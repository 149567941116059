import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import React, { ReactNode, useContext } from 'react'
import { Link } from 'react-router-dom'
import BackButton from '../../../../components/buttons/back-button/back-button'
import NavigateButton from '../../../../components/buttons/navigate-button/navigate-button'
import { RseeProjectContext } from '../../../../core/context/beem-pilot/rsee/rsee-project-context'
import { ProjectStatusEnum, projectStatusToLabel } from '../../../../core/enum/projectStatusEnum'

type CustomAction = { formId: string; isLoading: boolean; disabled: boolean }
export type Action = { path: string } | CustomAction

type IProps = {
  title: string
  action: Action | undefined
  navigateBackPath: string
  actionLabel: string
  icon: React.JSX.Element
  isWriting: boolean
  menu?: ReactNode
}

export default function RseeHeader({
  title,
  navigateBackPath,
  action,
  actionLabel,
  icon,
  isWriting,
  menu,
}: IProps): React.JSX.Element {
  const { rseeProject } = useContext(RseeProjectContext)

  function actionButton(someAction: Action): React.JSX.Element | undefined {
    if (someAction === undefined) {
      return undefined
    } else if ('path' in someAction) {
      return <NavigateButton path={someAction.path} label={actionLabel} />
    } else {
      return customActionButton(someAction)
    }
  }

  function customActionButton(customAction: CustomAction): React.JSX.Element {
    return customAction.isLoading ? (
      <CircularProgress />
    ) : (
      <Button
        variant='contained'
        sx={{ textAlign: 'center', width: '100%' }}
        type='submit'
        form={customAction.formId}
        disabled={customAction.disabled}>
        {actionLabel}
      </Button>
    )
  }

  return (
    <>
      <Grid item xs={8} display='flex' justifyContent='flex-start' alignItems='flex-start'>
        <Grid container columnSpacing={2} sx={{ alignItems: 'center' }}>
          <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Link to={navigateBackPath} style={{ textDecoration: 'none', color: 'inherit' }}>
              {icon}
            </Link>
          </Grid>

          <Grid item xs={11}>
            <Link to={navigateBackPath} style={{ textDecoration: 'none', color: 'inherit' }}>
              <Typography variant='h1' sx={{ fontSize: 50, fontWeight: 'bold' }}>
                {title}
              </Typography>
            </Link>
          </Grid>

          <Grid
            item
            xs={1}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            {rseeProject?.id && menu}
          </Grid>
          <Grid item xs={11}>
            <Typography variant='h6'>{rseeProject?.projectName ?? ''}</Typography>
            {rseeProject?.projectStatus && (
              <Typography variant='subtitle1'>Statut: {projectStatusToLabel(rseeProject.projectStatus)}</Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} display='flex' justifyContent='center' justifyItems='center' alignItems='center'>
        <BackButton path={navigateBackPath} />
      </Grid>
      <Grid item xs={2} display='flex' justifyContent='center' alignItems='center'>
        {isWriting && rseeProject?.projectStatus !== ProjectStatusEnum.ARCHIVED && action && actionButton(action)}
      </Grid>
    </>
  )
}
