import { SvgIcon } from '@mui/material'
import React from 'react'

type IProps = {
  style?: any
}

export default function IconFolder(props: IProps): React.JSX.Element {
  const { style } = props

  return (
    <SvgIcon
      {...props}
      fillOpacity={0}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      sx={{ width: style?.width ? style?.width : '24', height: style?.height ? style?.height : '24' }}>
      <path
        d='M5.5 4.5H9.5L12.5 7.5H19.5C20.0304 7.5 20.5391 7.71071 20.9142 8.08579C21.2893
         8.46086 21.5 8.96957 21.5 9.5V17.5C21.5 18.0304 21.2893 18.5391 20.9142 18.9142C20.5391
          19.2893 20.0304 19.5 19.5 19.5H5.5C4.96957 19.5 4.46086 19.2893 4.08579 18.9142C3.71071 
          18.5391 3.5 18.0304 3.5 17.5V6.5C3.5 5.96957 3.71071 5.46086 4.08579 5.08579C4.46086 4.71071 4.96957 4.5 5.5 4.5Z'
        stroke='#678ECA'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  )
}
