import { Box, Button, CircularProgress, FormControl, FormLabel } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BSMaterialResult } from '../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult'
import { useMaterial } from '../../../core/hooks/use-material'
import FileInput from '../../inputs/file-input/file-input'
import { SuccessContext } from '../../layout/success-snackbar'

const successfullAddFicheConfiguree = 'La fiche configurée a bien été ajouté !'

type IProps = {
  handleClose: () => void
}

export default function ImportFicheConfiguree({ handleClose }: Readonly<IProps>): React.JSX.Element {
  const { sendFicheConfiguree } = useMaterial()
  const { projectId } = useParams()
  const [file, setFile] = useState<File | undefined>()
  const [isSending, setIsSending] = useState<boolean>(false)

  const openSuccessSnackbar: (message: string) => void = useContext(SuccessContext)

  function onChange(modelFile: File): void {
    setFile(modelFile)
  }

  function sendFile(): void {
    if (file && projectId) {
      setIsSending(true)
      sendFicheConfiguree(file, projectId)
        .then(() => {
          handleClose()
          openSuccessSnackbar(successfullAddFicheConfiguree)
        })
        .finally(() => {
          setIsSending(false)
        })
    }
  }

  return (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='center'
      id='rseeFormId'
      component='form'
      onSubmit={() => undefined}
      sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <FormControl>
        <FormLabel>Selectionner un fiche configurée</FormLabel>
        <FileInput onChange={onChange} file={file} progress={100} disabled={false} fileType='.xml' />
        <Box display='flex' justifyContent='right' sx={{ p: 1 }}>
          <Button onClick={handleClose}>Annuler</Button>
          {!isSending ? (
            <Button variant='contained' onClick={sendFile}>
              Confirmer
            </Button>
          ) : (
            <CircularProgress />
          )}
        </Box>
      </FormControl>
    </Box>
  )
}
