import { Box, Typography } from '@mui/material'
import React from 'react'
import city from '../../../assets/img/city.png'

export function ProjectListEmpty(): React.JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}>
      <Typography
        color='#374771'
        sx={{
          fontFamily: 'Poppins',
          fontSize: '24px',
          fontWeight: 600,
          lineHeight: '36px',
          textAlign: 'center',
        }}>
        Estimez le calcul re2020 de vos projets en quelques clic !
      </Typography>
      <Typography
        variant='body1'
        sx={{
          fontFamily: 'Poppins',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '24px',
          letterSpacing: '0.15000000596046448px',
          textAlign: 'center',
        }}>
        Créez maintenant votre premier projet Beem Shot.
      </Typography>
      <Box sx={{ p: 2 }}>
        <img
          src={city}
          alt={city}
          height='300px'
          width='max-content'
          style={{
            marginLeft: 15,
            marginTop: -8,
          }}
        />
      </Box>
    </Box>
  )
}
