import Clear from '@mui/icons-material/Clear'
import { Box, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React from 'react'
import { useFetchMaterialLibDeclarationType } from '../../../core/hooks/material-lib/use-fetch-material-lib-declaration-type'

import { NomenclatureFilterDto } from '../../../core/dto/nomenclature-filter'
import { NomenclatureTreeDto } from '../../../core/dto/nomenclature-tree'
import { DeclarationFilter } from '../../../core/enum/declarationFilter'
import '../material-page.css'
import { getDeclarationTypeLabel } from '../../../core/services/declaration-type-service'

type MaterialPageFormProps = {
  selectedLvl0: DeclarationFilter | undefined
  setSelectedLvl0: (selected: DeclarationFilter | undefined) => void
  nomenclatureFilterData: NomenclatureFilterDto
  selectedLvl1: string | undefined
  selectedLvl2: string | undefined
  selectedLvl3: string | undefined
  selectedLvl4: string | undefined
  selectedOrganism: string | undefined
  selectedTypologieDeclaration: string | undefined
  setSelectedLvl1: React.Dispatch<React.SetStateAction<string | undefined>>
  setSelectedLvl2: React.Dispatch<React.SetStateAction<string | undefined>>
  setSelectedLvl3: React.Dispatch<React.SetStateAction<string | undefined>>
  setSelectedLvl4: React.Dispatch<React.SetStateAction<string | undefined>>
  setSelectedOrganism: React.Dispatch<React.SetStateAction<string | undefined>>
  setSelectedSelectedTypologieDeclaration: React.Dispatch<React.SetStateAction<string | undefined>>
  tab: 1 | 2
}

export default function MaterialPageFilterFormModal({
  selectedLvl0,
  setSelectedLvl0,
  nomenclatureFilterData,
  selectedLvl1,
  selectedLvl2,
  selectedLvl3,
  selectedLvl4,
  selectedOrganism,
  selectedTypologieDeclaration,
  setSelectedLvl1,
  setSelectedLvl2,
  setSelectedLvl3,
  setSelectedLvl4,
  setSelectedOrganism,
  setSelectedSelectedTypologieDeclaration,
  tab,
}: Readonly<MaterialPageFormProps>): React.JSX.Element {
  const { declarationTypes } = useFetchMaterialLibDeclarationType()

  const categories: NomenclatureTreeDto[] =
    selectedLvl0 === DeclarationFilter.FDES ? nomenclatureFilterData.fdes : nomenclatureFilterData.pep
  const category: NomenclatureTreeDto | undefined = categories.find((c) => c.iniesId === selectedLvl1)
  const subCategory: NomenclatureTreeDto | undefined = category?.children.find((subc) => subc.iniesId === selectedLvl2)
  const material: NomenclatureTreeDto | undefined = subCategory?.children.find((m) => m.iniesId === selectedLvl4)

  const endAdornment = (
    value: string | undefined,
    handleChange: (v?: string) => void,
    type: 'text' | 'select' = 'text'
  ): React.JSX.Element | undefined => {
    if (value) {
      return (
        <InputAdornment
          position='end'
          sx={{
            mr: type === 'text' ? 0 : 2,
          }}>
          <IconButton onClick={() => handleChange(undefined)} size='small'>
            <Clear fontSize='small' />
          </IconButton>
        </InputAdornment>
      )
    }
    return undefined
  }

  return (
    <Box className='partial-border' display='flex' flexDirection='column' sx={{ mt: 4 }}>
      <FormControl sx={{ m: 1, minWidth: 120, margin: '8px 0' }}>
        <InputLabel id='selectedLvl0' sx={{ textTransform: 'uppercase', backgroundColor: 'white' }}>
          Type
        </InputLabel>
        <Select
          label='Type'
          value={selectedLvl0 || ''}
          labelId='selectedLvl0'
          id='selectedLvl0'
          name='selectedLvl0'
          onChange={(e) => setSelectedLvl0(e.target.value as DeclarationFilter | undefined)}
          endAdornment={endAdornment(
            selectedLvl0,
            (v) => {
              // It might seem weird to put undefined but v is always undefined. If we refactor someday, the clear method could be simplified. "v" param is useless
              setSelectedLvl0(undefined)
              setSelectedLvl1(v)
              setSelectedLvl2(v)
              setSelectedLvl3(v)
              setSelectedLvl4(v)
            },
            'select'
          )}>
          <MenuItem key={DeclarationFilter.FDES} value={DeclarationFilter.FDES}>
            Produits
          </MenuItem>
          <MenuItem key={DeclarationFilter.PEP} value={DeclarationFilter.PEP}>
            Équipement (PEP)
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 120, margin: '8px 0' }}>
        <InputLabel id='selectedLvl1' sx={{ textTransform: 'uppercase', backgroundColor: 'white' }}>
          catégorie
        </InputLabel>
        <Select
          label='catégorie'
          value={selectedLvl1 ?? ''}
          labelId='selectedLvl1'
          id='selectedLvl1'
          name='selectedLvl1'
          onChange={(e) => setSelectedLvl1(e.target.value)}
          disabled={!selectedLvl0}
          endAdornment={endAdornment(
            selectedLvl1,
            (v) => {
              setSelectedLvl1(v)
              setSelectedLvl2(v)
              setSelectedLvl3(v)
              setSelectedLvl4(v)
            },
            'select'
          )}>
          {categories
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((categoryItem) => (
              <MenuItem key={categoryItem.iniesId} value={categoryItem.iniesId}>
                {categoryItem.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 120, margin: '8px 0' }}>
        <InputLabel sx={{ textTransform: 'uppercase', backgroundColor: 'white' }}>sous-catégorie</InputLabel>
        <Select
          label='sous-catégorie'
          value={selectedLvl2 || ''}
          onChange={(e) => setSelectedLvl2(e.target.value)}
          disabled={!selectedLvl1}
          endAdornment={endAdornment(
            selectedLvl2,
            (v) => {
              setSelectedLvl2(v)
              setSelectedLvl3(v)
              setSelectedLvl4(v)
            },
            'select'
          )}>
          {category?.children
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((subCategoryItem) => (
              <MenuItem key={subCategoryItem.iniesId} value={subCategoryItem.iniesId}>
                {subCategoryItem.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 120, margin: '8px 0' }}>
        <InputLabel sx={{ textTransform: 'uppercase', backgroundColor: 'white' }}>
          {selectedLvl0 === DeclarationFilter.PEP ? 'Type' : 'matériaux'}
        </InputLabel>
        <Select
          label={selectedLvl0 === DeclarationFilter.PEP ? 'Type' : 'matériaux'}
          value={selectedLvl3 ?? ''}
          onChange={(e) => setSelectedLvl3(e.target.value)}
          disabled={!selectedLvl3}
          endAdornment={endAdornment(
            selectedLvl3,
            (v) => {
              setSelectedLvl3(v)
              setSelectedLvl4(v)
            },
            'select'
          )}>
          {subCategory?.children
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((m) => (
              <MenuItem key={m.iniesId} value={m.iniesId}>
                {m.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {selectedLvl0 === DeclarationFilter.PEP && (
        <FormControl sx={{ m: 1, minWidth: 120, margin: '8px 0' }}>
          <InputLabel sx={{ textTransform: 'uppercase', backgroundColor: 'white' }}>spécification</InputLabel>
          <Select
            label='spécification'
            value={selectedLvl4 ?? ''}
            onChange={(e) => setSelectedLvl4(e.target.value)}
            disabled={!selectedLvl3}
            endAdornment={endAdornment(selectedLvl4, setSelectedLvl4, 'select')}>
            {material?.children
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((m) => (
                <MenuItem key={m.iniesId} value={m.iniesId}>
                  {m.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}

      <TextField
        sx={{ m: 1, minWidth: 120, margin: '8px 0' }}
        value={selectedOrganism ?? ''}
        onChange={(e) => setSelectedOrganism(e.target.value)}
        label='ORGANISME DECLARANT'
        variant='outlined'
        InputProps={{
          endAdornment: endAdornment(selectedOrganism, setSelectedOrganism),
        }}
      />

      <FormControl sx={{ m: 1, minWidth: 120, margin: '8px 0' }}>
        <InputLabel sx={{ textTransform: 'uppercase', backgroundColor: 'white' }}>typologie de déclaration</InputLabel>
        <Select
          label='material'
          value={selectedTypologieDeclaration ?? ''}
          onChange={(e) => setSelectedSelectedTypologieDeclaration(e.target.value)}
          endAdornment={endAdornment(selectedTypologieDeclaration, setSelectedSelectedTypologieDeclaration, 'select')}>
          {declarationTypes
            .sort((a, b) => a.localeCompare(b))
            .map((typologie) => (
              <MenuItem key={typologie} value={typologie}>
                {getDeclarationTypeLabel(typologie)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      {tab === 2 && (
        <>
          <TextField sx={{ m: 1, minWidth: 120, margin: '8px 0' }} label='FOURNISSEUR' variant='outlined' />
          <TextField sx={{ m: 1, minWidth: 120, margin: '8px 0' }} label='REFERENCE' variant='outlined' />
        </>
      )}
    </Box>
  )
}
