import InsertLinkIcon from '@mui/icons-material/InsertLink'
import { Button, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { ErrorContext } from '../../layout/error-snackbar'
import { SuccessContext } from '../../layout/success-snackbar'

type IProps = {
  link: string | undefined
  actionAfterCopy?(): void
}

export function CopyLinkButton({ link, actionAfterCopy }: Readonly<IProps>): React.JSX.Element {
  const openSuccessSnackbar = useContext(SuccessContext)
  const openErrorSnackbar = useContext(ErrorContext)

  function copyLink(): void {
    if (link) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          if (actionAfterCopy) {
            actionAfterCopy()
          }
          openSuccessSnackbar('Lien copié')
        })
        .catch((err) => {
          openErrorSnackbar(new Error('Erreur lors de la sauvegarde du lien dans le presse-papier'))
          console.error('Failed to copy the link: ', err)
        })
    }
  }

  return (
    <Button
      variant='contained'
      onClick={copyLink}
      startIcon={<InsertLinkIcon sx={{ color: '#009069' }} />}
      sx={{ borderRadius: 3, background: '#C9FFE8' }}>
      <Typography fontFamily='Poppins' fontSize='13px' fontWeight={500} lineHeight='22px' sx={{ color: '#009069' }}>
        Copier le lien
      </Typography>
    </Button>
  )
}
