import { Chip } from '@mui/material'
import React from 'react'
import { ProjectStatusEnum, projectStatusToLabel } from '../../core/enum/projectStatusEnum'
import { DefaultTypography } from '../typography/default-typography'

type IProps = {
  status: ProjectStatusEnum
}

export function ProjectCardStatus({ status }: IProps): React.JSX.Element {
  function getStatusBackgroundColor(): string {
    switch (status) {
      case ProjectStatusEnum.IN_PROGRESS:
        return '#DFF4FF'
      case ProjectStatusEnum.FINISHED:
        return '#E8F5E9'
      case ProjectStatusEnum.ARCHIVED:
        return '#ECF3F3'
      default:
        return ''
    }
  }

  function getTypologyColor(): string {
    switch (status) {
      case ProjectStatusEnum.IN_PROGRESS:
        return '#06B8F1'
      case ProjectStatusEnum.FINISHED:
        return '#388E3C'
      case ProjectStatusEnum.ARCHIVED:
        return '#374771'
      default:
        return ''
    }
  }

  function getBProjectCardStatus(): JSX.Element {
    return (
      <Chip
        sx={{ backgroundColor: getStatusBackgroundColor(), color: getTypologyColor() }}
        label={
          <DefaultTypography
            label={projectStatusToLabel(status)}
            fontSize='11px'
            lineHeight='16px'
            fontWeight={500}
            color={getTypologyColor()}
          />
        }
      />
    )
  }

  return getBProjectCardStatus()
}
