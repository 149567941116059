import { Clear } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import { Box, IconButton, InputAdornment, InputBase } from '@mui/material'

type MaterialPageSearchProps = {
  handleSearchDelayed: (value: string) => void
  search: string | undefined
  setSearch: React.Dispatch<React.SetStateAction<string | undefined>>
}
export default function MaterialPageSearch({
  handleSearchDelayed,
  search,
  setSearch,
}: MaterialPageSearchProps): React.JSX.Element {
  const endAdornment = (): React.JSX.Element | undefined => {
    if (search) {
      return (
        <InputAdornment position='end'>
          <IconButton
            size='small'
            onClick={() => {
              setSearch('')
              handleSearchDelayed('')
            }}>
            <Clear fontSize='small' />
          </IconButton>
        </InputAdornment>
      )
    }
    return undefined
  }
  return (
    <Box display='flex' flexDirection='row' justifyContent='space-around'>
      <Box
        sx={{
          flex: 3,
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#ffffff',
          borderRadius: 3,
        }}>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          value={search}
          placeholder='Rechercher des déclarations'
          inputProps={{ 'aria-label': 'search' }}
          endAdornment={<SearchIcon sx={{ margin: '0 8px' }} />}
          onChange={(e: { target: { value: any } }) => {
            setSearch(e.target.value)
            handleSearchDelayed(e.target.value)
          }}
        />
      </Box>
    </Box>
  )
}
