import { Box, CircularProgress, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ShareLink } from '../../../core/dto/rsee/share-link'
import { useProjects } from '../../../core/hooks/projects/use-projects'
import { SearchAndFilter } from '../components/SearchAndFilter'
import { useSearchAndFilter } from '../components/useSearchAndFilter'
import { RseeInviter } from '../rseeTeam/components/RseeInviter'
import { RseeProjectShareLinkDisplayer } from './components/RseeProjectShareLinkDisplayer'

export function RseeShareLinkPage(): React.JSX.Element {
  const { rseeDocumentId, rseeProjectId } = useParams()

  const { fetchAllRseeProjectShareLink, fetchAllRseeDocumentShareLink, deleteRseeShareToken } = useProjects()
  const [shareInformationList, setShareInformationList] = useState<ShareLink[]>()
  const [open, setOpen] = useState<boolean>(false)
  const [selectedShareLink, setSelectedShareLink] = useState<ShareLink | undefined>(undefined)

  const [isRevokeDialogOpen, setIsRevokeDialogOpen] = useState(false)

  useEffect(() => {
    refresh()
  }, [])

  const { searchState, handleSelectChange, handleSearch, itemsToShow } = useSearchAndFilter(shareInformationList ?? [], [
    'createdByUserName',
    'documentName',
  ])

  function refresh(): void {
    if (rseeProjectId) {
      fetchAllRseeProjectShareLink(rseeProjectId).then((newList) => {
        setShareInformationList(newList)
      })
    }
  }

  function handleClose(): void {
    setOpen(false)
  }

  function deleteShareInformation(element: ShareLink): Promise<void> {
    return deleteRseeShareToken(element.id).then(() => refresh())
  }

  return (
    <Box display='flex' flexDirection='column' sx={{ pt: 2 }}>
      <SearchAndFilter
        searchState={searchState}
        handleSearch={handleSearch}
        handleChange={handleSelectChange}
        searchStateLabel='Rechercher par nom du document, créateur'
      />

      <Grid container alignItems='flex-start' height='100%' rowGap={1}>
        {shareInformationList ? (
          <RseeProjectShareLinkDisplayer
            shareInformationList={itemsToShow}
            deleteShareLink={deleteShareInformation}
            isSearching={searchState !== undefined}
          />
        ) : (
          <CircularProgress />
        )}
      </Grid>

      <RseeInviter open={open} handleClose={handleClose} />
    </Box>
  )
}
