/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import React from 'react'

type IProps = {
  style?: any
}

export function IconUsers(props: IProps): React.JSX.Element {
  const { style } = props
  return (
    <SvgIcon
      {...props}
      fillOpacity={0}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      sx={{ width: style?.width ? style?.width : '24', height: style?.height ? style?.height : '24' }}>
      {/*  SVG from https://tablericons.com/ */}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='icon icon-tabler icon-tabler-users'
        viewBox='0 0 24 24'
        strokeWidth='1.5'
        stroke='#2c3e50'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'>
        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
        <path d='M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0' />
        <path d='M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2' />
        <path d='M16 3.13a4 4 0 0 1 0 7.75' />
        <path d='M21 21v-2a4 4 0 0 0 -3 -3.85' />
      </svg>
    </SvgIcon>
  )
}
