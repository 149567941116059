import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { Children } from '../../../../components/miscellianous/children'
import { RseeProjectCardDto } from '../../../dto/rsee/rsee-project-card'
import { ProjectStatusEnum } from '../../../enum/projectStatusEnum'
import { useRsee } from '../../../hooks/rsee/use-rsee'

export const RseeProjectListContext = React.createContext<RseeProjectListStore>({} as RseeProjectListStore)

export function RseeProjectListContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { fetchProjectCards } = useRsee()

  const [rseeProjectList, setRseeProjectList] = useState<RseeProjectCardDto[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [statusFilter, setStatusFilter] = useState<ProjectStatusEnum>(ProjectStatusEnum.IN_PROGRESS)
  const [fetchOnlyMyRseeProject, setFetchOnlyMyRseeProject] = useState<boolean>(true)

  const fetchBsProjectCard = useCallback(
    (limit?: number) => {
      setIsLoading(true)
      fetchProjectCards(fetchOnlyMyRseeProject, '', false)
        .then((newBSProjectList) => {
          setRseeProjectList(newBSProjectList)
          return newBSProjectList
        })
        .finally(() => setIsLoading(false))
    },
    [fetchOnlyMyRseeProject, fetchProjectCards]
  )

  useEffect(() => fetchBsProjectCard(), [fetchBsProjectCard])

  const rseeProjectListStore = useMemo(
    () => ({
      rseeProjectList,
      setRseeProjectList,
      isLoading,
      setIsLoading,
      statusFilter,
      setStatusFilter,
      fetchOnlyMyRseeProject,
      setFetchOnlyMyRseeProject,
    }),
    [fetchOnlyMyRseeProject, isLoading, rseeProjectList, statusFilter]
  )
  return <RseeProjectListContext.Provider value={rseeProjectListStore}>{children}</RseeProjectListContext.Provider>
}

export type RseeProjectListStore = {
  rseeProjectList: RseeProjectCardDto[]
  setRseeProjectList: Dispatch<SetStateAction<RseeProjectCardDto[]>>
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  statusFilter: ProjectStatusEnum
  setStatusFilter: Dispatch<SetStateAction<ProjectStatusEnum>>
  fetchOnlyMyRseeProject: boolean
  setFetchOnlyMyRseeProject: Dispatch<SetStateAction<boolean>>
}
