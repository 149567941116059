import React, { useContext, useState } from 'react'
import IconFolder from '../../../../components/custom-icons/icon-folder'
import { IconRseeGlobalDashboard } from '../../../../components/custom-icons/icon-rsee-global-dashboard'
import { ProjectListEmpty } from '../../../../components/project-list-empty/projectListEmpty'
import { RseeProjectListContext } from '../../../../core/context/beem-pilot/rsee/rsee-project-list-context'
import { CustomTabPanel, TabPanelProps } from '../../../beem-shot/components/CustomTabPanel'
import OrganizationDashboardPage from '../../../organization-dashboard-page/OrganizationDashboardPage'
import { RseeProjectList } from './RseeProjectList'

export function RseeProjectListContainer(): React.JSX.Element {
  const { rseeProjectList, statusFilter } = useContext(RseeProjectListContext)

  const [tabs, setTabs] = React.useState('RSEE_PROJECTS')
  const [numberProject, setNumberProject] = useState<number>()

  function handleChange(event: React.SyntheticEvent, newValue: string): void {
    setTabs(newValue)
  }

  const panels: TabPanelProps[] = [
    {
      id: 'PILOTAGE_GENERAL',
      Icon: IconRseeGlobalDashboard,
      label: 'Pilotage géneral',
      chips: undefined,
      content: <OrganizationDashboardPage />,
    },
    {
      id: 'RSEE_PROJECTS',
      Icon: IconFolder,
      label: 'Projets',
      chips: numberProject?.toString() ?? rseeProjectList.filter((x) => x.projectStatus === statusFilter).length.toString(),
      content: <RseeProjectList setNumberProject={setNumberProject} />,
    },
  ]

  return !rseeProjectList || rseeProjectList.length === 0 ? (
    <ProjectListEmpty />
  ) : (
    <CustomTabPanel panels={panels} tabs={tabs} handleChange={handleChange} />
  )
}
