import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { TableauDashboard } from '../../components/tableau-dashboards/tableau-dashboard'
import { TableauViz } from '../../core/services/tableau-service'
import {
  ICADE_CLIENT_ID_PARAMETER_NAME,
  PROJECT_ID_PARAMETER_NAME,
  SECURE_TOKEN_PARAMETER_NAME,
} from '../../core/services/tableau-extensions-service'
import { useKairnial } from './useKairnial'
import { DashboardInfos } from '../../core/dto/kairnial/DashboardInfos'
import { useQuery } from '../../core/hooks/miscellaneous/use-query'
import { KairnialRefreshNavigation } from './KairnialRefreshNavigation'

export function KairnialDashboardPage(): React.JSX.Element {
  const { getDashboardInfos, fetchTableauJwt } = useKairnial()

  const query = useQuery()
  const clientId: string = useMemo(() => query.get('clientId') ?? '', [query])
  const secureToken: string = useMemo(() => query.get('secureToken') ?? '', [query])

  const [projectId, setProjectId] = useState<string | undefined>(undefined)
  const [isReady, setIsReady] = useState<boolean>(false)
  const [jwt, setJwt] = useState<string | undefined>(undefined)
  const [dashboardUrl, setDashboardUrl] = useState<string | undefined>(undefined)

  const [isInitialized, setIsInitialized] = useState<boolean>(false)

  const viz: React.MutableRefObject<any> = useRef<any>(new TableauViz())

  useEffect(() => {
    getDashboardInfos(secureToken, clientId).then((response: DashboardInfos) => {
      setProjectId(response.projectId)
      setDashboardUrl(response.dashboardUrl)
      setIsReady(true)
    })
  }, [getDashboardInfos, secureToken, clientId])

  useEffect(() => {
    fetchTableauJwt()
      .then((token) => {
        setJwt(token.jwt)
      })
      .catch(() => console.error('No JWT found or no calculation selected'))
  }, [fetchTableauJwt])

  if (projectId && dashboardUrl && isReady) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', pb: '100px' }}>
        <TableauDashboard
          dashboardUrl={dashboardUrl}
          isDataReady
          tableauParameter={[
            { name: PROJECT_ID_PARAMETER_NAME, value: [projectId] },
            { name: SECURE_TOKEN_PARAMETER_NAME, value: [secureToken] },
            { name: ICADE_CLIENT_ID_PARAMETER_NAME, value: [clientId] },
          ]}
          setIsInitialized={setIsInitialized}
          vizRef={viz}
          filterToAdd={[]}
          currentJwt={jwt}
          hasJwt
        />
        <KairnialRefreshNavigation secureToken={secureToken} clientId={clientId} />
      </Box>
    )
  } else if (!isReady) {
    return <div>En cours de récupération des informations du tableau</div>
  } else {
    return <div>Impossible d'accéder aux informations du projet</div>
  }
}
