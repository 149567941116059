import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export function IconRseeCriticalImport(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      {/*  SVG from https://tablericons.com/ */}
      <svg width='24' height='24' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10.0833 20.75H11.9166V22.5833H10.0833V20.75ZM10.0833 13.4166H11.9166V18.9166H10.0833V13.4166ZM10.9908 8.83331C5.93081
 8.83331 1.83331 12.94 1.83331 18C1.83331 23.06 5.93081 27.1666 10.9908 27.1666C16.06 27.1666 20.1666 23.06 20.1666 18C20.1666
  12.94 16.06 8.83331 10.9908 8.83331ZM11 25.3333C6.94831 25.3333 3.66665 22.0516 3.66665 18C3.66665 13.9483 6.94831 10.6666 11
   10.6666C15.0516 10.6666 18.3333 13.9483 18.3333 18C18.3333 22.0516 15.0516 25.3333 11 25.3333Z'
          fill='#FF2E2E'
        />
      </svg>
    </SvgIcon>
  )
}
