import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import { Box, Grid, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { RseeProjectContext } from '../../../core/context/beem-pilot/rsee/rsee-project-context'
import { BSVariantContext } from '../../../core/context/beem-shot/BSVariant/BSVariantContext'
import { AppNavBarContext } from '../../../core/context/nav-bar/AppNavBarContext'
import { AppSideBarContext } from '../../../core/context/side-bar/side-bar-context'

export function BackButtonNavbar(): React.JSX.Element {
  const navigate = useNavigate()

  const { previousUrl, icon } = useContext(AppNavBarContext)
  const { rseeProject } = useContext(RseeProjectContext)
  const { urlState } = useContext(AppSideBarContext)
  const { selectedVariant } = useContext(BSVariantContext)

  return (
    <Grid container item xs={2} display='flex' flexDirection='row' alignContent='center' alignItems='center'>
      <Box display='flex' flexDirection='row' alignItems='center' overflow='hidden'>
        <ArrowBackOutlinedIcon onClick={() => navigate(previousUrl)} fontSize='medium' sx={{ cursor: 'pointer' }} />
        <Box
          display='flex'
          alignItems='center'
          sx={{ borderRadius: 2, background: '#b7ffe3', p: 1, m: 1, border: 3, borderColor: 'white' }}>
          {icon}
        </Box>
        <Box overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
          <Typography variant='body1' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {rseeProject?.projectName}
          </Typography>
          <Typography variant='body1' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {selectedVariant?.name}
          </Typography>
          <Typography variant='body2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {urlState}
          </Typography>
        </Box>
      </Box>
    </Grid>
  )
}
