import { stringToDate } from '../../services/date-service'

export class RseeDocument {
  id: string
  projectId: string
  documentName: string
  nomPhase: string
  numeroPermis: string
  zoneClimatique: string
  altitude: number
  description: string
  referencesCadastrales: string[]
  dateLivraison?: Date
  dateDepotPC?: Date
  createdDate?: Date
  lastModifiedDate: Date
  impactCarboneGlobal: number
  seuilCarboneGlobal: number
  versionTtb: string
  status: string
  currentDocument: boolean
  labelDateEtude: string
  createdByUser: string
  compliant: boolean

  constructor(data: any) {
    this.id = data.id
    this.projectId = data.projectId
    this.documentName = data.documentName
    this.nomPhase = data.nomPhase
    this.numeroPermis = data.numeroPermis
    this.zoneClimatique = data.zoneClimatique
    this.altitude = data.altitude
    this.description = data.description
    this.referencesCadastrales = data.referencesCadastrales
    this.dateDepotPC = stringToDate(data.dateDepotPC)
    this.dateLivraison = stringToDate(data.dateLivraison)
    this.createdDate = stringToDate(data.createdDate)
    this.lastModifiedDate = new Date(data.lastModifiedDate)
    this.impactCarboneGlobal = data.impactCarboneGlobal
    this.seuilCarboneGlobal = data.seuilCarboneGlobal
    this.versionTtb = data.versionTtb
    this.status = data.status
    this.currentDocument = data.currentDocument
    this.labelDateEtude = data.labelDateEtude
    this.createdByUser = data.createdByUser
    this.compliant = data.compliant
  }
}
