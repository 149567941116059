import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { BsModelImportContext } from '../../../../../../core/context/beem-shot/BSBimModel/BSBimModelImportContext'
import { BSItemContext } from '../../../../../../core/context/beem-shot/BSItems/BSItemContext'
import { BSItemCategory } from '../../../../../../core/dto/beem-shot/BSItem/BSItemCategory'
import { SubCategoryAccordion } from './SubCategoryAccordion'
import { useAccordion } from './useAccordion'

interface IProps {
  category: BSItemCategory
  expandedCategory: string
  handleExpandedCategory(toExpand?: string): void
}

export function CategoryAccordion({
  category,
  expandedCategory,
  handleExpandedCategory,
}: Readonly<IProps>): React.JSX.Element {
  return (
    <CategoryAccordionCore
      category={category}
      expandedCategory={expandedCategory}
      handleExpandedCategory={handleExpandedCategory}
    />
  )
}

export function CategoryAccordionCore({
  category,
  expandedCategory,
  handleExpandedCategory,
}: Readonly<IProps>): React.JSX.Element {
  const { setSelectedCodeExtrait, selectedBSItem, selectedCodeExtrait, subCategoriesForCategory } = useContext(BSItemContext)
  const { viewer } = useContext(BsModelImportContext)

  const { expanded: subcategoryExpanded, expand, handleExpanded } = useAccordion()

  useEffect(() => {
    if (selectedCodeExtrait && selectedBSItem) {
      expand(selectedBSItem?.subCategory.name)
    }
  }, [selectedBSItem, selectedCodeExtrait])

  return (
    <Accordion
      id={category.name}
      expanded={expandedCategory === category.name}
      onChange={(e, toOpen) => {
        handleExpanded('')
        if (selectedBSItem && !toOpen) {
          handleExpandedCategory(selectedBSItem?.category?.name)
          viewer?.manager.subsetsManager.restoreAllSubsetsMaterial()
          setSelectedCodeExtrait(undefined)
        } else {
          handleExpandedCategory(category.name)
        }
      }}
      disableGutters
      square
      sx={{
        borderRadius: 6,
        m: 1,
        minWidth: '100%',
      }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{category.label}</AccordionSummary>
      <AccordionDetails>
        {category?.subCategories
          ?.filter((subCategory) => subCategoriesForCategory[category.name]?.some((sc) => sc.name === subCategory.name))
          .map((subCategory) => (
            <SubCategoryAccordion
              key={subCategory.name}
              subCategory={subCategory}
              expandedSubCategory={subcategoryExpanded}
              handleExpandedSubCategory={handleExpanded}
            />
          ))}
      </AccordionDetails>
    </Accordion>
  )
}
