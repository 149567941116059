import { Box, Divider, Grid, Tooltip, Typography } from '@mui/material'
import React, { ChangeEvent, FormEvent, useContext, useEffect, useMemo, useState } from 'react'
import { CustomBottomNavigation } from '../../components/buttons/navigate-button/CustomBottomNavigation'
import ListInput from '../../components/inputs/list-input/list-input'
import PhoneInput from '../../components/inputs/phone-input/phone-input'
import { TextInput } from '../../components/inputs/text-input/text-input'
import { SuccessContext } from '../../components/layout/success-snackbar'
import { OrganizationContext } from '../../core/context/organization/organization-context'
import { Address } from '../../core/dto/address'
import { Organization } from '../../core/dto/organization/organization'
import { OrganizationUpdate } from '../../core/dto/organization/organization-update'
import { RoleEnum } from '../../core/enum/roleEnum'
import { useOrganization } from '../../core/hooks/use-organization'

type OrganizationForm = {
  businessName: string
  email: string
  addressString: string
  phone: string
  vatNumber: string
  siret: string
  address: Address
  roles: RoleEnum[]
}

function getOrganizationAddress(form: Address): string {
  let completeAddress = ''

  if (form.street !== undefined) {
    completeAddress += `${form.street}`
  }
  if (form.city !== undefined && completeAddress !== '') {
    completeAddress += `, `
  }
  if (form.city !== undefined) {
    completeAddress += `${form.city}`
  }
  if (form.zipCode !== undefined && completeAddress !== '') {
    completeAddress += `, `
  }
  if (form.zipCode !== undefined) {
    completeAddress += `${form.zipCode}`
  }
  return completeAddress === '' ? ' ' : completeAddress
}

function formFromOrganization(organization: Organization | undefined): OrganizationForm {
  if (!organization) {
    return {
      businessName: '',
      email: '',
      addressString: '',
      phone: '',
      vatNumber: '',
      siret: '',
      address: new Address(),
      roles: [],
    }
  } else {
    return {
      businessName: organization.businessName,
      email: organization.email,
      phone: organization.phone,
      vatNumber: organization.vatNumber,
      siret: organization.siret,
      addressString: getOrganizationAddress(organization.address),
      address: organization.address,
      roles: organization.roles,
    }
  }
}

function dtoFromForm(organizationForm: OrganizationForm): OrganizationUpdate {
  return {
    businessName: organizationForm.businessName,
    email: organizationForm.email,
    phone: organizationForm.phone,
    siret: organizationForm.siret,
    vatNumber: organizationForm.vatNumber,
    address: organizationForm.address,
  }
}

const addressInstance = new Address()
function isAddressKey(stringToTest: string): boolean {
  return Object.hasOwn(addressInstance, stringToTest)
}

type IProps = {
  isWriting: boolean
}

export default function OrganizationDetail({ isWriting }: IProps): React.JSX.Element {
  const { organization, setOrganization } = useContext(OrganizationContext)
  const openSuccessSnackbar = useContext(SuccessContext)

  const { updateOrganization } = useOrganization()

  const [organizationForm, setOrganizationForm] = useState<OrganizationForm>(formFromOrganization(organization))
  const [error, setError] = useState<Record<string, string>>({})
  const [isUpdating, setIsUpdating] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setOrganizationForm(formFromOrganization(organization))
  }, [organization])
  const guestRole = useMemo(() => [RoleEnum.MOA, RoleEnum.ARCHITECT, RoleEnum.BE, RoleEnum.CONSULTANT], [])

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const fieldName = target.id

    if (isAddressKey(fieldName)) {
      setOrganizationForm({
        ...organizationForm,
        address: { ...organizationForm.address, [fieldName]: value },
      })
    } else {
      setOrganizationForm({
        ...organizationForm,
        [fieldName]: value,
      })
    }
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    if (!isUpdating) {
      setIsUpdating(true)
    } else {
      const dto: OrganizationUpdate = dtoFromForm(organizationForm)
      setIsLoading(true)
      updateOrganization(dto)
        .then((response) => {
          setOrganization(response)
        })
        .then(() => openSuccessSnackbar('Les modifications ont bien été enregistrés'))
        .finally(() => {
          setIsLoading(false)
          setIsUpdating(false)
        })
    }
  }
  return (
    <>
      <Box
        component='form'
        id='organization-info-form'
        onSubmit={handleSubmit}
        borderRadius={4}
        sx={{ boxShadow: '2px 4px 20px 0px #0000000D', backgroundColor: 'white', p: 4, pt: 1, mx: 2, mt: 4 }}>
        <Typography fontWeight='bold' variant='h5' fontSize={20} sx={{ mt: 4, mb: 2 }}>
          INFORMATIONS GÉNÉRALES
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextInput
              fieldName='businessName'
              label='Organisation'
              handleChange={handleChange}
              form={organizationForm}
              errors={error}
              backgroundColor='#F5F5F5'
              hideBorder
              borderRadius={3}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              fieldName='email'
              label='Adresse e-mail'
              handleChange={handleChange}
              form={organizationForm}
              errors={error}
              backgroundColor='#F5F5F5'
              hideBorder
              borderRadius={3}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextInput
              fieldName='street'
              label='Adresse'
              handleChange={handleChange}
              form={organizationForm.address}
              errors={error}
              backgroundColor='#F5F5F5'
              hideBorder
              borderRadius={3}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextInput
              label="Complément d'adresse"
              fieldName='additional'
              handleChange={handleChange}
              form={organizationForm.address}
              errors={error}
              backgroundColor='#F5F5F5'
              hideBorder
              borderRadius={3}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              fieldName='zipCode'
              label='Code postal'
              handleChange={handleChange}
              form={organizationForm.address}
              errors={error}
              backgroundColor='#F5F5F5'
              hideBorder
              borderRadius={3}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              fieldName='city'
              label='Ville'
              handleChange={handleChange}
              form={organizationForm.address}
              errors={error}
              backgroundColor='#F5F5F5'
              hideBorder
              borderRadius={3}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              fieldName='siret'
              label='Siret'
              handleChange={handleChange}
              form={organizationForm}
              errors={error}
              backgroundColor='#F5F5F5'
              hideBorder
              borderRadius={3}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <PhoneInput
              form={organizationForm}
              error={error}
              handleChange={handleChange}
              required={false}
              disabled={!isUpdating}
              variant='outlined'
              fullWidth
              hideBorder
              margin='normal'
              backgroundColor='#F5F5F5'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              fieldName='vatNumber'
              label='TVA'
              handleChange={handleChange}
              form={organizationForm}
              errors={error}
              backgroundColor='#F5F5F5'
              hideBorder
              borderRadius={3}
            />
          </Grid>

          <Divider
            variant='fullWidth'
            textAlign='left'
            sx={{
              my: 2,
              color: '#BDBDBD',
              backgroundColor: '#BDBDBD ',
              width: '100%',
            }}
          />

          <Grid item xs={12} sm={6}>
            <ListInput values={organizationForm.roles} label='Rôles' disabled backgroundColor='#F5F5F5' />
          </Grid>
          <Tooltip title='Si vous souhaitez modifier le nom de domaine, contactez le support.' placement='right' arrow>
            <Grid item xs={12} sm={3}>
              {organization && (
                <ListInput
                  values={organization.domainNames}
                  label={organization.domainNames.length > 1 ? 'Noms de domaine' : 'Nom de domaine'}
                  disabled
                  backgroundColor='#F5F5F5'
                />
              )}
            </Grid>
          </Tooltip>
        </Grid>
      </Box>

      <CustomBottomNavigation actionLabel='Enregistrer' isSubmiting={isLoading} formId='organization-info-form' />
    </>
  )
}
