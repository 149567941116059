import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Box, Dialog, DialogContent, Grid, Pagination, Typography } from '@mui/material'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CustomBottomNavigation } from '../../components/buttons/navigate-button/CustomBottomNavigation'
import { IconMapPin } from '../../components/custom-icons/icon-map-pin'
import { CollaboratorFormComponent } from '../../components/forms/user-invitation-form/collaborator-form-component'
import { ErrorContext } from '../../components/layout/error-snackbar'
import { SuccessContext } from '../../components/layout/success-snackbar'
import { pagesUrl } from '../../core/appConstants'
import { OrganizationContext } from '../../core/context/organization/organization-context'
import { UserContext } from '../../core/context/user/user-context'
import { Collaborator } from '../../core/dto/user/collaborator'
import { UserForm } from '../../core/dto/user/user-form'
import { UserUpdateDto } from '../../core/dto/user/user-update-dto'
import { CollaboratorTypeEnum } from '../../core/enum/user/collaboratorTypeEnum'
import { OrganizationRoleEnum } from '../../core/enum/user/organization-role-enum'
import { useOrganization } from '../../core/hooks/use-organization'
import { useUser } from '../../core/hooks/use-user'
import { isMegaUser } from '../../core/services/authentication-service'
import { getFullNameLabel } from '../../core/services/helper-service'
import { roleSuperUserSelectOptions, roleUpdateSelectOptions } from '../../core/services/user-service'
import { SearchAndFilter } from '../beem-pilot/components/SearchAndFilter'
import { RoleUserChip } from '../beem-pilot/rseeTeam/components/RoleUserChip'

type IProps = {
  dataCollab: Collaborator[]
  setCollabList: React.Dispatch<React.SetStateAction<Collaborator[]>>
}

const ITEMS_PER_PAGE = 5

export default function OrganizationCollaborators({ dataCollab, setCollabList }: Readonly<IProps>): React.JSX.Element {
  const navigate = useNavigate()
  const { fetchCollaborators } = useOrganization()
  const { adminUpdateUser, sendInvitation } = useUser()

  const openSuccessSnackbar = useContext(SuccessContext)
  const openErrorSnackbar = useContext(ErrorContext)
  const { refreshOrganization } = useContext(OrganizationContext)
  const { user } = useContext(UserContext)

  const [selectedUser, setSelectedUser] = useState<Collaborator | undefined>(undefined)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [searchState, setSearchState] = useState<string | undefined>(undefined)
  const [page, setPage] = useState(1)

  const startIndex = useMemo(() => (page - 1) * ITEMS_PER_PAGE, [page])
  const endIndex = useMemo(() => startIndex + ITEMS_PER_PAGE, [startIndex])

  const refreshCollab = useCallback(() => {
    refreshOrganization()
    fetchCollaborators().then((collab) => {
      setCollabList(collab)
    })
  }, [fetchCollaborators, refreshOrganization, setCollabList])

  useEffect(() => {
    refreshCollab()
  }, [refreshCollab])

  const itemsToShow = useMemo(() => {
    const list = dataCollab.filter(
      (x) =>
        x.familyName.toLowerCase().includes(searchState?.toLowerCase() ?? '') ||
        x.givenName.toLowerCase().includes(searchState?.toLowerCase() ?? '') ||
        x.email.toLowerCase().includes(searchState?.toLowerCase() ?? '')
    )
    return list
  }, [dataCollab, searchState])

  function handleChange(event: any, value: React.SetStateAction<number>): void {
    setPage(value)
  }

  function handleSearch(e: string): void {
    setPage(1)
    setSearchState(e)
  }

  function onSubmit(userForm: UserForm): Promise<void> {
    if (selectedUser) {
      return updateUser(userForm).then(() => {
        refreshCollab()
        handleClose()
      })
    } else {
      return sendInvitationFromUser(userForm).then(() => {
        refreshCollab()
        handleClose()
      })
    }
  }

  function sendInvitationFromUser(userInvitationDto: UserForm): Promise<void> {
    return sendInvitation(userInvitationDto).then(() => {
      openSuccessSnackbar("Invitation envoyée à l'utilisateur")
    })
  }

  function updateUser(userForm: UserForm): Promise<void> {
    if (selectedUser === undefined) {
      openErrorSnackbar(new Error("Pas d'utilisateur sélectionné"))
      navigate(pagesUrl.MY_ORGANIZATION_PAGE)
      return Promise.resolve()
    }

    const userUpdateDto: UserUpdateDto = {
      cognitoUserId: selectedUser.cognitoUserId,
      givenName: userForm.firstName,
      familyName: userForm.lastName,
      phone: userForm.phone,
      email: userForm.email,
      role: userForm.userOrganizationRole,
    }

    return adminUpdateUser(userUpdateDto)
      .then(() => refreshOrganization())
      .then(() => {
        openSuccessSnackbar('Utilisateur modifié')
        navigate(pagesUrl.MY_ORGANIZATION_PAGE)
      })
  }

  function handleClose(): void {
    setSelectedUser(undefined)
    setOpenDialog(false)
  }

  return (
    <>
      <Box>
        <SearchAndFilter searchState={searchState} handleSearch={handleSearch} searchStateLabel='Rechercher par nom ' />
        <Grid container alignItems='flex-start' height='100%' rowGap={1}>
          {itemsToShow.length > 0 ? (
            <>
              <Grid container px={2} pb={1}>
                <Grid item xs={3}>
                  <Typography variant='subtitle2' fontSize={12} sx={{ color: '#8F8F8F' }}>
                    Nom
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant='subtitle2' fontSize={12} sx={{ color: '#8F8F8F' }}>
                    Adresse e-mail
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant='subtitle2' fontSize={12} sx={{ color: '#8F8F8F' }}>
                    Agence
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant='subtitle2' fontSize={12} sx={{ color: '#8F8F8F' }}>
                    Téléphone
                  </Typography>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1}>
                  <Typography variant='subtitle2' fontSize={12} sx={{ color: '#8F8F8F' }}>
                    Role
                  </Typography>
                </Grid>
              </Grid>
              {itemsToShow.slice(startIndex, endIndex).map((x) => {
                const fullName = getFullNameLabel(x.givenName, x.familyName)
                return (
                  <Grid
                    display='flex'
                    alignItems='center'
                    container
                    key={x.cognitoUserId}
                    borderRadius={4}
                    onClick={() => {
                      setSelectedUser(x)
                      setOpenDialog(true)
                    }}
                    p={2}
                    sx={{ boxShadow: '2px 4px 20px 0px #0000000D', background: 'white', cursor: 'pointer' }}>
                    <Grid item xs={3}>
                      {fullName}
                    </Grid>
                    <Grid item xs={3} sx={{ color: '#8F8F8F' }}>
                      {x.email}
                    </Grid>
                    <Grid item xs={2}>
                      <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
                        <IconMapPin />
                        {x.organizationName}
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      {x.phone}
                    </Grid>
                    <Grid item xs={1}>
                      {x.type === CollaboratorTypeEnum.WAITING_CREATION && <AccessTimeIcon sx={{ color: '#FF9800' }} />}
                    </Grid>
                    <Grid item xs={1}>
                      <RoleUserChip role={x.role} />
                    </Grid>
                  </Grid>
                )
              })}
            </>
          ) : (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              minHeight='40vh'
              width='100%'>
              <Typography fontSize='24px' fontWeight={600} lineHeight='36px' textAlign='center' color='#374771'>
                Aucun résultat
              </Typography>
              <Typography variant='body1'>Modifier les options de recherche.</Typography>
            </Box>
          )}
        </Grid>
      </Box>

      <Box
        display='flex'
        flexGrow={1}
        justifyContent='right'
        alignItems='flex-end'
        alignContent='flex-end'
        alignSelf='flex-end'>
        <Pagination
          count={Math.ceil(itemsToShow.length / ITEMS_PER_PAGE)}
          page={page}
          onChange={handleChange}
          color='primary'
        />
      </Box>

      <CustomBottomNavigation actionLabel='Ajouter' actionButton={() => setOpenDialog(true)} />

      <Dialog open={openDialog} onClose={handleClose}>
        <DialogContent>
          <CollaboratorFormComponent
            onSubmit={onSubmit}
            selectOptions={isMegaUser(user) ? roleUpdateSelectOptions : roleSuperUserSelectOptions}
            defaultRole={OrganizationRoleEnum.NORMAL_USER}
            initUser={selectedUser}
            handleClose={handleClose}
            refreshCollab={refreshCollab}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
