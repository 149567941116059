import { Alert, Snackbar } from '@mui/material'
import React, { useCallback, useState } from 'react'

export const SuccessContext = React.createContext<(message: string) => void>(() => {
  // do nothing, function will be set later
})

type Props = {
  children: any
}

export default function SuccessSnackbar({ children }: Props): React.JSX.Element {
  const [msg, setMsg] = useState<string | undefined>(undefined)
  const [open, setOpen] = useState<boolean>(false)

  function handleClose(): void {
    setMsg(undefined)
    setOpen(false)
  }

  const openSuccessSnackbar = useCallback((message: string) => {
    setMsg(message)
    setOpen(true)
  }, [])

  return (
    <SuccessContext.Provider value={openSuccessSnackbar}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} variant='filled' sx={{ width: '100%', background: '#4965AC', color: 'white' }}>
          {msg}
        </Alert>
      </Snackbar>
    </SuccessContext.Provider>
  )
}
