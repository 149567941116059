import { ITableauEventType } from './tableau-service'

// DOCUMENTATION: https://tableau.github.io/extensions-api/docs/globals.html
export const tableauExt = (window as any)?.tableau
export const tExtensions = tableauExt?.extensions

export const FilterUpdateType = tableauExt.FilterUpdateType //Add,All,Remove,Replace
export const FilterType = tableauExt.FilterType //CategoricalFilter,HierarchicalFilter,RangeFilter,RelativeDateFilter

export const PROJECT_ID_FILTER = 'Project Id'

// Types for extensions
export const AUTH_TOKEN_PARAMETER_NAME = '0P_Auth_Token'
export const DASHBOARD_TYPE_PARAMETER_NAME = '0P_Dashboard_Type'
export const PROJECT_ID_PARAMETER_NAME = '0P_Project_Id'
export const VARIANT_ID_PARAMETER_NAME = '0P_Variant_Id'
export const SECURE_TOKEN_PARAMETER_NAME = '0P_Secure_Token'
export const RSEE_DOCUMENT_ID_PARAMETER_NAME = '0P_Document_Id'
export const ICADE_CLIENT_ID_PARAMETER_NAME = '0P_Client_Id'

export const WORKSHEET_FOR_BIM_MODEL_INTERACTION = 'TBL_CODE_OCCURRENCE'

export interface Filter {
  fieldId: string
  fieldName: string
  filterType: typeof FilterType
}

export interface Dashboard {
  worksheets: WorkSheet[]
  getParametersAsync(): Promise<TableauParameter[]>
}

export interface DataTable {
  data: DataValue[][]
}
export interface DataValue {
  formattedValue: string
  nativeValue: string
  value: string
}

export interface LogicalTable {
  id: string
  getParametersAsync(): Promise<TableauParameter[]>
}

export interface FilterOptions {
  isExcludeMode: boolean
}

export interface WorkSheet {
  name: string

  addEventListener(type: ITableauEventType, filterChangedHandler: (filterEvent: any) => void): void

  applyFilterAsync(
    fieldName: string,
    values: string[],
    updateType: typeof FilterUpdateType,
    options?: FilterOptions
  ): Promise<string>

  getFiltersAsync(): Promise<Array<Filter>>
  clearFilterAsync(fieldName: string): Promise<string>

  getDataSourcesAsync(): Promise<any>
  getSummaryColumnsInfoAsync(): Promise<any>
  getSummaryDataAsync(): Promise<DataTable>
  getUnderlyingDataAsync(): Promise<DataTable>
  getUnderlyingTableDataAsync(tableId: string): Promise<DataTable>
  getUnderlyingTablesAsync(): Promise<LogicalTable[]>
}

export interface TableauParameter {
  id: string
  name: string
  datatype: string
  currentValue: { formattedValue: string; nativeValue: string; value: string }
}

export const tableauExtensionsService = {
  async fetchParameters(extensions: any): Promise<TableauParameter[]> {
    const dashboard: Dashboard = extensions?.dashboardContent?.dashboard
    if (dashboard) {
      return dashboard.getParametersAsync()
    } else {
      return []
    }
  },
  async fetchParameter(extensions: any, parameterName: string): Promise<TableauParameter | undefined> {
    let parameter: TableauParameter | undefined
    const dashboard: Dashboard = extensions?.dashboardContent?.dashboard
    if (dashboard) {
      await dashboard.getParametersAsync().then((parameters: TableauParameter[]) => {
        parameter = parameters.find((param: TableauParameter) => param.name === parameterName)
      })
    }
    console.info('parameter:', parameter)
    return parameter
  },
  getWorksheetByName(sheetName: string): WorkSheet | undefined {
    const dashboard: Dashboard = tExtensions.dashboardContent.dashboard
    return dashboard.worksheets.find((sheet: any) => sheet.name === sheetName)
  },
  clearAllFiltersExcept(filtersToKeep: string[]): Promise<void> {
    const dashboard: Dashboard = tExtensions.dashboardContent.dashboard
    const worksheetPromises: Promise<string[]>[] = []

    for (const worksheet of dashboard.worksheets) {
      const worksheetPromise = worksheet.getFiltersAsync().then((filtersForWorksheet: Filter[]) => {
        const filterPromises: Promise<string>[] = []
        for (const filter of filtersForWorksheet) {
          const b: boolean = filtersToKeep.some((filterName) => filterName === filter.fieldName)
          if (!b) {
            const filterPromise = worksheet.clearFilterAsync(filter.fieldName)
            filterPromises.push(filterPromise)
          }
        }
        return Promise.all(filterPromises)
      })
      worksheetPromises.push(worksheetPromise)
    }

    return Promise.all(worksheetPromises).then((whatever) => {
      console.info('updated filters', whatever)
      return undefined
    })
  },
  clearAllActionFilters(): Promise<void> {
    const dashboard: Dashboard = tExtensions.dashboardContent.dashboard
    const worksheetPromises: Promise<void>[] = []

    const filterPromises: Promise<string>[] = []
    for (const worksheet of dashboard.worksheets) {
      const worksheetPromise = worksheet.getFiltersAsync().then((filtersForWorksheet: Filter[]) => {
        for (const filter of filtersForWorksheet) {
          console.info('test filter', filter.fieldName, 'for worksheet', worksheet.name)
          if (filter?.fieldName?.startsWith('Action')) {
            console.info('remove filter')
            const filterPromise = worksheet.clearFilterAsync(filter.fieldName)
            filterPromises.push(filterPromise)
          }
        }
      })
      worksheetPromises.push(worksheetPromise)
    }

    return Promise.all(worksheetPromises)
      .then(() => Promise.all(filterPromises))
      .then((updatedFilterNames) => {
        console.info('updated filters', updatedFilterNames)
        return undefined
      })
  },
}
