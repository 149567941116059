import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Children } from '../../../components/miscellianous/children'
import { useTeams } from '../../hooks/use-team'
import { RseeProjectContext } from '../beem-pilot/rsee/rsee-project-context'

export const RseeProjectRoleContext = React.createContext<RseeProjectRoleStore>({} as RseeProjectRoleStore)

export default function RseeProjectRoleContextProvider({ children }: Children): React.JSX.Element {
  const { rseeProject } = useContext(RseeProjectContext)
  const { hasRseeProject } = useTeams()
  const [currentRight, setCurrentRight] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (rseeProject?.id && !isLoading) {
      setIsLoading(true)
      hasRseeProject(rseeProject.id)
        .then((response: boolean) => {
          setCurrentRight(response)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [hasRseeProject, rseeProject?.id])

  const rseeProjectRoleStore: RseeProjectRoleStore = useMemo(() => ({ hasProject: currentRight }), [currentRight])

  return <RseeProjectRoleContext.Provider value={rseeProjectRoleStore}>{children}</RseeProjectRoleContext.Provider>
}

export type RseeProjectRoleStore = {
  hasProject: boolean
}
