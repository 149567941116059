import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import TextButton from '../buttons/text-button/TextButton'
import { ValidateButton } from '../buttons/validate-button/validate-button'
import { TitleFormTypography } from '../typography/title-form-typography'

interface IProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  titleText: string
  contentText: React.ReactNode
  cancelButtonLabel: string
  submitButtonLabel: string
}

export function BSSubmitDialog({
  isOpen,
  onClose,
  onSubmit,
  titleText,
  contentText,
  cancelButtonLabel,
  submitButtonLabel,
}: Readonly<IProps>): React.JSX.Element {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <TitleFormTypography label={titleText} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        <Box sx={{ px: 1 }}>
          <TextButton onClick={onClose} label={cancelButtonLabel} />
        </Box>
        <ValidateButton label={submitButtonLabel} action={onSubmit} />
      </DialogActions>
    </Dialog>
  )
}
