import { Search } from '@mui/icons-material'
import { Autocomplete, InputAdornment, TextField } from '@mui/material'
import React from 'react'

type IProps<T> = {
  label: string
  value: T | null
  options: T[] | undefined
  onChange(event: any, newValue: T | null): void
  getOptionLabel(option: T): string
  disablePortal?: boolean
}

export function SearchField<T>({
  label,
  value,
  options,
  onChange,
  getOptionLabel,
  disablePortal = true,
}: IProps<T>): React.JSX.Element {
  return (
    <Autocomplete
      fullWidth
      disablePortal={disablePortal}
      id='searchBar'
      value={value}
      options={options ?? []}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, valueToCompare) => option === valueToCompare}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={label}
          fullWidth
          sx={{
            borderRadius: '4px',
            background: '#0000000F 0% 0% no-repeat padding-box;',
            boxSizing: 'border-box',
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position='start'>
                <Search />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}
