import { SvgIcon } from '@mui/material'
import React from 'react'

type IProps = {
  style?: any
}

export function IconBeemPilot(props: IProps): React.JSX.Element {
  const { style } = props

  return (
    <SvgIcon
      {...props}
      fillOpacity={0}
      viewBox='0 0 38 38'
      xmlns='http://www.w3.org/2000/svg'
      sx={{ width: style?.width ? style?.width : 24, height: style?.height ? style?.height : 24 }}>
      <path
        d='M26.9166 33.25H11.0833C10.2434 33.25 9.43799 32.9164 8.84412 32.3225C8.25026 31.7286 7.91663 30.9232 7.91663 30.0833V7.91667C7.91663 
        7.07681 8.25026 6.27136 8.84412 5.6775C9.43799 5.08363 10.2434 4.75 11.0833 4.75H22.1666L30.0833 12.6667V30.0833C30.0833 30.9232 
        29.7497 31.7286 29.1558 32.3225C28.5619 32.9164 27.7565 33.25 26.9166 33.25Z'
        fill='#97FFD7'
        stroke='#0BE2A0'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M14.25 26.9167V19' stroke='#009069' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M19 26.9163V25.333' stroke='#009069' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M23.75 26.917V22.167' stroke='#009069' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M22.1666 4.75V11.0833C22.1666 11.5033 22.3334 11.906 22.6304 12.2029C22.9273 12.4999 23.33 12.6667 23.75 12.6667H30.0833'
        fill='#0BE2A0'
      />
      <path
        d='M22.1666 4.75V11.0833C22.1666 11.5033 22.3334 11.906 22.6304 12.2029C22.9273 12.4999 23.33 12.6667 23.75 12.6667H30.0833L22.1666 4.75Z'
        stroke='#009069'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  )
}
