import CheckIcon from '@mui/icons-material/Check'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'
import { Box } from '@mui/material'
import React from 'react'
import { DefaultTypography } from '../../typography/default-typography'

type IProps = {
  isConforme: boolean
  height?: number
}

export function RseeProjectCardSeuil({ isConforme, height }: IProps): React.JSX.Element {
  function getSeuilBackgroundColor(): string {
    return isConforme ? '#E5FFF4' : '#FEEBEE'
  }

  function getTypologyColor(): JSX.Element | undefined {
    return isConforme ? (
      <>
        <CheckIcon fontSize='medium' sx={{ color: '#009069', pr: 1 }} />
        <DefaultTypography label='Conforme RE2020' fontSize='14px' lineHeight='20.02px' fontWeight={400} color='#009069' />
      </>
    ) : (
      <>
        <WarningAmberRoundedIcon fontSize='medium' sx={{ color: '#E65100', pr: 1 }} />
        <DefaultTypography
          label='Non conforme RE2020'
          fontSize='14px'
          lineHeight='20.02px'
          fontWeight={400}
          color='#E65100'
        />
      </>
    )
  }

  function getBProjectCardStatus(): JSX.Element {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        minHeight={height}
        borderRadius={2}
        border={1.5}
        borderColor={isConforme ? '#009069' : '#FF6060'}
        px={1}
        sx={{ width: '100%', backgroundColor: getSeuilBackgroundColor() }}>
        {getTypologyColor()}
      </Box>
    )
  }

  return getBProjectCardStatus()
}
