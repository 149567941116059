import { Check } from '@mui/icons-material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material'
import React, { FormEvent, useContext, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { adminPagesUrl } from '../../../core/appConstants'
import { AdminOrganizationContext } from '../../../core/context/organization/admin-organization-context'
import { DomainNameValidatorContext } from '../../../core/context/organization/domain-name-validator-context'
import { UserContext } from '../../../core/context/user/user-context'
import { Collaborator } from '../../../core/dto/user/collaborator'
import { CollaboratorForm, UserForm } from '../../../core/dto/user/user-form'
import { CollaboratorTypeEnum } from '../../../core/enum/user/collaboratorTypeEnum'
import { OrganizationRoleEnum } from '../../../core/enum/user/organization-role-enum'
import { useUser } from '../../../core/hooks/use-user'
import { resolveUrl } from '../../../core/services/http-service'
import { isEmailValid, isPhoneValid } from '../../../core/services/user-service'
import CancelButton from '../../buttons/cancel-button/cancel-button'
import PhoneInput from '../../inputs/phone-input/phone-input'
import SelectInput, { SelectOption } from '../../inputs/select-input/select-input'
import { TextInput } from '../../inputs/text-input/text-input'
import { SuccessContext } from '../../layout/success-snackbar'
import UserTableDialog from '../../tables/display-user/user-table-dialog'

type IProps = {
  onSubmit(userForm: UserForm): Promise<void>
  selectOptions: SelectOption<OrganizationRoleEnum>[]
  defaultRole: OrganizationRoleEnum
  initUser?: Collaborator
  isFirstMegaUser?: boolean
  handleClose?(): void
  refreshCollab?(): void
}

export function CollaboratorFormComponent({
  onSubmit,
  selectOptions,
  defaultRole,
  initUser = undefined,
  isFirstMegaUser,
  handleClose,
  refreshCollab,
}: Readonly<IProps>): React.JSX.Element {
  const { organizationId } = useParams()
  const navigate = useNavigate()
  const { disableUser, adminDisableUser, cancelUserOrganisationInvitation } = useUser()

  const { isDomainNameInvalid } = useContext(DomainNameValidatorContext)
  const { organization } = useContext(AdminOrganizationContext)
  const { user } = useContext(UserContext)
  const openSuccessSnackbar = useContext(SuccessContext)

  const [invitationForm, setInvitationForm] = useState<UserForm>(userFormFromCollaborator(initUser))
  const [error, setError] = useState<any>({})
  const [openDeleteUser, setOpenDeleteUser] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const isCreation = useMemo(() => initUser === undefined, [initUser])

  function userFormFromCollaborator(actualUser: Collaborator | undefined): CollaboratorForm {
    if (!actualUser) {
      return {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        userOrganizationRole: defaultRole,
      }
    } else {
      return {
        firstName: actualUser.givenName,
        lastName: actualUser.familyName,
        phone: actualUser.phone,
        email: actualUser.email,
        userOrganizationRole: actualUser.role,
      }
    }
  }

  function validate(): boolean {
    const newError: Record<string, any> = {}
    let isValid = true

    if (!invitationForm.firstName) {
      newError.firstName = 'Le prénom doit être renseigné'
      isValid = false
    }

    if (!invitationForm.lastName) {
      newError.lastName = 'Le nom doit être renseigné'
      isValid = false
    }

    if (!invitationForm.phone) {
      newError.phone = 'Le numéro de téléphone doit être renseigné'
      isValid = false
    } else if (!isPhoneValid(invitationForm.phone)) {
      newError.phone =
        'Le numéro de téléphone doit être composé uniquement de chiffres et faire 10 caractères ou commencer par "+" et faire 12 caractères'
      isValid = false
    }

    if (!invitationForm.email) {
      newError.email = "L'email doit être renseigné"
      isValid = false
    } else if (!isEmailValid(invitationForm.email)) {
      newError.email = "L'adresse email ne respecte pas la forme classique : email@complement.fin"
      isValid = false
    } else if (isDomainNameInvalid(invitationForm.email)) {
      newError.email = "Time To Beem est un service B2B. Merci d'utiliser une adresse email professionnelle"
      isValid = false
    }

    setError(newError)
    return isValid
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()

    if (!validate()) {
      return
    }

    invitationForm.email = invitationForm.email.toLowerCase()

    setIsSubmitting(true)
    onSubmit(invitationForm).finally(() => setIsSubmitting(false))
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const target = event.target

    setInvitationForm({
      ...invitationForm,
      [target.id]: target.value,
    })
  }

  function handleChangeRole(newRole: OrganizationRoleEnum): void {
    setInvitationForm({
      ...invitationForm,
      userOrganizationRole: newRole,
    })
  }

  function onDeleteCollaborator(): Promise<void> {
    if (initUser === undefined) {
      return Promise.resolve()
    }

    let promise: Promise<Response | undefined>
    if (user?.role === OrganizationRoleEnum.MEGA_USER) {
      promise = disableUser(initUser.cognitoUserId)
    } else if (user?.role === OrganizationRoleEnum.ULTIMATE_USER && !!organizationId) {
      promise = adminDisableUser(initUser.cognitoUserId, organizationId)
    } else {
      promise = Promise.resolve(undefined)
    }
    return promise.then(() => {
      handleCloseDeleteDialog()
      if (handleClose) {
        handleClose()
      }
      if (refreshCollab) {
        refreshCollab()
        openSuccessSnackbar("L'utilisateur a été désactivé avec succès")
      }
    })
  }

  function handleCloseDeleteDialog(): void {
    setOpenDeleteUser(false)
  }

  return (
    <>
      <Box component='form' onSubmit={handleSubmit}>
        {!isFirstMegaUser && (
          <Box display='flex' flexDirection='column' gap={1}>
            <Typography fontSize={24} fontWeight={600} sx={{ pt: '-200px' }}>
              {isCreation ? 'Ajouter un collaborateur' : 'Modifier un collaborateur'}
            </Typography>
            <Typography variant='body1' fontSize={14} sx={{ pb: 2 }}>
              {isCreation
                ? 'Renseignez les informations de votre collaborateur. Il recevra un e-mail à l’adresse indiquée afin de créer son compte Time To Beem.  '
                : 'Les informations du collaborateur sont visibles depuis son compte Time To Beem.  '}
            </Typography>
          </Box>
        )}
        {isFirstMegaUser && (
          <Typography variant='h6' sx={{ pt: '-200px' }}>
            Inscription du 1er méga user
          </Typography>
        )}
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={12} sm={12}>
            <TextInput
              label='Prénom'
              fieldName='firstName'
              handleChange={handleChange}
              form={invitationForm}
              errors={error}
              margin='none'
              backgroundColor='#F5F5F5'
              borderRadius={3}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput
              label='Nom'
              fieldName='lastName'
              handleChange={handleChange}
              form={invitationForm}
              errors={error}
              margin='none'
              backgroundColor='#F5F5F5'
              borderRadius={3}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <TextInput
              label='Adresse e-mail'
              fieldName='email'
              handleChange={handleChange}
              form={invitationForm}
              errors={error}
              margin='none'
              backgroundColor='#F5F5F5'
              borderRadius={3}
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            <PhoneInput
              form={invitationForm}
              error={error}
              fullWidth
              handleChange={handleChange}
              variant='outlined'
              margin='none'
              backgroundColor='#F5F5F5'
              borderRadius={3}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <SelectInput
              id='role'
              label='Rôle'
              mode='direct'
              handleChange={handleChangeRole}
              options={selectOptions}
              selectedOption={invitationForm.userOrganizationRole}
              backgroundColor='#F5F5F5'
              borderRadius={3}
            />
          </Grid>

          {isCreation ? (
            <Grid item xs={12} sm={12}>
              <Typography variant='body2' fontSize={12} sx={{ color: '#8F8F8F' }}>
                Tous les champs sont obligatoires.
              </Typography>
            </Grid>
          ) : (
            <>
              {false && (
                <Grid item xs={12} sm={7}>
                  {initUser?.type === CollaboratorTypeEnum.WAITING_CREATION && (
                    <Box
                      display='flex'
                      alignItems='center'
                      gap={1}
                      border={2}
                      borderRadius={6}
                      borderColor='#FFCC80'
                      sx={{ background: '#FFF3E0' }}>
                      <AccessTimeIcon sx={{ color: '#FF9800', ml: 1 }} />
                      <Typography variant='body2' fontWeight={400} sx={{ color: '#F57C00' }}>
                        Invitation envoyé le 27/01/2024
                      </Typography>
                    </Box>
                  )}

                  {initUser?.type === CollaboratorTypeEnum.CREATED && (
                    <Box
                      display='flex'
                      gap={1}
                      alignItems='center'
                      border={2}
                      borderRadius={6}
                      borderColor='#DDE6E8'
                      sx={{ background: '#E6F0FB' }}>
                      <Check sx={{ color: '#84ABD6', ml: 1 }} />
                      <Typography variant='body2' fontWeight={400} sx={{ color: '#4965AC' }}>
                        Membre depuis le 27/01/2024
                      </Typography>
                    </Box>
                  )}
                </Grid>
              )}

              {initUser?.type !== CollaboratorTypeEnum.WAITING_CREATION && (
                <Grid container gap={1} item xs={12} sm={12}>
                  <Typography variant='subtitle1'>Retirer ce collaborateur ?</Typography>
                  <Typography variant='body2'>
                    Le collaborateur aura toujours accès à la plateforme Time To Beem mais il ne fera plus partie de votre
                    organisation. Il n’aura plus accès aux projets de l’organisation.
                  </Typography>
                  <Button
                    variant='contained'
                    onClick={() => setOpenDeleteUser(true)}
                    sx={{ backgroundColor: '#FFDEDE', color: '#A90B0B' }}>
                    Retirer de mon organisation
                  </Button>
                </Grid>
              )}
            </>
          )}
        </Grid>

        <Divider sx={{ pt: 2 }} />

        <Box gap={2} sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
          {isSubmitting ? (
            <CircularProgress />
          ) : (
            <>
              {isFirstMegaUser && (
                <Button
                  sx={{ mr: 2 }}
                  onClick={() => navigate(resolveUrl(adminPagesUrl.ADMIN_ORGANIZATION_PAGE, [organization?.id], {}))}>
                  Ignorer
                </Button>
              )}
              <CancelButton
                action={() => {
                  if (handleClose) {
                    handleClose()
                  }
                }}
                label='Annuler'
              />
              {initUser?.type !== CollaboratorTypeEnum.WAITING_CREATION && (
                <Button variant='contained' type='submit'>
                  {isCreation ? 'Inviter' : 'Enregistrer'}
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
      <UserTableDialog
        openDeleteUser={openDeleteUser}
        handleClose={handleCloseDeleteDialog}
        onDelete={onDeleteCollaborator}
      />
    </>
  )
}
