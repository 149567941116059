import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import { CopyLinkButton } from '../../../../components/buttons/CopyLinkButton/CopyLinkButton'
import { IconTrashX } from '../../../../components/custom-icons/icon-trash-x'
import { BSSubmitDialog } from '../../../../components/dialog/BSSubmitDialog'
import { ShareLink } from '../../../../core/dto/rsee/share-link'

interface IProps {
  shareInformationList: ShareLink[] | undefined
  deleteShareLink(element: ShareLink): Promise<void>
  isSearching?: boolean
}

export function RseeProjectShareLinkDisplayer({
  shareInformationList,
  deleteShareLink,
  isSearching = false,
}: Readonly<IProps>): React.JSX.Element {
  const [open, setOpen] = useState<boolean>(false)
  const [selectedShareLink, setSelectedShareLink] = useState<ShareLink>()

  return shareInformationList && shareInformationList?.length > 0 ? (
    <>
      <Grid container px={2} pb={1}>
        <Grid item xs={4}>
          <Typography variant='subtitle2' fontSize={12} sx={{ color: '#8F8F8F' }}>
            Nom du document
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant='subtitle2' fontSize={12} sx={{ color: '#8F8F8F' }}>
            Créé par
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant='subtitle2' fontSize={12} sx={{ color: '#8F8F8F' }}>
            Nombre actuel d'utilisations
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant='subtitle2' fontSize={12} sx={{ color: '#8F8F8F' }}>
            Lien
          </Typography>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      {shareInformationList.map((x) => (
        <Grid
          display='flex'
          alignItems='center'
          container
          key={x.id}
          borderRadius={4}
          p={2}
          py={3}
          sx={{ boxShadow: '2px 4px 20px 0px #0000000D', background: 'white' }}>
          <Grid item xs={4}>
            <Typography variant='subtitle2' fontSize={12}>
              {x.documentName}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant='subtitle2' fontSize={12}>
              {x.createdByUserName}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='subtitle2' fontSize={12}>
              {x.usageCounter}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <CopyLinkButton link={x.sharePageUrl} />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title='Révoquer ce lien'>
              <IconButton
                onClick={() => {
                  setOpen(true)
                  setSelectedShareLink(x)
                }}>
                <IconTrashX />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ))}

      <BSSubmitDialog
        isOpen={open}
        onClose={() => setOpen(false)}
        onSubmit={() => {
          if (selectedShareLink) {
            return deleteShareLink(selectedShareLink)
          }
          return Promise.resolve()
        }}
        titleText='Êtes-vous sûr ?'
        contentText='Vous êtes sur le point de supprimer ce lien de partage. Êtes-vous sûr de vouloir continuer ?'
        cancelButtonLabel='Annuler'
        submitButtonLabel='Confirmer'
      />
    </>
  ) : (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' minHeight='40vh' width='100%'>
      <Typography fontSize='24px' fontWeight={600} lineHeight='36px' textAlign='center' color='#374771'>
        Aucun résultat
      </Typography>
      {isSearching ? (
        <Typography variant='body1'>Modifier les options de recherche.</Typography>
      ) : (
        <Typography variant='body1'> Aucun lien n'a été créé sur ce projet </Typography>
      )}
    </Box>
  )
}
