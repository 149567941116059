import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined'
import HeadphonesOutlinedIcon from '@mui/icons-material/HeadphonesOutlined'
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined'
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined'
import {
  Box,
  Button,
  CSSObject,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  Link,
  styled,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'
import Fab from '@mui/material/Fab'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as ReactLogoTTB } from '../../../assets/LogoTTB_green.svg'
import { ReactComponent as ReactMiniLogoTTB } from '../../../assets/LogoTTB_mini_green.svg'
import { pagesUrl } from '../../core/appConstants'
import { OrganizationContext } from '../../core/context/organization/organization-context'
import { AppSideBarContext, UrlStateEnum } from '../../core/context/side-bar/side-bar-context'
import { SupportForm } from '../../core/dto/support/support-form'
import { ProjectTypeEnum } from '../../core/enum/project/projectTypeEnum'
import { useUser } from '../../core/hooks/use-user'
import { resolveUrl } from '../../core/services/http-service'
import { IconBeemMaster } from '../custom-icons/icon-beem-master'
import { IconFileAnalytics } from '../custom-icons/icon-file-analytics'
import { InfoDialog } from '../dialog/InfoDialog'
import { SupportFormComponent } from '../forms/support-form-component'
import './app-side-navbar.scss'
import DividerSideBar from './divider-side-navbar'
import { IconSideBar } from './icone-side-navbar'

const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  background: '#070F27',
  scrollbarWidth: 'none',
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius,
  height: `calc(100vh - 16px * 2)`,
  margin: theme.spacing(1),
  padding: theme.spacing(1),
  zIndex: 1200,
})

const openedFabMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  position: 'fixed',
  background: '#070F27',
  zIndex: 1300,
})

const closedFabMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  background: '#070F27',
  position: 'fixed',
  zIndex: 1300,
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  background: '#070F27',
  width: `calc(${theme.spacing(7)} + 1px)`,
  scrollbarWidth: 'none',
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(1),
  padding: theme.spacing(1),
  paddingBottom: theme.spacing(4),
  height: `calc(100vh - 16px * 2)`,
  zIndex: 1200,
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
}))

export function AppSideBar(): React.JSX.Element {
  const theme = useTheme()
  const navigate = useNavigate()
  const { sendSupportForm } = useUser()

  const { organization } = useContext(OrganizationContext)
  const { showAppSide, drawerWidth, openAppSide, setOpenAppSide } = useContext(AppSideBarContext)
  const [isBeemMasterWarningOpen, setIsBeemMasterWarningOpen] = useState(false)
  const [isBeemPilotWarningOpen, setIsBeemPilotWarningOpen] = useState(false)
  const [openContactForm, setOpenContactForm] = useState<boolean>(false)
  const [openConfirmContact, setOpenConfirmContact] = useState<boolean>(false)

  const { urlState } = useContext(AppSideBarContext)

  function goToBeemMaster(): void {
    if (organization?.beemMasterOption) {
      navigate(resolveUrl(pagesUrl.PROJECTS_PAGE, [], { type: ProjectTypeEnum.BIM }))
    } else {
      setIsBeemMasterWarningOpen(true)
    }
  }

  function goToBeemPilot(): void {
    if (organization?.beemPilotOption) {
      navigate(resolveUrl(pagesUrl.BEEM_PILOT_HOME, [], {}))
    } else {
      setIsBeemPilotWarningOpen(true)
    }
  }

  function handleClose(): void {
    setOpenContactForm(false)
  }

  function handleCloseConfirmModal(): void {
    setOpenConfirmContact(false)
  }

  function sendMessageToSupportTeam(supportForm: SupportForm): Promise<void> {
    sendSupportForm(supportForm)
      .then(() => {
        setOpenContactForm(false)
      })
      .catch((error: any) => {
        console.error('Issue with supportForm :', error)
      })
    return Promise.resolve()
  }

  return (
    <>
      <Box hidden={!showAppSide} visibility={showAppSide ? 'visible' : 'hidden'}>
        <Fab
          color='primary'
          onClick={() => setOpenAppSide(!openAppSide)}
          sx={{
            position: 'absolute',
            top: 40,
            left: openAppSide ? 240 : `calc(${theme.spacing(7)} + 1px)`,
            zIndex: 1300,
            width: 22,
            height: 22,
            minHeight: 'unset',
            lineHeight: 'normal',
            padding: 0,
            border: 2,
            borderColor: '#F5F8F8',
            ...(openAppSide
              ? {
                  ...openedFabMixin(theme),
                  '& .MuiDrawer-paper': openedFabMixin(theme),
                }
              : {
                  ...closedFabMixin(theme),
                  '& .MuiDrawer-paper': closedFabMixin(theme),
                }),
          }}>
          {openAppSide ? (
            <ChevronLeftIcon sx={{ color: 'white', width: 15, height: 15 }} />
          ) : (
            <ChevronRightIcon sx={{ color: 'white', width: 15, height: 15 }} />
          )}
        </Fab>
        <Drawer
          variant='permanent'
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            overflowX: 'hidden',
            ...(openAppSide
              ? {
                  ...openedMixin(theme, drawerWidth),
                  '& .MuiDrawer-paper': openedMixin(theme, drawerWidth),
                  height: 0,
                }
              : {
                  ...closedMixin(theme),
                  '& .MuiDrawer-paper': closedMixin(theme),
                  height: 0,
                }),
          }}>
          <DrawerHeader
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pt: 3,
              pb: 3,
            }}>
            {openAppSide ? <ReactLogoTTB /> : <ReactMiniLogoTTB />}
          </DrawerHeader>
          <Box display='flex' flexDirection='column' height='100%' justifyContent='space-between' sx={{ color: 'white' }}>
            <Box>
              <DividerSideBar label='Projet Beem' open={openAppSide} />

              <IconSideBar action={goToBeemMaster} open={openAppSide} icon={<IconBeemMaster />} label='Beem Master' />
              <InfoDialog
                isOpen={isBeemMasterWarningOpen}
                title="Vous n'êtes pas encore un 'BEEM Master'"
                content={
                  <>
                    <DialogContentText>
                      Le module Beem Master permet aux ingénieurs environnementaux d'utiliser votre estimation comme base de
                      leur calcul.
                    </DialogContentText>
                    <DialogContentText sx={{ pt: 2 }}>Pour un essai gratuit, contactez nous:</DialogContentText>
                    <DialogContentText sx={{ textAlign: 'center', pt: 3 }}>
                      <Link>contact@timetobeem.com</Link>
                    </DialogContentText>
                  </>
                }
                handleClose={() => setIsBeemMasterWarningOpen(false)}
              />

              <IconSideBar
                action={() => navigate(resolveUrl(pagesUrl.BEEM_SHOT_HOME, []))}
                open={openAppSide}
                icon={<DonutSmallOutlinedIcon />}
                label={UrlStateEnum.BEEM_SHOT}
                isActive={urlState === UrlStateEnum.BEEM_SHOT}
              />

              <DividerSideBar label='Projet Rsee' open={openAppSide} />

              <IconSideBar
                action={goToBeemPilot}
                open={openAppSide}
                icon={<IconFileAnalytics style={{ color: urlState === UrlStateEnum.BEEM_PILOT ? 'black' : 'white' }} />}
                label={UrlStateEnum.BEEM_PILOT}
                isActive={urlState === UrlStateEnum.BEEM_PILOT}
              />
              <InfoDialog
                isOpen={isBeemPilotWarningOpen}
                title="Vous n'êtes pas encore un 'BEEM Pilot'"
                content={
                  <>
                    <DialogContentText>
                      Le module Beem Pilot génère des tableaux de bord à partir d'un RSEE.
                    </DialogContentText>
                    <DialogContentText>Il permet de piloter l'impact global de tous vos projets.</DialogContentText>
                    <DialogContentText sx={{ pt: 2 }}>Pour un essai gratuit, contactez nous:</DialogContentText>
                    <DialogContentText sx={{ textAlign: 'center', pt: 3 }}>
                      <Link>contact@timetobeem.com</Link>
                    </DialogContentText>
                  </>
                }
                handleClose={() => setIsBeemPilotWarningOpen(false)}
              />

              <Divider sx={{ color: 'white' }} />
            </Box>

            <Box sx={{ pb: 3 }}>
              <DividerSideBar label='Aide' open={openAppSide} />

              <IconSideBar
                action={() => setOpenAppSide(!openAppSide)}
                open={openAppSide}
                icon={<QuizOutlinedIcon />}
                label='FAQ'
              />

              <IconSideBar
                action={() => setOpenContactForm(true)}
                open={openAppSide}
                icon={<HeadphonesOutlinedIcon />}
                label='Contacter le support'
              />
              <IconSideBar
                action={() => setOpenAppSide(!openAppSide)}
                open={openAppSide}
                icon={<SupportOutlinedIcon />}
                label='Tutoriels'
              />
            </Box>
          </Box>
        </Drawer>
      </Box>

      <Dialog maxWidth='lg' open={openContactForm} onClose={handleClose}>
        <DialogTitle sx={{ backgroundColor: 'white' }}>
          <Typography fontSize={24} fontWeight={600}>
            Contacter le support
          </Typography>
        </DialogTitle>
        <DialogContentText>
          <SupportFormComponent onSubmit={sendMessageToSupportTeam} handleClose={handleClose} />
        </DialogContentText>
      </Dialog>

      <Dialog open={openConfirmContact} onClose={handleClose}>
        <DialogContentText>
          <Typography>
            Si le problème persiste, notre équipe est là pour vous aider. Planifiez une session de support pour une
            assistance personnalisée. A bientôt 👋
          </Typography>
        </DialogContentText>
        <DialogActions>
          <Button onClick={() => setOpenConfirmContact(false)}>Terminer</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
