/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import React from 'react'

type IProps = {
  style?: any
}

export function IconBuildingCommunity(props: IProps): React.JSX.Element {
  const { style } = props
  return (
    <SvgIcon
      {...props}
      fillOpacity={0}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      sx={{ width: style?.width ? style?.width : '24', height: style?.height ? style?.height : '24' }}>
      {/*  SVG from https://tablericons.com/ */}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='icon icon-tabler icon-tabler-building-community'
        viewBox='0 0 24 24'
        strokeWidth='1.5'
        stroke='#2c3e50'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'>
        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
        <path d='M8 9l5 5v7h-5v-4m0 4h-5v-7l5 -5m1 1v-6a1 1 0 0 1 1 -1h10a1 1 0 0 1 1 1v17h-8' />
        <path d='M13 7l0 .01' />
        <path d='M17 7l0 .01' />
        <path d='M17 11l0 .01' />
        <path d='M17 15l0 .01' />
      </svg>
    </SvgIcon>
  )
}
