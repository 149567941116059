import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import React, { useContext, useState } from 'react'
import { ProjectListEmpty } from '../../../components/project-list-empty/projectListEmpty'
import { BSProjectListContext } from '../../../core/context/beem-shot/BSProject/BSProjectListContext'
import { BSProjectList } from './BSProjectList'
import { CustomTabPanel, TabPanelProps } from './CustomTabPanel'

export function BSProjectListContainer(): React.JSX.Element {
  const { bsProjectList } = useContext(BSProjectListContext)

  const [tabs, setTabs] = React.useState('BS_PROJECTS')
  const [numberProject, setNumberProject] = useState<number>(0)

  function handleChange(event: React.SyntheticEvent, newValue: string): void {
    setTabs(newValue)
  }

  const panels: TabPanelProps[] = [
    {
      id: 'BS_PROJECTS',
      Icon: FormatListBulletedIcon,
      label: 'Projets',
      chips: numberProject.toString(),
      content: <BSProjectList setNumberProject={setNumberProject} />,
    },
  ]

  return !bsProjectList || bsProjectList.length === 0 ? (
    <ProjectListEmpty />
  ) : (
    <CustomTabPanel panels={panels} tabs={tabs} handleChange={handleChange} />
  )
}
