import CalculateIcon from '@mui/icons-material/CalculateOutlined'
import { Grid } from '@mui/material'
import React, { useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import TitleH2 from '../../../../components/miscellianous/title-h2'
import { ProjectLinkTable } from '../../../../components/tables/share-information-table/project-link-table'
import { adminPagesUrl, pagesUrl } from '../../../../core/appConstants'
import { RseeDocumentContext } from '../../../../core/context/beem-pilot/rsee/rsee-document-context'
import { RseeProjectContext } from '../../../../core/context/beem-pilot/rsee/rsee-project-context'
import { RseeProjectRoleContext } from '../../../../core/context/user/rsee-project-role-context'
import { UserContext } from '../../../../core/context/user/user-context'
import { isUltimateUser } from '../../../../core/services/authentication-service'
import { resolveUrl } from '../../../../core/services/http-service'
import RseeHeader from '../components/rsee-header'
import { RseeTeam } from '../components/rsee-team'

export default function RseeGroupPage(): React.JSX.Element {
  const { organizationId, rseeProjectId, rseeDocumentId } = useParams()

  const { rseeProject } = useContext(RseeProjectContext)
  const { rseeDocument } = useContext(RseeDocumentContext)
  const { hasProject } = useContext(RseeProjectRoleContext)
  const { user } = useContext(UserContext)

  const isWriting = useMemo(() => hasProject, [hasProject])

  function getBackUrl(): string {
    return rseeDocumentId
      ? resolveUrl(pagesUrl.RSEE_PROJECT_DOCUMENT, [rseeProjectId, rseeDocumentId])
      : resolveUrl(pagesUrl.RSEE_PROJECT, [rseeProjectId])
  }

  function getAdminBackUrl(): string {
    return rseeDocumentId
      ? resolveUrl(adminPagesUrl.RSEE_PROJECT_DOCUMENT, [organizationId, rseeProjectId, rseeDocumentId])
      : resolveUrl(adminPagesUrl.RSEE_PROJECT, [organizationId, rseeProjectId])
  }

  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      <RseeHeader
        title='Projet'
        action={undefined}
        actionLabel=''
        navigateBackPath={isUltimateUser(user) ? getAdminBackUrl() : getBackUrl()}
        icon={<CalculateIcon sx={{ fontSize: '40px' }} />}
        isWriting={false}
      />
      {!rseeDocumentId && (
        <>
          <Grid item xs={12}>
            <TitleH2 text='Equipe du projet' />
          </Grid>
          <RseeTeam isWriting={isWriting} />
        </>
      )}
      <Grid item xs={12}>
        <TitleH2 text='Liens créés pour accèder aux tableau de bord de ce projet' />
      </Grid>
      <Grid item xs={12}>
        {rseeProject && <ProjectLinkTable rseeProject={rseeProject} rseeDocument={rseeDocument} isWriting={isWriting} />}
      </Grid>
    </Grid>
  )
}
