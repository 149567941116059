import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CopyLinkButton } from '../../../components/buttons/CopyLinkButton/CopyLinkButton'
import { KairnialProject } from '../../../core/dto/kairnial/KairnialProject'
import { useKairnial } from '../../kairnial/useKairnial'

export function KairnialProjectsComponent(): React.JSX.Element {
  const { fetchKairnialProjectList } = useKairnial()
  const [isKairnialDialogOpen, setIsKairnialDialogOpen] = useState<boolean>(false)
  const [kairnialProjectList, setKairnialProjectList] = useState<KairnialProject[]>([])
  const [kairnialProject, setKairnialProject] = useState<KairnialProject | null>(null)

  useEffect(() => {
    fetchKairnialProjectList().then((kairnialProjects) => {
      setKairnialProjectList(kairnialProjects)
    })
  }, [fetchKairnialProjectList])

  function openKairnialDialog(): void {
    setIsKairnialDialogOpen(true)
  }

  function handleClose(): void {
    setIsKairnialDialogOpen(false)
  }

  function handleChange(event: React.SyntheticEvent<Element, Event>, value: KairnialProject | null, reason: string): void {
    setKairnialProject(value)
  }

  return (
    <Box
      borderRadius={4}
      sx={{ boxShadow: '2px 4px 20px 0px #0000000D', backgroundColor: 'white', p: 4, pt: 1, mx: 2, mt: 4 }}>
      <Typography fontWeight='bold' variant='h5' fontSize={20} sx={{ mt: 4, mb: 2 }}>
        PROJET KAIRNIAL
      </Typography>
      <Grid container mt={3} gap={2}>
        <Grid item xs={12}>
          <Autocomplete
            onChange={handleChange}
            value={kairnialProject}
            renderInput={(params) => <TextField {...params} label='Selectionner un projet' />}
            options={kairnialProjectList ?? []}
            getOptionLabel={(value: KairnialProject) => value.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          {kairnialProject && <CopyLinkButton link={kairnialProject.url} />}
        </Grid>
      </Grid>
    </Box>
  )
}
