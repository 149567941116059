import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Children } from '../../../../components/miscellianous/children'
import { BSMaterialResult } from '../../../dto/beem-shot/BSMaterialResult/BSMaterialResult'
import { useBSMaterialResults } from '../../../hooks/beem-shot/useBSMaterialResults'
import { BSVariantContext } from '../BSVariant/BSVariantContext'

export const BSMaterialResultContext = React.createContext<BsMaterialResultStore>({} as BsMaterialResultStore)

export function BSMaterialContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { getBsMaterialResult, updateBsMaterialQuantities, resetBsMaterialQuantities, addBSOverridenMaterial } =
    useBSMaterialResults()

  const { selectedVariant } = useContext(BSVariantContext)

  const [bsMaterialResults, setBsMaterialResults] = useState<BSMaterialResult[]>([])

  useEffect(() => {
    if (selectedVariant?.id) {
      getBsMaterialResult(selectedVariant?.id).then((x) => setBsMaterialResults(x))
    }
  }, [getBsMaterialResult, selectedVariant?.id])

  const getAllMaterialResult = useCallback(
    (variantId: string): Promise<void> => getBsMaterialResult(variantId).then((x) => setBsMaterialResults(x)),
    [getBsMaterialResult]
  )

  const resetMaterial = useCallback(
    (variantId: string, materialResultId: string): Promise<void> =>
      resetBsMaterialQuantities(variantId, materialResultId).then(() => {
        if (selectedVariant?.id) {
          getAllMaterialResult(selectedVariant?.id)
        }
      }),
    [getAllMaterialResult, resetBsMaterialQuantities, selectedVariant?.id]
  )

  const updateMaterialQuantities = useCallback(
    (variantId: string, materialResultId: string, quantity: string): Promise<void> =>
      updateBsMaterialQuantities(variantId, materialResultId, quantity).then(() => {
        if (selectedVariant?.id) {
          getBsMaterialResult(selectedVariant?.id).then((x) => setBsMaterialResults(x))
        }
      }),
    [getBsMaterialResult, selectedVariant?.id, updateBsMaterialQuantities]
  )

  const addOverridenMaterial = useCallback(
    (variantId: string, materialResultId: string, iniesId: number): Promise<void> =>
      addBSOverridenMaterial(variantId, materialResultId, iniesId).then(() => {
        getBsMaterialResult(variantId).then((x) => setBsMaterialResults(x))
      }),
    [addBSOverridenMaterial, getBsMaterialResult]
  )

  const bsMaterialResultStore = useMemo(
    () => ({
      bsMaterialResults,
      setBsMaterialResults,
      updateMaterialQuantities,
      getAllMaterialResult,
      resetMaterial,
      addOverridenMaterial,
    }),
    [addOverridenMaterial, bsMaterialResults, getAllMaterialResult, resetMaterial, updateMaterialQuantities]
  )
  return <BSMaterialResultContext.Provider value={bsMaterialResultStore}>{children}</BSMaterialResultContext.Provider>
}

export type BsMaterialResultStore = {
  bsMaterialResults: BSMaterialResult[]
  setBsMaterialResults: React.Dispatch<React.SetStateAction<BSMaterialResult[]>>
  updateMaterialQuantities(variantId: string, materialResultId: string, quantity: string): Promise<void>
  getAllMaterialResult(variantId: string): Promise<void>
  resetMaterial(variantId: string, materialResultId: string): Promise<void>
  addOverridenMaterial(variantId: string, materialResultId: string, iniesId: number): Promise<void>
}
