import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import CancelButton from '../buttons/cancel-button/cancel-button'
import { ValidateButton } from '../buttons/validate-button/validate-button'

type IProps = {
  id: string
  title: string
  contentText: string | React.ReactNode
  handleClose: () => void
  action: () => void
  open: boolean
}

export function DeleteDialog({ id, title, contentText, handleClose, action, open }: Readonly<IProps>): React.JSX.Element {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id={id}>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <CancelButton action={handleClose} label='Annuler' />
        <ValidateButton action={action} label='Valider' />
      </DialogActions>
    </Dialog>
  )
}
