import { Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import { CopyLinkButton } from '../../../../components/buttons/CopyLinkButton/CopyLinkButton'
import { IconTrashX } from '../../../../components/custom-icons/icon-trash-x'
import { BSSubmitDialog } from '../../../../components/dialog/BSSubmitDialog'
import { ShareLink } from '../../../../core/dto/rsee/share-link'

interface IProps {
  shareInformationList: ShareLink[] | undefined
  deleteShareLink(element: ShareLink): Promise<void>
  handleClose?(): void
}

export function RseeDocumentShareLinkDisplayer({
  shareInformationList,
  deleteShareLink,
  handleClose,
}: Readonly<IProps>): React.JSX.Element {
  const [open, setOpen] = useState<boolean>(false)
  const [selectedShareLink, setSelectedShareLink] = useState<ShareLink>()

  return (
    <>
      <Grid container px={2} pb={1}>
        <Grid item xs={3}>
          <Typography variant='subtitle2' fontSize={16} fontWeight={600}>
            Liens de partage créés
          </Typography>
        </Grid>

        <Grid item xs={9} />
      </Grid>

      {shareInformationList && shareInformationList.length > 0 ? (
        <>
          <Typography variant='subtitle2' fontSize={12} px={3} sx={{ color: '#8F8F8F' }}>
            Créé par
          </Typography>
          {shareInformationList.map((x) => (
            <Grid
              container
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              key={x.id}
              borderRadius={4}
              px={3}
              py={1}
              sx={{ boxShadow: '2px 4px 20px 0px #0000000D', background: 'white' }}>
              <Grid item xs={3}>
                <Typography variant='subtitle2' fontSize={12}>
                  {x.createdByUserName}
                </Typography>
              </Grid>
              <Grid item xs={3} display='flex' alignItems='center'>
                <CopyLinkButton
                  link={x.sharePageUrl}
                  actionAfterCopy={() => {
                    if (handleClose) {
                      handleClose()
                    }
                  }}
                />
                <Tooltip title='Révoquer ce lien'>
                  <IconButton
                    onClick={() => {
                      setOpen(true)
                      setSelectedShareLink(x)
                    }}>
                    <IconTrashX />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          ))}
        </>
      ) : (
        <Typography variant='caption' sx={{ fontStyle: 'italic', textDecoration: 'underline' }}>
          Aucun lien n'a été pour créé ce document
        </Typography>
      )}

      <BSSubmitDialog
        isOpen={open}
        onClose={() => setOpen(false)}
        onSubmit={() => {
          if (selectedShareLink) {
            return deleteShareLink(selectedShareLink).then(() => setOpen(false))
          }
          return Promise.resolve()
        }}
        titleText='Êtes-vous sûr ?'
        contentText='Vous êtes sur le point de supprimer ce lien de partage. Êtes-vous sûr de vouloir continuer ?'
        cancelButtonLabel='Annuler'
        submitButtonLabel='Confirmer'
      />
    </>
  )
}
