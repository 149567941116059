import { Box } from '@mui/material'
import React, { useContext } from 'react'
import { AppNavBarContext, NavbarTypeEnum } from '../../core/context/nav-bar/AppNavBarContext'
import './app-navbar-v2.scss'
import { DefaultNavbar } from './DefaultNavbar'
import { HeaderWithoutStep } from './HeaderWithoutStep'
import { StepHeaderNavbar } from './StepHeaderNavbar'

export function AppNavbarPage(): React.JSX.Element {
  const { typeNavBar } = useContext(AppNavBarContext)

  function getNavBar(): React.JSX.Element {
    switch (typeNavBar) {
      case NavbarTypeEnum.DEFAULT:
        return <DefaultNavbar />
      case NavbarTypeEnum.STEP_HEADER:
        return <StepHeaderNavbar />
      case NavbarTypeEnum.HEAD_WITHOUT_STEP:
        return <HeaderWithoutStep />
      default:
        return <Box />
    }
  }
  return getNavBar()
}
