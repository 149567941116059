import { DialogContentText, Link } from '@mui/material'
import { pagesUrl } from '../core/appConstants'
import { MenuItemType } from '../core/type/MenuItemType'

export const userMenu: MenuItemType[] = [
  {
    id: 'projects',
    label: 'Mes projets',
    link: pagesUrl.PROJECTS_PAGE,
    isForUltimateUser: false,
    isForMegaUser: true,
    isForSuperUser: true,
    isForNormalUser: true,
    isForTestUser: true,
    tooltip: "C'est ici que vous accédez à vos projets BIM et RSEE.",
    isActive: true,
    hasDialog: (organization) => !!organization && !organization?.beemMasterOption,
    dialogTitle: "Vous n'êtes pas encore un 'BEEM Master'",
    dialogContent: (
      <>
        <DialogContentText>
          Le module BEEM Shot permet d'estimer l'impact carbone à partir d'une maquette BIM et de l'utiliser comme base de
          calcul.
        </DialogContentText>
        <DialogContentText sx={{ pt: 2 }}>Pour un essai gratuit, contactez nous:</DialogContentText>
        <DialogContentText sx={{ textAlign: 'center', pt: 3 }}>
          <Link>contact@timetobeem.com</Link>
        </DialogContentText>
      </>
    ),
  },
  {
    id: 'globalDashboard',
    label: 'Pilotage général',
    link: pagesUrl.BEEM_PILOT_HOME,
    isForUltimateUser: false,
    isForMegaUser: true,
    isForSuperUser: false,
    isForNormalUser: false,
    isForTestUser: false,
    tooltip: "C'est ici que vous accédez au pilotage de votre organisation.",
    isActive: true,
    chipColor: '#47B27C',
    hasDialog: (organization) => !organization?.beemPilotOption,
    dialogTitle: "Vous n'êtes pas encore un 'BEEM Pilot'",
    dialogContent: (
      <>
        <DialogContentText>Le module Beem Pilot génère des tableaux de bord à partir d'un RSEE.</DialogContentText>
        <DialogContentText>Il permet de piloter l'impact global de tous vos projets.</DialogContentText>
        <DialogContentText sx={{ pt: 2 }}>Pour un essai gratuit, contactez nous:</DialogContentText>
        <DialogContentText sx={{ textAlign: 'center', pt: 3 }}>
          <Link>contact@timetobeem.com</Link>
        </DialogContentText>
      </>
    ),
  },
  {
    id: 'materialLib',
    label: 'Ma matériauthèque',
    link: pagesUrl.MY_MATERIAL_PAGE,
    isForUltimateUser: false,
    isForMegaUser: false,
    isForSuperUser: false,
    isForNormalUser: false,
    isForTestUser: false,
    tooltip:
      "C'est ici que vous pouvez ajouter et enrichir des déclarations environnementales de produits et des équipements qui seront reliés à votre organisation.",
    isActive: false,
  },
  {
    id: 'beemShot',
    label: 'Beem Shot',
    link: pagesUrl.BEEM_SHOT_HOME,
    isForUltimateUser: false,
    isForMegaUser: true,
    isForSuperUser: true,
    isForNormalUser: true,
    isForTestUser: true,
    tooltip: "C'est ici que vous accédez au beem shot.",
    isActive: true,
    chipColor: '#47B27C',
    hasDialog: (organization) => !organization?.beemShotOption,
    dialogTitle: "Vous n'avez pas BEEM Shot",
    dialogContent: (
      <>
        <DialogContentText>
          Le module BEEM Shot permet d'estimer l'impact carbone à partir d'une maquette BIM et de l'utiliser comme base de
          calcul.
        </DialogContentText>
        <DialogContentText sx={{ pt: 2 }}>Pour un essai gratuit, contactez nous:</DialogContentText>
        <DialogContentText sx={{ textAlign: 'center', pt: 3 }}>
          <Link>contact@timetobeem.com</Link>
        </DialogContentText>
      </>
    ),
  },
]
