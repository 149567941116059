import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Box, Card, CardContent, CircularProgress, Grid, IconButton, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import CancelButton from '../../../../components/buttons/cancel-button/cancel-button'
import { ValidateButton } from '../../../../components/buttons/validate-button/validate-button'
import { SuccessContext } from '../../../../components/layout/success-snackbar'
import { DefaultTypography } from '../../../../components/typography/default-typography'
import { appConstants } from '../../../../core/appConstants'
import { BSMaterialResultContext } from '../../../../core/context/beem-shot/BSMaterialResult/BSMaterialResultContext'
import { BSVariantContext } from '../../../../core/context/beem-shot/BSVariant/BSVariantContext'
import { BSMaterialResult } from '../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult'
import IniesRecord from '../../../../core/dto/material/inies-record'
import MaterialRecord from '../../../../core/dto/material/material-record'
import { DeclarationTypeEnum } from '../../../../core/enum/declarationTypeEnum'
import { FunctionalUnitEnum } from '../../../../core/enum/functionalUnitEnum'
import { fromIniesId, toLabel } from '../../../../core/enum/unitEnum'
import { formatToFrench } from '../../../../core/services/date-service'
import { getDeclarationTypeLabel } from '../../../../core/services/declaration-type-service'

type IProps = {
  selectedRow: IniesRecord | MaterialRecord
  setSelectedRow: React.Dispatch<React.SetStateAction<IniesRecord | MaterialRecord | undefined>>
  getTypologyBackground: (typologie: DeclarationTypeEnum) => string
  getTypologyTextColor: (typologie: DeclarationTypeEnum) => string
  handleCloseMaterialCardModal: () => void
  handleClose: () => void
  selectedBsMaterialResult: BSMaterialResult | undefined
}

export function BSMaterialCardInfo({
  selectedRow,
  setSelectedRow,
  getTypologyBackground,
  handleCloseMaterialCardModal,
  handleClose,
  getTypologyTextColor,
  selectedBsMaterialResult,
}: Readonly<IProps>): React.JSX.Element {
  const { addOverridenMaterial } = useContext(BSMaterialResultContext)
  const { selectedVariant } = useContext(BSVariantContext)
  const openSuccessSnackbar: (message: string) => void = useContext(SuccessContext)

  const [isSubmitting, setIsSubmiting] = useState<boolean>(false)

  const successfullyAddMaterialLabel = 'Le materiau a bien été modifié !'

  function getUrl(): string {
    return `${appConstants.web.baseInie}?id=${selectedRow?.iniesId}`
  }

  function getFunctionalUnit(): FunctionalUnitEnum {
    return FunctionalUnitEnum[selectedRow?.functionalUnit as keyof typeof FunctionalUnitEnum]
  }

  function getAllParameters(): string {
    let chaineConcatenee = `${selectedRow.description || ''}`
    if (selectedRow instanceof MaterialRecord && selectedRow.parameters.length > 0) {
      chaineConcatenee += '<br/><br/><b> Paramètres : </b> <ul>'
      selectedRow.parameters.forEach((chaine) => {
        chaineConcatenee += ` <li><b> ${chaine.name} : </b>  ${chaine.value} ${toLabel(
          fromIniesId(chaine.paramUnitId)
        )}  </li>
      `
        return chaine
      })
      chaineConcatenee += '</ul>'
    }
    return chaineConcatenee
  }

  const functionalUnit: FunctionalUnitEnum =
    FunctionalUnitEnum[selectedRow.functionalUnit as unknown as keyof typeof FunctionalUnitEnum]

  return (
    <Card sx={{ width: 1100, background: '#ffffff', borderRadius: 4 }}>
      <CardContent>
        <Box component='form' display='flex' flexDirection='column'>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography
              component='span'
              sx={{
                fontWeight: 'bold',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              {selectedRow.fdesName}
            </Typography>

            <IconButton sx={{ marginLeft: 'auto' }} onClick={handleCloseMaterialCardModal}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>

          <Box borderRadius={4}>
            <Typography
              fontWeight={600}
              sx={{
                display: 'inline-block',
                backgroundColor: getTypologyBackground(selectedRow?.declarationType),
                color: getTypologyTextColor(selectedRow?.declarationType),
                padding: 0.8,
                borderRadius: 3,
                fontSize: 12,
              }}>
              {getDeclarationTypeLabel(selectedRow?.declarationType)}
            </Typography>
          </Box>

          <Box
            display='flex'
            flexDirection='row'
            py={1}
            alignItems='center'
            alignContent='center'
            columnGap={0.5}
            justifyItems='center'>
            <DefaultTypography label='Modifiée le ' color='#070F27' fontWeight={400} fontSize='11px' lineHeight='16.5px' />
            <DefaultTypography
              label={
                selectedRow instanceof IniesRecord
                  ? ` ${formatToFrench(selectedRow?.lastIniesUpdate)} depuis la base INIES.`
                  : ` ${formatToFrench(selectedRow?.lastModifiedDate)} par ${selectedRow?.lastModifiedUserName}.`
              }
              color='#070F27'
              fontWeight={500}
              fontSize='11px'
              lineHeight='16.5px'
            />
          </Box>

          <Grid container flexGrow={1} display='flex' justifyContent='space-between' alignItems='center' width='100%' py={2}>
            <Grid
              item
              xs={2.8}
              display='flex'
              flexDirection='column'
              sx={{ border: 2, borderColor: '#F5F8F8', borderRadius: 2, p: 1, backgroundColor: '#F5F5F5' }}>
              <DefaultTypography
                label='Total impact selon RE2020'
                fontSize='11px'
                lineHeight='26.5px'
                fontWeight={400}
                color='#8398A6'
              />
              <DefaultTypography
                label={`${(selectedRow?.re2020CarbonImpact ?? 0).toFixed(2)} kgeqCO2/${getFunctionalUnit()}`}
                fontSize='14px'
                lineHeight='24px'
                fontWeight={500}
              />
            </Grid>

            <Grid
              item
              xs={2.8}
              display='flex'
              flexDirection='column'
              sx={{ border: 2, borderColor: '#F5F8F8', borderRadius: 2, p: 1, backgroundColor: '#F5F5F5' }}>
              <DefaultTypography
                label='Total du cycle de vie'
                fontSize='11px'
                lineHeight='26.5px'
                fontWeight={400}
                color='#8398A6'
              />
              <DefaultTypography
                label={`${selectedRow.totalLifeCycleCarbonImpact?.toFixed(1)} kgeqCO2/${getFunctionalUnit()}`}
                fontSize='14px'
                lineHeight='24px'
                fontWeight={500}
              />
            </Grid>

            <Grid
              item
              xs={2.8}
              display='flex'
              flexDirection='column'
              sx={{ border: 2, borderColor: '#F5F8F8', borderRadius: 2, p: 1, backgroundColor: '#F5F5F5' }}>
              <DefaultTypography
                label='  Unité fonctionnelle'
                fontSize='11px'
                lineHeight='26.5px'
                fontWeight={400}
                color='#8398A6'
              />
              <DefaultTypography label={functionalUnit} fontSize='14px' lineHeight='24px' fontWeight={500} />
            </Grid>

            <Grid
              item
              xs={2.8}
              display='flex'
              flexDirection='column'
              sx={{ border: 2, borderColor: '#F5F8F8', borderRadius: 2, p: 1, backgroundColor: '#F5F5F5' }}>
              <DefaultTypography label='Durée de vie' fontSize='11px' lineHeight='26.5px' fontWeight={400} color='#8398A6' />
              <DefaultTypography
                label={selectedRow.referenceLifeTime.toString()}
                fontSize='14px'
                lineHeight='24px'
                fontWeight={500}
              />
            </Grid>
          </Grid>

          <Grid container flexGrow={1} display='flex' justifyContent='space-between' alignItems='center' width='100%'>
            <Grid
              item
              xs={12}
              display='flex'
              flexDirection='column'
              sx={{ border: 2, borderColor: '#F5F8F8', borderRadius: 2, p: 1, backgroundColor: '#F5F5F5' }}>
              <DefaultTypography
                label='Caractéristiques'
                fontSize='11px'
                lineHeight='26.5px'
                fontWeight={400}
                color='#8398A6'
              />
              <Typography
                variant='body1'
                dangerouslySetInnerHTML={{ __html: getAllParameters() }}
                fontSize='14px'
                lineHeight='24px'
                fontWeight={500}
              />
            </Grid>
          </Grid>
        </Box>

        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' pt={1}>
          <Typography
            component='a'
            href={getUrl()}
            target='_blank'
            rel='noopener noreferrer'
            fontSize='14px'
            fontWeight={400}
            sx={{ color: '#070F27' }}>
            Consulter la base inies
          </Typography>

          <Box display='flex' flexDirection='row'>
            <Box sx={{ px: 1 }}>
              <CancelButton action={() => handleCloseMaterialCardModal()} label='Annuler' />
            </Box>
            {isSubmitting ? (
              <CircularProgress />
            ) : (
              <ValidateButton
                action={() => {
                  if (selectedBsMaterialResult?.id && selectedVariant?.id) {
                    setIsSubmiting(true)
                    addOverridenMaterial(selectedVariant?.id, selectedBsMaterialResult?.id, selectedRow.iniesId)
                      .then(() => {
                        openSuccessSnackbar(successfullyAddMaterialLabel)
                        handleClose()
                      })
                      .finally(() => setIsSubmiting(false))
                  }
                }}
                label='Utiliser'
                isSubmiting={isSubmitting}
              />
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
