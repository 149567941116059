import { Box, Grid, TablePagination, Typography } from '@mui/material'
import React from 'react'
import IniesRecord from '../../../../core/dto/material/inies-record'
import { originMaterialRecordToLabel } from '../../../../core/dto/material/material'
import MaterialRecord from '../../../../core/dto/material/material-record'
import { DeclarationTypeEnum } from '../../../../core/enum/declarationTypeEnum'
import { getDeclarationTypeLabel } from '../../../../core/services/declaration-type-service'

type MaterialPageTableProps = {
  handleSetSelectedRow(row: IniesRecord | MaterialRecord | undefined): void
  getTypologyBackground(typologie: DeclarationTypeEnum): string
  getTypologyTextColor(typologie: DeclarationTypeEnum): string
  records: IniesRecord[] | MaterialRecord[] | []
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  rowsPerPage: number
  setRowsPerPage(newlimit: number): void
  handleSearchPaginated(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void
}

export function BSMaterialLibTable({
  handleSetSelectedRow,
  getTypologyBackground,
  getTypologyTextColor,
  records,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  handleSearchPaginated,
}: Readonly<MaterialPageTableProps>): React.JSX.Element {
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <>
      <Grid
        container
        display='flex'
        flexDirection='row'
        rowGap={1}
        justifyContent='center'
        alignContent='center'
        alignItems='center'
        width='97%'>
        <Box display='flex' alignItems='center' p={1} gap={1} width='97%'>
          <Grid item xs={3}>
            <Typography variant='subtitle2' fontSize={12} sx={{ color: '#8F8F8F' }}>
              Typologie de déclaration
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant='subtitle2' fontSize={12} sx={{ color: '#8F8F8F' }}>
              Nom du produit
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='subtitle2' fontSize={12} sx={{ color: '#8F8F8F' }}>
              Fournisseur & réference
            </Typography>
          </Grid>
        </Box>

        {records.map((record) => (
          <Box
            display='flex'
            alignItems='center'
            borderRadius={3}
            p={1.5}
            gap={1}
            key={record.id}
            boxShadow={5}
            width='97%'
            onClick={() => handleSetSelectedRow(record)}
            sx={{ ':hover': { width: '100%', cursor: 'pointer' } }}>
            <Grid item xs={3}>
              <Typography
                fontWeight={600}
                component='span'
                sx={{
                  backgroundColor: getTypologyBackground(record.declarationType),
                  color: getTypologyTextColor(record.declarationType),
                  padding: '8px',
                  borderRadius: '28px',
                  fontSize: 12,
                  whiteSpace: 'nowrap',
                }}>
                {getDeclarationTypeLabel(record.declarationType)}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant='subtitle2' fontSize={12}>
                {record.fdesName}

                {record instanceof MaterialRecord && (
                  <Typography
                    component='span'
                    sx={{
                      fontWeight: 'bold',

                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color: record instanceof MaterialRecord ? originMaterialRecordToLabel(record).color : '',
                    }}>
                    {` (${record instanceof MaterialRecord ? originMaterialRecordToLabel(record).text : ''})`}
                  </Typography>
                )}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {!(record instanceof IniesRecord) && (record?.provider || record?.productReference) && (
                <>
                  {record?.provider && <Typography>{record?.provider?.toUpperCase()}</Typography>}
                  {record?.productReference && (
                    <Typography sx={{ fontSize: 12 }}>{`${record?.productReference}`}</Typography>
                  )}
                </>
              )}
            </Grid>
          </Box>
        ))}
      </Grid>

      <TablePagination
        sx={{
          mt: 1,
          display: 'flex',
          justifyContent: 'center',
        }}
        component='div'
        count={records.length === rowsPerPage ? -1 : records.length}
        page={page}
        onPageChange={handleSearchPaginated}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50]}
        labelDisplayedRows={({ from, to }) =>
          `${from < rowsPerPage ? from : `... ${from}`}-${records.length === rowsPerPage ? ` ${to} ...` : from + to} `
        }
        labelRowsPerPage='Ligne par page:'
      />
    </>
  )
}
