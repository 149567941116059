export enum RoleEnum {
  MOA = 'MOA',
  ARCHITECT = 'ARCHITECT',
  BE = 'BE',
  CONSULTANT = 'CONSULTANT',
  ADMINISTRATOR = 'ADMINISTRATOR',
}

export const roleEnumLabels = {
  [RoleEnum.MOA]: getLabelText(RoleEnum.MOA),
  [RoleEnum.ARCHITECT]: getLabelText(RoleEnum.ARCHITECT),
  [RoleEnum.BE]: getLabelText(RoleEnum.BE),
  [RoleEnum.CONSULTANT]: getLabelText(RoleEnum.CONSULTANT),
  [RoleEnum.ADMINISTRATOR]: getLabelText(RoleEnum.ADMINISTRATOR),
}

export function getLabelText(value: RoleEnum): string {
  if (value === RoleEnum.ADMINISTRATOR) return 'ADMINISTRATEUR'
  if (value === RoleEnum.ARCHITECT) return 'ARCHITECTE'
  if (value === RoleEnum.BE) return "BUREAU D'ÉTUDES"
  if (value === RoleEnum.CONSULTANT) return 'CONSULTANT'
  if (value === RoleEnum.MOA) return 'MOA'

  return ''
}
