import { SvgIcon } from '@mui/material'
import React from 'react'

type IProps = {
  style?: any
}

export function IconBeemMaster(props: IProps): React.JSX.Element {
  const { style } = props

  return (
    <SvgIcon
      {...props}
      fillOpacity={0}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      sx={{ width: style?.width ? style?.width : '24', height: style?.height ? style?.height : '24' }}>
      {/*  SVG from https://tablericons.com/ */}
      <g id='icon-components'>
        <g id='Vector'>
          <path d='M3 12L6 15L9 12L6 9L3 12Z' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path
            d='M15 12L18 15L21 12L18 9L15 12Z'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path d='M9 6L12 9L15 6L12 3L9 6Z' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path
            d='M9 18L12 21L15 18L12 15L9 18Z'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </SvgIcon>
  )
}
