/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import React from 'react'

type IProps = {
  style?: any
}

export function IconBuildingCommunityCustom(props: IProps): React.JSX.Element {
  const { style } = props
  return (
    <SvgIcon
      {...props}
      fillOpacity={0}
      viewBox='0 0 38 38'
      xmlns='http://www.w3.org/2000/svg'
      sx={{ width: style?.width ? style?.width : '38', height: style?.height ? style?.height : '38' }}>
      {/*  SVG from https://tablericons.com/ */}
      <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M14 6V15.5L20.5 22V33.5H32.4823C33.0415 33.5 33.492 33.0415 33.4821 32.4825L33.0172 5.98246C33.0077 5.4371 32.5628 5 32.0174 5H15C14.4477 5 14 5.44772 14 6Z'
          fill='#97FFD7'
        />
        <path
          d='M20.5833 33.25V22.1667L12.6667 14.25L4.75 22.1667V33.25H12.6667M20.5833 33.25H12.6667M20.5833 33.25H33.25V6.33333C33.25 5.91341 33.0832 5.51068 32.7863 5.21375C32.4893 4.91681 32.0866 4.75 31.6667 4.75H15.8333C15.4134 4.75 15.0107 4.91681 14.7137 5.21375C14.4168 5.51068 14.25 5.91341 14.25 6.33333V15.8333M12.6667 33.25V26.9167'
          stroke='#0BE2A0'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.6667 14.25L20.5833 22.1667V33.25H12.6667V26.9167M12.6667 33.25H4.75V22.1667L12.6667 14.25'
          fill='#0BE2A0'
        />
        <path
          d='M12.6667 33.25H20.5833V22.1667L12.6667 14.25L4.75 22.1667V33.25H12.6667ZM12.6667 33.25V26.9167'
          stroke='#009069'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M20.5833 11.0835V11.0985' stroke='#0BE2A0' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M26.9167 11.0835V11.0985' stroke='#0BE2A0' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M26.9167 17.4165V17.4315' stroke='#0BE2A0' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M26.9167 23.75V23.765' stroke='#0BE2A0' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  )
}
