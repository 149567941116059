import { Box, debounce, Grid, Modal } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ImportFicheConfiguree from '../../../../components/material-lib/material-page-modal/import-fiche-configuree'
import MaterialPageSearch from '../../../../components/material-lib/material-page-modal/material-page-modal-search'
import { BSMaterialResult } from '../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult'
import IniesRecord from '../../../../core/dto/material/inies-record'
import MaterialRecord from '../../../../core/dto/material/material-record'
import { DeclarationFilter } from '../../../../core/enum/declarationFilter'
import { DeclarationTypeEnum } from '../../../../core/enum/declarationTypeEnum'
import { useFetchMaterialDeclarationFilter } from '../../../../core/hooks/material-lib/use-fetch-material-lib-declaration-filter'
import { useIniesRecordsFiltered } from '../../../../core/hooks/material-lib/use-inies-records-filtered'
import { BSMaterialCardModal } from './BSMaterialCardModal'
import { BSMaterialLibFilter } from './BSMaterialLibFilter'
import { BSMaterialLibTable } from './BSMaterialLibTable'
import './material-page-modal.css'

type MaterialPageModalAddIniesProps = {
  handleClose: () => void
  selectedBsMaterialResult: BSMaterialResult | undefined
}

const style = {
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 7,
  pt: 3,
  pb: 1,
  display: 'block',
  overflowX: 'hidden',
}

export function BSMaterialLib({
  handleClose,
  selectedBsMaterialResult,
}: Readonly<MaterialPageModalAddIniesProps>): React.JSX.Element {
  const [selectedLvl0, setSelectedLvl0] = useState<DeclarationFilter | undefined>(undefined)

  const { iniesData: nomenclatureFilterData } = useFetchMaterialDeclarationFilter()

  const {
    search: searchInies,
    iniesRecords,
    reset: resetInies,
    limit: limitInies,
    setLimit: setLimitInies,
  } = useIniesRecordsFiltered()

  const [selectedCategory, setSelectedCategory] = useState<string>()
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>()
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState<string>()
  const [selectedMaterial, setSelectedMaterial] = useState<string>()
  const [selectedOrganism, setSelectedOrganism] = useState<string>()
  const [selectedTypologieDeclaration, setSelectedTypologieDeclaration] = useState<string>()
  const [selectedRow, setSelectedRow] = useState<IniesRecord | MaterialRecord | undefined>(undefined)

  const [searchState, setSearchState] = useState<string | undefined>(undefined)

  const [openCardModal, setOpenCardModal] = useState(false)
  const [openFicheeConfigureeModal, setOpenFicheeConfigureeModal] = useState(false)

  const [lastModalMaterial, setLastModalMaterial] = useState<string | undefined>(undefined)
  const [lastPrevModalMaterial, setLastPrevModalMaterial] = useState<string[]>([])

  const [modalPageMaterial, setModalPageMaterial] = useState(0)

  const getRowPerPage = useCallback((): number => limitInies, [limitInies])

  const setRowsPerPage = useCallback(
    (newLimit: number): void => {
      setLimitInies(newLimit)
    },
    [setLimitInies]
  )

  const handleSetSelectedRow = useCallback((row: IniesRecord | MaterialRecord | undefined) => {
    setSelectedRow(row)
    setOpenCardModal(true)
  }, [])

  const searchIniesNomenclatures = useCallback(
    (newSelectedLvl0: DeclarationFilter | undefined, containedInName?: string, lastFdesName?: string) =>
      searchInies({
        filter: {
          responsibleOrganism: selectedOrganism ?? undefined,
          declarationType: selectedTypologieDeclaration ?? undefined,
          classificationLvl0: newSelectedLvl0,
          classificationLvl1: Number(selectedCategory ?? undefined),
          classificationLvl2: Number(selectedSubCategory ?? undefined),
          classificationLvl3: Number(selectedMaterial ?? undefined),
          classificationLvl4: Number(selectedSubSubCategory ?? undefined),
          containedInName,
        },
        lastFdesName,
      }).then((record: IniesRecord[]) => {
        if (record?.length) {
          setLastModalMaterial(record[record.length - 1].fdesName)
        }
      }),
    [
      searchInies,
      selectedOrganism,
      selectedTypologieDeclaration,
      selectedCategory,
      selectedSubCategory,
      selectedMaterial,
      selectedSubSubCategory,
    ]
  )

  useEffect(() => {
    // It's these use effect that triggers a search when changing a filter or rowsPerPage
    resetInies()
    setSearchState(undefined)

    searchIniesNomenclatures(selectedLvl0)
  }, [selectedLvl0, resetInies, searchIniesNomenclatures])

  const handleSearchModalIniesPaginated = useCallback(
    (newPage: number) => {
      if (newPage > modalPageMaterial) {
        searchIniesNomenclatures(selectedLvl0, searchState, lastModalMaterial).then(() => {
          if (lastModalMaterial) setLastPrevModalMaterial([...lastPrevModalMaterial, lastModalMaterial])
        })
      } else if (newPage <= modalPageMaterial) {
        lastPrevModalMaterial.pop()
        const newLastPrevInie = [...lastPrevModalMaterial]
        const lastFdesName = newPage === 0 ? undefined : newLastPrevInie[newLastPrevInie.length - 1]
        searchIniesNomenclatures(selectedLvl0, searchState, lastFdesName)
      }
    },
    [selectedLvl0, lastModalMaterial, lastPrevModalMaterial, modalPageMaterial, searchIniesNomenclatures, searchState]
  )

  const handleSearchModalPaginated = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      resetInies()

      handleSearchModalIniesPaginated(newPage)

      setModalPageMaterial(newPage)
    },
    [resetInies, handleSearchModalIniesPaginated]
  )

  const handleSearchDelayed = useMemo(
    () =>
      debounce((e: string) => {
        setSearchState((prev) => e || prev)
        setModalPageMaterial(0)

        searchIniesNomenclatures(selectedLvl0, e)
      }, 700),
    [selectedLvl0, searchIniesNomenclatures]
  )

  const handleChangeSelectedLvl0 = (selected: DeclarationFilter | undefined): void => {
    setSelectedLvl0(selected)
    resetInies()
    searchIniesNomenclatures(selected)
  }

  const getTypologyBackground = useCallback((typologie: DeclarationTypeEnum): string => {
    switch (typologie) {
      case DeclarationTypeEnum.DONNEE_PAR_DEFAUT:
        return '#E6F0FB'
      case DeclarationTypeEnum.COLLECTIVE:
        return '#faad75'
      case DeclarationTypeEnum.FORFAITAIRE:
        return '#b5f7f4'
      case DeclarationTypeEnum.INDIVIDUELLE:
        return '#facebe'
      case DeclarationTypeEnum.FICHE_CONFIGUREE:
        return '#92D050'
      default:
        return '#4169E1'
    }
  }, [])

  const getTypologyTextColor = useCallback((typologie: DeclarationTypeEnum): string => {
    switch (typologie) {
      case DeclarationTypeEnum.DONNEE_PAR_DEFAUT:
        return '#4965AC'
      case DeclarationTypeEnum.COLLECTIVE:
        return '#D2691E'
      case DeclarationTypeEnum.FORFAITAIRE:
        return '#20B2AA'
      case DeclarationTypeEnum.INDIVIDUELLE:
        return '#FF7F50'
      case DeclarationTypeEnum.FICHE_CONFIGUREE:
        return '#92D050'
      default:
        return '#4169E1'
    }
  }, [])

  function reset(): void {
    resetInies()
  }

  function getRecordsForModal(): IniesRecord[] | MaterialRecord[] {
    return iniesRecords
  }

  function importFicheConfiguree(): void {
    setOpenFicheeConfigureeModal(true)
  }

  function handleCloseImportFicheConfigureeModal(): void {
    setOpenFicheeConfigureeModal(false)
  }

  function handleCloseMaterialCardModal(): void {
    setOpenCardModal(false)
  }

  return (
    <>
      <Grid container display='flex' gap={2}>
        {/* THIS WILL BE USED LATER */}
        {/* <Grid display='flex' justifyContent='center' item xs={12}>
          <Button
            variant='contained'
            onClick={() => importFicheConfiguree()}
            sx={{ borderRadius: 3, color: '#009069', backgroundColor: '#C9FFE8' }}>
            importer une fiche configurée (.XML)
          </Button>
        </Grid> */}

        <Grid item xs={12}>
          <BSMaterialLibFilter
            selectedLvl0={selectedLvl0}
            setSelectedLvl0={handleChangeSelectedLvl0}
            nomenclatureFilterData={nomenclatureFilterData}
            selectedLvl1={selectedCategory}
            setSelectedLvl1={setSelectedCategory}
            selectedLvl2={selectedSubCategory}
            setSelectedLvl2={setSelectedSubCategory}
            selectedLvl3={selectedSubSubCategory}
            setSelectedLvl3={setSelectedSubSubCategory}
            selectedLvl4={selectedMaterial}
            setSelectedLvl4={setSelectedMaterial}
            selectedTypologieDeclaration={selectedTypologieDeclaration}
            setSelectedSelectedTypologieDeclaration={setSelectedTypologieDeclaration}
          />
        </Grid>
        <Grid item xs={8}>
          <MaterialPageSearch handleSearchDelayed={handleSearchDelayed} search={searchState} setSearch={setSearchState} />
        </Grid>
        <Grid item xs={12}>
          <BSMaterialLibTable
            handleSetSelectedRow={handleSetSelectedRow}
            getTypologyBackground={getTypologyBackground}
            getTypologyTextColor={getTypologyTextColor}
            records={getRecordsForModal()}
            page={modalPageMaterial}
            handleSearchPaginated={handleSearchModalPaginated}
            setPage={setModalPageMaterial}
            rowsPerPage={getRowPerPage()}
            setRowsPerPage={setRowsPerPage}
          />
          <BSMaterialCardModal
            openCard={openCardModal}
            selectedRow={selectedRow}
            reset={reset}
            setSelectedRow={setSelectedRow}
            getTypologyBackground={getTypologyBackground}
            getTypologyTextColor={getTypologyTextColor}
            handleCloseMaterialCardModal={handleCloseMaterialCardModal}
            selectedBsMaterialResult={selectedBsMaterialResult}
            handleClose={handleClose}
          />
        </Grid>
      </Grid>

      <Modal
        sx={{
          margin: '40px 0',
        }}
        open={openFicheeConfigureeModal}
        onClose={() => handleCloseImportFicheConfigureeModal}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box className='modal-import-inies' sx={style}>
          <ImportFicheConfiguree handleClose={handleCloseImportFicheConfigureeModal} />
        </Box>
      </Modal>
    </>
  )
}
