import CheckIcon from '@mui/icons-material/Check'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Grid, Tooltip, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CustomBottomNavigation } from '../../../components/buttons/navigate-button/CustomBottomNavigation'
import { IconRseeCriticalImport } from '../../../components/custom-icons/icon-rsee-critical-import'
import { IconRseeSuccessImport } from '../../../components/custom-icons/icon-rsee-sucess-import'
import { IconRseeWarningImport } from '../../../components/custom-icons/icon-rsee-warning-import'
import { pagesUrl } from '../../../core/appConstants'
import { RseeDocumentContext } from '../../../core/context/beem-pilot/rsee/rsee-document-context'
import { AppNavBarContext, NavbarTypeEnum } from '../../../core/context/nav-bar/AppNavBarContext'
import { ErrorLevelEnum } from '../../../core/enum/rsee/errorLevelEnum'
import { ImportStateEnum } from '../../../core/enum/rsee/importStateEnum'
import { RseeSummaryTypeEnum } from '../../../core/enum/rsee/rseeSummaryTypeEnum'
import { useQuery } from '../../../core/hooks/miscellaneous/use-query'
import { resolveUrl } from '../../../core/services/http-service'

export function RseeSummaryDocumentImport(): React.JSX.Element {
  const { rseeProjectId, rseeDocumentId } = useParams()
  const navigate = useNavigate()
  const query = useQuery()

  const { setTypeNavBar, setPreviousUrl, setStepHeader } = useContext(AppNavBarContext)

  const { rseeDocument, getRseeSummaryList, summaryImport, importState, dataCompState, rsetState, rsenvState } =
    useContext(RseeDocumentContext)

  const [fromPage, setFromPage] = useState<RseeSummaryTypeEnum>(RseeSummaryTypeEnum.RSEE_DASHBOARD)

  const [expandDataComp, setExpandDataComp] = useState<boolean>(false)
  const [expandRset, setExpandRset] = useState<boolean>(false)
  const [expandRsenv, setExpandRsenv] = useState<boolean>(false)

  useEffect(() => {
    if (dataCompState === ImportStateEnum.CRITICAL) {
      setExpandDataComp(true)
    }

    if (rsetState === ImportStateEnum.CRITICAL) {
      setExpandRset(true)
    }

    if (rsenvState === ImportStateEnum.CRITICAL) {
      setExpandRsenv(true)
    }
  }, [dataCompState, rsenvState, rsetState])

  useEffect(() => {
    const typeString = query.get('from')
    let from: RseeSummaryTypeEnum
    switch (typeString) {
      case RseeSummaryTypeEnum.RSEE_DASHBOARD:
        from = RseeSummaryTypeEnum.RSEE_DASHBOARD
        break
      case RseeSummaryTypeEnum.RSEE_DOWNLOADING:
        from = RseeSummaryTypeEnum.RSEE_DOWNLOADING
        break
      default:
        from = RseeSummaryTypeEnum.RSEE_DASHBOARD
    }
    setFromPage(from)
  }, [query])

  useEffect(() => {
    if (fromPage === RseeSummaryTypeEnum.RSEE_DASHBOARD) {
      setPreviousUrl(resolveUrl(pagesUrl.BEEM_PILOT_DOCUMENT_DASHBOARD, [rseeProjectId, rseeDocumentId]))
    } else if (fromPage === RseeSummaryTypeEnum.RSEE_DOWNLOADING) {
      setPreviousUrl(resolveUrl(pagesUrl.BEEM_PILOT_UPLOAD_PAGE, [rseeProjectId, rseeDocumentId]))
    }
    setTypeNavBar(NavbarTypeEnum.STEP_HEADER)
    setStepHeader(1)
  }, [fromPage, rseeDocumentId, rseeProjectId, setPreviousUrl, setStepHeader, setTypeNavBar])

  useEffect(() => {
    getRseeSummaryList()
  }, [])

  function getMainMessage(): React.ReactNode {
    if (importState === ImportStateEnum.SUCCESS) {
      return (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          border={1}
          borderRadius={5}
          borderColor='#81C784'
          py={2}
          gap={1}
          width='100%'
          sx={{ background: '#E8F5E9' }}>
          <CheckIcon color='success' />
          <Typography>Le fichier RSEE à été importé avec succès</Typography>
        </Box>
      )
    } else if (importState === ImportStateEnum.WARNING) {
      return (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          border={1}
          borderRadius={5}
          borderColor='#EF6C00'
          py={2}
          gap={1}
          width='100%'
          sx={{ background: '#FFF4E5' }}>
          <WarningAmberIcon color='warning' />
          <Typography>Le fichier RSEE importé comporte des points d‘attention</Typography>
        </Box>
      )
    } else if (importState === ImportStateEnum.CRITICAL) {
      return (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          border={1}
          borderRadius={5}
          borderColor='#EF6C00'
          py={2}
          gap={1}
          width='100%'
          sx={{ background: '#FEEBEE' }}>
          <IconRseeCriticalImport color='warning' />
          <Typography>Le fichier RSEE importé comporte des points critiques</Typography>
        </Box>
      )
    } else {
      return undefined
    }
  }

  function getErrolLevelIcon(errorLevel: ImportStateEnum): React.ReactNode {
    if (errorLevel === ImportStateEnum.SUCCESS) {
      return <IconRseeSuccessImport />
    } else if (errorLevel === ImportStateEnum.WARNING) {
      return <IconRseeWarningImport sx={{ color: 'FFF4E5' }} />
    } else if (errorLevel === ImportStateEnum.CRITICAL) {
      return <IconRseeCriticalImport />
    } else {
      return undefined
    }
  }

  function getErrorMessageColor(errorLevel: ErrorLevelEnum): React.ReactNode {
    if (errorLevel === ErrorLevelEnum.INFO) {
      return <Chip size='small' color='primary' sx={{ size: 0.2, mr: 1 }} />
    } else if (errorLevel === ErrorLevelEnum.WARNING) {
      return <Chip size='small' color='warning' sx={{ size: 0.2, mr: 1 }} />
    } else if (errorLevel === ErrorLevelEnum.CRITICAL) {
      return <Chip size='small' color='error' sx={{ size: 0.2, mr: 1 }} />
    } else {
      return undefined
    }
  }

  function getErrorLevelChip(errorLevel: ImportStateEnum): React.ReactNode {
    if (errorLevel === ImportStateEnum.SUCCESS) {
      return (
        <Chip
          label={
            <Typography variant='body2' fontWeight={600}>
              Importé avec succès
            </Typography>
          }
          color='primary'
          size='medium'
          variant='outlined'
          sx={{ background: '#E5FFF4', border: 0 }}
        />
      )
    } else if (errorLevel === ImportStateEnum.WARNING) {
      return (
        <Chip
          label={
            <Typography variant='body2' fontWeight={600}>
              Points d’attention
            </Typography>
          }
          color='warning'
          size='medium'
          variant='outlined'
          sx={{ background: '#FFF4E5', border: 0 }}
        />
      )
    } else if (errorLevel === ImportStateEnum.CRITICAL) {
      return (
        <Chip
          label={
            <Typography variant='body2' fontWeight={600}>
              Point critique
            </Typography>
          }
          color='error'
          size='medium'
          variant='outlined'
          sx={{ background: '#FEEBEE', border: 0 }}
        />
      )
    } else {
      return undefined
    }
  }

  return (
    <Box
      display='flex'
      flexGrow={1}
      flexShrink={1}
      justifyContent='center'
      flexDirection='column'
      alignContent='center'
      alignItems='center'
      // height='76vh'
      gap={4}
      pt={8}>
      <Typography variant='h5' fontWeight={600}>
        Vérifier le diagnostic de l’import du fichier RSEE
      </Typography>

      <Grid container>
        <Grid item xs={1} />
        <Grid container item xs={10} display='flex' flexDirection='column' alignItems='center' gap={2}>
          <Tooltip title='Pensez à vérifier son nom' placement='right' arrow>
            <Box width='60%' borderRadius={2} p={1} sx={{ background: 'white' }}>
              <Typography
                fontSize={12}
                sx={{
                  color: '#8F8F8F',
                }}>
                Nom du fichier RSEE *
              </Typography>
              <Typography variant='body2' fontWeight={500}>
                {rseeDocument?.documentName}
              </Typography>
            </Box>
          </Tooltip>

          {getMainMessage()}

          <Accordion
            expanded={expandDataComp}
            onChange={() => setExpandDataComp(!expandDataComp)}
            disableGutters
            square
            sx={{ width: '100%', p: 1, borderRadius: 6 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
                <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
                  {getErrolLevelIcon(dataCompState)}

                  <Typography variant='subtitle1'>Informations générales</Typography>
                </Box>
                <Box mr={1}>{getErrorLevelChip(dataCompState)}</Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {summaryImport.datacomp.map((x) => (
                <Typography key={x.id} variant='body1' fontSize={14} p={1}>
                  {getErrorMessageColor(x.errorLevelEnum)}
                  {x.errorMessage}
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expandRset}
            onChange={() => setExpandRset(!expandRset)}
            disableGutters
            square
            sx={{ width: '100%', p: 1, borderRadius: 6 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
                <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
                  {getErrolLevelIcon(rsetState)}

                  <Typography variant='subtitle1'>Partie énergie (thermique)</Typography>
                </Box>
                <Box mr={1}>{getErrorLevelChip(rsetState)}</Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {summaryImport.rset.map((x) => (
                <Typography key={x.id} variant='body1' fontSize={14} p={1}>
                  {getErrorMessageColor(x.errorLevelEnum)}
                  {x.errorMessage}
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expandRsenv}
            onChange={() => setExpandRsenv(!expandRsenv)}
            disableGutters
            square
            sx={{ width: '100%', p: 1, borderRadius: 6 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
                <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
                  {getErrolLevelIcon(rsenvState)}

                  <Typography variant='subtitle1'>Partie environnement (carbone)</Typography>
                </Box>
                <Box mr={1}>{getErrorLevelChip(rsenvState)}</Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {summaryImport.rsenv.map((x) => (
                <Typography key={x.id} variant='body1' fontSize={14} p={1}>
                  {getErrorMessageColor(x.errorLevelEnum)}
                  {x.errorMessage}
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={1} />
      </Grid>

      <CustomBottomNavigation
        actionLabel='Valider'
        actionButton={() => {
          if (fromPage === RseeSummaryTypeEnum.RSEE_DOWNLOADING) {
            navigate(resolveUrl(pagesUrl.BEEM_PILOT_PROJECT, [rseeDocument?.projectId]))
          } else if (fromPage === RseeSummaryTypeEnum.RSEE_DASHBOARD) {
            navigate(resolveUrl(pagesUrl.BEEM_PILOT_DOCUMENT_DASHBOARD, [rseeProjectId, rseeDocumentId]))
          }
        }}
      />
    </Box>
  )
}
