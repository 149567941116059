import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export function IconRseeSuccessImport(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      {/*  SVG from https://tablericons.com/ */}
      <svg width='24' height='24' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z'
          fill='#55FFC6'
        />
        <path
          d='M10.167 15.9997L14.3337 20.1663L22.667 11.833'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  )
}
