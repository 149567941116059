import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { FormEvent, useContext, useState } from 'react'
import CancelButton from '../../../components/buttons/cancel-button/cancel-button'
import { SubmitButton } from '../../../components/buttons/SubmitButton/SubmitButton'
import { ConfirmationDialog } from '../../../components/dialog/confirmation-dialog'
import { NumberInput } from '../../../components/inputs/number-input/number-input'
import { DefaultTypography } from '../../../components/typography/default-typography'
import { TextInputTypography } from '../../../components/typography/textInputTypography'
import { BSItemContext } from '../../../core/context/beem-shot/BSItems/BSItemContext'
import { BSMaterialResultContext } from '../../../core/context/beem-shot/BSMaterialResult/BSMaterialResultContext'
import { BSVariantContext } from '../../../core/context/beem-shot/BSVariant/BSVariantContext'
import { BSItem } from '../../../core/dto/beem-shot/BSItem/BSItem'
import { BSMaterialResult } from '../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult'
import { codeToKey } from '../../../core/services/code-service'
import { stringToNumber } from '../../../core/services/helper-service'
import { useAccordion } from '../BSCalculationPage/BSCalculationForm/components/AdvancedQuestionnaire/useAccordion'
import { CodeDisplayer } from './BSBimModelExtractedCode/CodeDisplayer'
import { BSMaterialLib } from './BsMaterialLib/BSMaterialLib'

export function BSCalculDetailPage(): React.JSX.Element {
  const { bsMaterialResults, updateMaterialQuantities, resetMaterial } = useContext(BSMaterialResultContext)
  const { bsItems } = useContext(BSItemContext)
  const { selectedVariant } = useContext(BSVariantContext)

  const [open, setOpen] = useState<boolean>(false)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false)
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false)
  const [openMaterialDetail, setOpenMaterialDetail] = useState<boolean>(false)

  const [selectedBsMaterialResult, setSelectedBsMaterialResult] = useState<BSMaterialResult>()

  const { expanded: expandedBsItem, handleExpanded } = useAccordion()

  function sortBsItem(a: BSItem, b: BSItem): number {
    if (a.type.label < b.type.label) {
      return -1
    } else if (a.type.label > b.type.label) {
      return 1
    } else {
      return stringToNumber(a.codeExtrait?.occurence) - stringToNumber(b.codeExtrait?.occurence)
    }
  }

  function handleClose(): void {
    setOpen(false)
    setOpenConfirmationDialog(false)
  }

  function updateQuantities(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    if (selectedBsMaterialResult?.id && selectedVariant?.id) {
      setIsSubmiting(true)
      updateMaterialQuantities(
        selectedVariant?.id,
        selectedBsMaterialResult?.id,
        selectedBsMaterialResult?.quantity.toFixed(3)
      )
        .then(() => setOpenMaterialDetail(false))
        .finally(() => setIsSubmiting(false))
    }
  }

  return (
    <>
      <Box display='flex' flexDirection='column' gap={1} pt={2}>
        <Typography variant='h6'>Personnalisez vos calculs bla blablabla</Typography>

        {bsItems.sort(sortBsItem).map((bsItem) => (
          <Accordion
            id={bsItem.id}
            key={bsItem.id}
            expanded={expandedBsItem === bsItem.id}
            onChange={(e, toOpen) => {
              handleExpanded(bsItem.id)
            }}
            elevation={1}
            square
            disableGutters
            TransitionProps={{ unmountOnExit: true }}
            sx={{
              position: 'inherit',
              borderRadius: 6,
              minWidth: '100%',
            }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                id={bsItem.codeExtrait ? codeToKey(bsItem.codeExtrait) : ''}
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                <CodeDisplayer
                  codeTitle={bsItem.type.label}
                  codeDescription={bsItem.codeExtrait?.descriptionFromBimModel}
                  codeExtrait={bsItem.codeExtrait}
                  codeColor={bsItem.codeExtrait?.color ?? 'red'}
                  showBorder={false}
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                display='flex'
                flexDirection='row'
                rowGap={1}
                justifyContent='center'
                alignContent='center'
                alignItems='center'
                width='97%'>
                <Box display='flex' alignItems='center' p={1} gap={1} width='97%'>
                  <Grid item xs={1}>
                    <Typography variant='subtitle2' fontSize={12} sx={{ color: '#8F8F8F' }}>
                      Identifiant
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant='subtitle2' fontSize={12} sx={{ color: '#8F8F8F' }}>
                      Nom du produit
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant='subtitle2' fontSize={12} sx={{ color: '#8F8F8F' }}>
                      Quantité
                    </Typography>
                  </Grid>
                  <Grid item xs={1} />
                </Box>

                {bsMaterialResults
                  .filter((x: BSMaterialResult) => x.itemType === bsItem.type.name)
                  .map((material: BSMaterialResult) => (
                    <Box
                      display='flex'
                      alignItems='center'
                      borderRadius={3}
                      p={1}
                      gap={1}
                      key={material.id}
                      boxShadow={5}
                      width='97%'
                      onClick={() => {
                        setOpenMaterialDetail(true)
                        setSelectedBsMaterialResult(material)
                      }}
                      sx={{ ':hover': { width: '100%', cursor: 'pointer' } }}>
                      {isSubmiting && material.id === selectedBsMaterialResult?.id ? (
                        <CircularProgress />
                      ) : (
                        <>
                          <Grid item xs={1}>
                            <Typography variant='subtitle2' fontSize={12}>
                              {material.ficheId}
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant='subtitle2' fontSize={12}>
                              {material.nomProduit}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography variant='subtitle2' fontSize={12}>
                              {`${material.quantity.toFixed(3)} ${material.ficheUniteLabel}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={1} display='flex' flexDirection='row' justifyContent='center' gap={1}>
                            <Tooltip title='Modifier les informations'>
                              <ModeEditOutlineIcon
                                onClick={(event) => {
                                  event.stopPropagation()
                                  setSelectedBsMaterialResult(material)
                                  setOpen(true)
                                }}
                              />
                            </Tooltip>
                            {material.resettable && (
                              <Tooltip title='Réinitialiser la valeur par défaut'>
                                <RotateLeftIcon
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    setSelectedBsMaterialResult(material)
                                    setOpenConfirmationDialog(true)
                                  }}
                                />
                              </Tooltip>
                            )}
                          </Grid>
                        </>
                      )}
                    </Box>
                  ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      <Dialog open={openMaterialDetail} maxWidth='sm' fullWidth onClose={() => setOpenMaterialDetail(false)}>
        <Box component='form' id='update-quantities' onSubmit={updateQuantities} sx={{ m: 1 }}>
          <DialogTitle>Informations du matériau</DialogTitle>
          <DialogContent>
            <Grid display='flex' container rowGap={1}>
              <Grid
                item
                xs={6}
                display='flex'
                flexDirection='column'
                sx={{ border: 2, borderColor: '#DDE6E8', borderRadius: 2, p: 1 }}>
                <TextInputTypography label='Identifiant ' />
                <DefaultTypography
                  label={selectedBsMaterialResult?.ficheId ?? ''}
                  fontSize='12px'
                  lineHeight='18px'
                  fontWeight={400}
                />
              </Grid>

              <Grid
                item
                xs={6}
                display='flex'
                flexDirection='column'
                sx={{ border: 2, borderColor: '#DDE6E8', borderRadius: 2, p: 1 }}>
                <TextInputTypography label='Impact re2020 / surface' />
                <DefaultTypography
                  label={`${selectedBsMaterialResult?.re2020CarbonImpactPerSurface.toFixed(3) ?? '0'} kgeqCO2/m²`}
                  fontSize='12px'
                  lineHeight='18px'
                  fontWeight={400}
                />
              </Grid>

              <Grid
                item
                xs={12}
                display='flex'
                flexDirection='column'
                sx={{ border: 2, borderColor: '#DDE6E8', borderRadius: 2, p: 1 }}>
                <TextInputTypography label='Nom du produit ' />
                <DefaultTypography
                  label={selectedBsMaterialResult?.nomProduit ?? ''}
                  fontSize='12px'
                  lineHeight='18px'
                  fontWeight={400}
                />
              </Grid>

              <Grid item xs={12} display='flex' flexDirection='column'>
                <NumberInput
                  id='Quantité'
                  label='Quantité'
                  value={selectedBsMaterialResult?.quantity ?? 0}
                  decimalScale={3}
                  variant='outlined'
                  handleChange={(value) => {
                    if (selectedBsMaterialResult)
                      setSelectedBsMaterialResult({ ...selectedBsMaterialResult, quantity: stringToNumber(value) })
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                display='flex'
                flexDirection='column'
                sx={{ border: 2, borderColor: '#DDE6E8', borderRadius: 2, p: 1 }}>
                <TextInputTypography label='Unité fiche' />
                <DefaultTypography
                  label={selectedBsMaterialResult?.ficheUniteLabel ?? ''}
                  fontSize='12px'
                  lineHeight='18px'
                  fontWeight={400}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box sx={{ px: 1 }}>
              <CancelButton action={() => setOpenMaterialDetail(false)} label='Annuler' />
            </Box>
            <SubmitButton label='Mettre à jour' formId='update-quantities' isSubmiting={isSubmiting} />
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog
        maxWidth='lg'
        fullWidth
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            minHeight: 400,
          },
        }}>
        <DialogTitle display='flex' justifyContent='center'>
          <Typography
            sx={{
              fontFamily: 'Poppins, sans-serif',
              fontSize: '24px',
              fontWeight: 600,
              lineHeight: '36px',
              textAlign: 'center',
            }}>
            Importer une déclaration
          </Typography>
        </DialogTitle>
        <DialogContent>
          <BSMaterialLib handleClose={handleClose} selectedBsMaterialResult={selectedBsMaterialResult} />
        </DialogContent>
      </Dialog>

      <ConfirmationDialog
        id='reset-bs-material-result'
        title='Confirmation de la suppression'
        contentText='Êtes-vous sûr de vouloir rétablir les réglages par défaut ?'
        handleClose={handleClose}
        action={() => {
          if (selectedBsMaterialResult?.id && selectedVariant?.id) {
            return resetMaterial(selectedVariant?.id, selectedBsMaterialResult.id).then(() =>
              setOpenConfirmationDialog(false)
            )
          }
          return Promise.resolve()
        }}
        open={openConfirmationDialog}
      />
    </>
  )
}
