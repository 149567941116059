import { Box, CardHeader, Collapse, IconButton, Typography } from '@mui/material'
import React, { useMemo, useRef, useState } from 'react'
import { FileDownloadOutlined, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import TermOfServiceDialog from '../../../../../components/terme-of-service-dialog'
import { useTutorial } from '../../../../../core/hooks/bim-model/use-tutorial'

export default function TutorialBimModelGuide(): React.JSX.Element {
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const fetchFile = useRef<() => Promise<File | undefined>>()
  const { fetchConventionBeem } = useTutorial()

  const annexeData = useMemo(
    () => [
      {
        label: 'Convention Beem/Cahier des charges Beem',
        size: 5,
        fetch: fetchConventionBeem,
      },
    ],
    [fetchConventionBeem]
  )

  const handleDownloadFile = async (): Promise<void> => {
    if (fetchFile.current) {
      setLoading(true)
      const file = await fetchFile.current()
      if (file) {
        const element = document.createElement('a')
        element.href = URL.createObjectURL(file)
        element.download = file.name
        element.click()
      }
      setOpenDialog(false)
      setLoading(false)
    }
  }

  const handleClick = (fetchData: () => Promise<File | undefined>): void => {
    setOpenDialog(true)
    fetchFile.current = fetchData
  }

  const handleChangeDialogState = (value: boolean): void => {
    if (!value) {
      fetchFile.current = undefined
    }
    setOpenDialog(value)
  }

  return (
    <>
      <Box component='div'>
        <CardHeader
          title={<Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>Annexes Beem</Typography>}
          sx={{
            width: '350px',
            backgroundColor: 'black',
            color: 'white',
            py: '5px',
            borderRadius: '10px',
            mb: 1,
          }}
          action={
            <IconButton color='info' onClick={() => setOpen(!open)} aria-label='expand' size='small'>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          }
        />
        <Collapse in={open} timeout='auto' unmountOnExit>
          <Box ml={2}>
            {annexeData.map((item) => (
              <Box
                key={item.label}
                sx={{
                  color: '#344a92',
                  cursor: 'pointer',
                  width: 'fit-content',
                }}
                display='flex'
                flexDirection='row'
                alignItems='center'
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  handleClick(item.fetch)
                }}>
                <FileDownloadOutlined />
                <Typography ml={1} fontWeight='bold'>{`${item.label} (${item.size} Mo)`}</Typography>
              </Box>
            ))}
          </Box>
        </Collapse>
      </Box>
      <TermOfServiceDialog
        handleConfirm={handleDownloadFile}
        openDialog={openDialog}
        loading={loading}
        setOpenDialog={handleChangeDialogState}
      />
    </>
  )
}
