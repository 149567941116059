import Clear from '@mui/icons-material/Clear'
import { Box, IconButton, InputAdornment } from '@mui/material'
import SelectInput from '../../../../components/inputs/select-input/select-input'
import { NomenclatureFilterDto } from '../../../../core/dto/nomenclature-filter'
import { NomenclatureTreeDto } from '../../../../core/dto/nomenclature-tree'
import { DeclarationFilter, declarationFilterEnumLabels } from '../../../../core/enum/declarationFilter'
import { useFetchMaterialLibDeclarationType } from '../../../../core/hooks/material-lib/use-fetch-material-lib-declaration-type'
import { getDeclarationTypeLabel } from '../../../../core/services/declaration-type-service'
import { enumArrayToSelectOptions, enumToSelectOptions } from '../../../../core/services/helper-service'

type MaterialPageFormProps = {
  selectedLvl0: DeclarationFilter | undefined
  setSelectedLvl0: (selected: DeclarationFilter | undefined) => void
  nomenclatureFilterData: NomenclatureFilterDto
  selectedLvl1: string | undefined
  selectedLvl2: string | undefined
  selectedLvl3: string | undefined
  selectedLvl4: string | undefined
  selectedTypologieDeclaration: string | undefined
  setSelectedLvl1: React.Dispatch<React.SetStateAction<string | undefined>>
  setSelectedLvl2: React.Dispatch<React.SetStateAction<string | undefined>>
  setSelectedLvl3: React.Dispatch<React.SetStateAction<string | undefined>>
  setSelectedLvl4: React.Dispatch<React.SetStateAction<string | undefined>>
  setSelectedSelectedTypologieDeclaration: React.Dispatch<React.SetStateAction<string | undefined>>
}

export function BSMaterialLibFilter({
  selectedLvl0,
  setSelectedLvl0,
  nomenclatureFilterData,
  selectedLvl1,
  selectedLvl2,
  selectedLvl3,
  selectedLvl4,
  selectedTypologieDeclaration,
  setSelectedLvl1,
  setSelectedLvl2,
  setSelectedLvl3,
  setSelectedLvl4,
  setSelectedSelectedTypologieDeclaration,
}: Readonly<MaterialPageFormProps>): React.JSX.Element {
  const { declarationTypes } = useFetchMaterialLibDeclarationType()

  const categories: NomenclatureTreeDto[] =
    selectedLvl0 === DeclarationFilter.FDES
      ? nomenclatureFilterData.fdes.sort((a, b) => a.name.localeCompare(b.name))
      : nomenclatureFilterData.pep.sort((a, b) => a.name.localeCompare(b.name))
  const category: NomenclatureTreeDto | undefined = categories.find((c) => c.iniesId === selectedLvl1)
  const subCategory: NomenclatureTreeDto | undefined = category?.children
    .sort((a, b) => a.name.localeCompare(b.name))
    .find((subc) => subc.iniesId === selectedLvl2)
  const material: NomenclatureTreeDto | undefined = subCategory?.children
    .sort((a, b) => a.name.localeCompare(b.name))
    .find((m) => m.iniesId === selectedLvl4)

  const endAdornment = (
    value: string | undefined,
    handleChange: (v?: string) => void,
    type: 'text' | 'select' = 'text'
  ): React.JSX.Element | undefined => {
    if (value) {
      return (
        <InputAdornment
          position='end'
          sx={{
            mr: type === 'text' ? 0 : 2,
          }}>
          <IconButton onClick={() => handleChange(undefined)} size='small'>
            <Clear fontSize='small' />
          </IconButton>
        </InputAdornment>
      )
    }
    return undefined
  }

  return (
    <Box display='flex' flexDirection='row' alignItems='center' columnGap={3} p={1}>
      <SelectInput
        id='selectedLvl0'
        label='Type'
        selectedOption={selectedLvl0 ?? ''}
        options={enumToSelectOptions(DeclarationFilter, declarationFilterEnumLabels)}
        mode='event'
        handleEventChange={(e) => setSelectedLvl0(e.target.value as DeclarationFilter | undefined)}
        isBeemShot
        size='medium'
        nullable
        endAdornment={endAdornment(
          selectedLvl0,
          (v) => {
            // It might seem weird to put undefined but v is always undefined. If we refactor someday, the clear method could be simplified. "v" param is useless
            setSelectedLvl0(undefined)
            setSelectedLvl1(v)
            setSelectedLvl2(v)
            setSelectedLvl3(v)
            setSelectedLvl4(v)
          },
          'select'
        )}
      />

      <SelectInput
        id='selectedLvl1'
        label='Catégorie'
        selectedOption={selectedLvl1 ?? ''}
        disabled={!selectedLvl0}
        options={enumArrayToSelectOptions(
          categories.map((x) => x.iniesId),
          categories.reduce((acc, categorie) => {
            acc[categorie.iniesId] = categorie.name
            return acc
          }, {} as Record<string, string>)
        )}
        mode='event'
        handleEventChange={(e) => setSelectedLvl1(e.target.value)}
        isBeemShot
        size='medium'
        nullable
        endAdornment={endAdornment(
          selectedLvl0,
          (v) => {
            // It might seem weird to put undefined but v is always undefined. If we refactor someday, the clear method could be simplified. "v" param is useless
            setSelectedLvl0(undefined)
            setSelectedLvl1(v)
            setSelectedLvl2(v)
            setSelectedLvl3(v)
            setSelectedLvl4(v)
          },
          'select'
        )}
      />

      <SelectInput
        id='selectedLvl2'
        label='Sous-catégorie'
        selectedOption={selectedLvl2 || ''}
        options={enumArrayToSelectOptions(
          category?.children.map((x) => x.iniesId) || [],
          category?.children?.reduce((acc, subCategoryItem) => {
            acc[subCategoryItem.iniesId] = subCategoryItem.name
            return acc
          }, {} as Record<string, string>) || {}
        )}
        mode='event'
        handleEventChange={(e) => setSelectedLvl2(e.target.value)}
        isBeemShot
        size='medium'
        nullable
        disabled={!selectedLvl1}
        endAdornment={endAdornment(
          selectedLvl2,
          (v) => {
            setSelectedLvl2(v)
            setSelectedLvl3(v)
            setSelectedLvl4(v)
          },
          'select'
        )}
      />

      <SelectInput
        id='selectedLvl3'
        label={selectedLvl0 === DeclarationFilter.PEP ? 'Type' : 'Matériaux'}
        selectedOption={selectedLvl3 ?? ''}
        options={enumArrayToSelectOptions(
          subCategory?.children.map((x) => x.iniesId) || [],
          subCategory?.children?.reduce((acc, i) => {
            acc[i.iniesId] = i.name
            return acc
          }, {} as Record<string, string>) || {}
        )}
        mode='event'
        handleEventChange={(e) => setSelectedLvl3(e.target.value)}
        isBeemShot
        size='medium'
        nullable
        disabled={!selectedLvl2}
        endAdornment={endAdornment(
          selectedLvl3,
          (v) => {
            setSelectedLvl3(v)
            setSelectedLvl4(v)
          },
          'select'
        )}
      />

      <SelectInput
        id='selectedTypologieDeclaration'
        label='Typologie de déclaration'
        selectedOption={selectedTypologieDeclaration ?? ''}
        options={enumArrayToSelectOptions(
          declarationTypes.sort((a, b) => a.localeCompare(b)).map((typologie) => typologie) || [],
          declarationTypes.reduce((acc, typologie) => {
            acc[typologie] = getDeclarationTypeLabel(typologie)
            return acc
          }, {} as Record<string, string>) || {}
        )}
        mode='event'
        handleEventChange={(e) => setSelectedSelectedTypologieDeclaration(e.target.value)}
        isBeemShot
        size='medium'
        nullable
        endAdornment={endAdornment(selectedTypologieDeclaration, setSelectedSelectedTypologieDeclaration, 'select')}
      />
    </Box>
  )
}
