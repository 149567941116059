import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Children } from '../../../../components/miscellianous/children'
import { Collaborator } from '../../../dto/user/collaborator'
import { useOrganization } from '../../../hooks/use-organization'
import { useTeams } from '../../../hooks/use-team'
import { isUltimateUser } from '../../../services/authentication-service'
import { AdminOrganizationContext } from '../../organization/admin-organization-context'
import { UserContext } from '../../user/user-context'
import { RseeProjectContext } from './rsee-project-context'

export const RseeTeamProjectContext = React.createContext<RseeTeamProjectStore>({} as RseeTeamProjectStore)

export function RseeTeamProjectContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { getCollaboratorsForOrganization, fetchCollaborators } = useOrganization()
  const { fetchRseeTeam, revokeUserFromRseeProject, addTeamMember } = useTeams()

  const { rseeProject } = useContext(RseeProjectContext)
  const { user } = useContext(UserContext)
  const { organization } = useContext(AdminOrganizationContext)

  const [collaboratorList, setCollaboratorList] = useState<Collaborator[]>([])
  const [currentTeam, setCurrentTeam] = useState<Collaborator[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isAdding, setIsAdding] = useState(false)
  const [cognitoUserIdToRevoke, setCognitoUserIdToRevoke] = useState<string | undefined>(undefined)
  const [labelToRevoke, setLabelToRevoke] = useState<string>('')

  useEffect(() => {
    refreshTeamAndCollab()
  }, [getCollaboratorsForOrganization, fetchCollaborators, fetchRseeTeam, rseeProject?.id])

  const refreshTeamAndCollab = useCallback(() => {
    if (rseeProject?.id && !isLoading) {
      const rseeTeamPromise = fetchRseeTeam(rseeProject.id)
      const collaboratorListPromise =
        isUltimateUser(user) && organization?.id ? getCollaboratorsForOrganization(organization?.id) : fetchCollaborators()
      setIsLoading(true)
      return Promise.all([rseeTeamPromise, collaboratorListPromise])
        .then((result) => {
          const teamMembersList = result[0]
          const newCollaboratorsList = result[1]
          const teamSet: Set<string> = new Set(teamMembersList.map((collaborator) => collaborator.cognitoUserId))
          const collaborators = newCollaboratorsList.filter((newCollaborator) => !teamSet.has(newCollaborator.cognitoUserId))
          setCollaboratorList(collaborators)
          setCurrentTeam(teamMembersList)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      return Promise.resolve()
    }
  }, [
    fetchCollaborators,
    fetchRseeTeam,
    getCollaboratorsForOrganization,
    isLoading,
    organization?.id,
    rseeProject?.id,
    user,
  ])

  const revokeUser = useCallback(() => {
    if (rseeProject?.id && cognitoUserIdToRevoke) {
      return revokeUserFromRseeProject(cognitoUserIdToRevoke, rseeProject.id).then(() => {
        if (cognitoUserIdToRevoke === user?.cognitoUserId) {
          // navigate(resolveUrl(pagesUrl.PROJECTS_PAGE, [], { type: ProjectTypeEnum.RSEE }))
          return Promise.resolve()
        } else {
          return refreshTeamAndCollab().then(() => Promise.resolve())
        }
      })
    } else {
      return Promise.resolve()
    }
  }, [cognitoUserIdToRevoke, refreshTeamAndCollab, revokeUserFromRseeProject, rseeProject?.id, user?.cognitoUserId])

  const addRseeTeamMember = useCallback(
    (selectedCollaborator: Collaborator | null) => {
      if (rseeProject?.id && selectedCollaborator?.cognitoUserId) {
        setIsAdding(true)
        return addTeamMember(selectedCollaborator.cognitoUserId, rseeProject.id)
          .then((collaborator) => refreshTeamAndCollab())
          .finally(() => setIsAdding(false))
      }
      return Promise.resolve()
    },
    [addTeamMember, refreshTeamAndCollab, rseeProject?.id]
  )

  const rseeTeamProjectHook: RseeTeamProjectStore = useMemo(
    (): RseeTeamProjectStore => ({
      currentTeam,
      collaboratorList,
      revokeUser,
      cognitoUserIdToRevoke,
      labelToRevoke,
      setLabelToRevoke,
      setCognitoUserIdToRevoke,
      addRseeTeamMember,
    }),
    [addRseeTeamMember, cognitoUserIdToRevoke, collaboratorList, currentTeam, labelToRevoke, revokeUser]
  )

  return <RseeTeamProjectContext.Provider value={rseeTeamProjectHook}>{children}</RseeTeamProjectContext.Provider>
}

export type RseeTeamProjectStore = {
  currentTeam: Collaborator[]
  collaboratorList: Collaborator[]
  revokeUser(): Promise<void>
  cognitoUserIdToRevoke: string | undefined
  labelToRevoke: string
  setLabelToRevoke: React.Dispatch<React.SetStateAction<string>>
  setCognitoUserIdToRevoke: React.Dispatch<React.SetStateAction<string | undefined>>
  addRseeTeamMember(selectedCollaborator: Collaborator | null): Promise<void>
}
