import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import { Box, Grid } from '@mui/material'
import React, { useContext, useState } from 'react'
import DefaultBsProjectCard from '../../../../assets/DefaultBsProjectCard.png'
import { BSProjectContext } from '../../../core/context/beem-shot/BSProject/BSProjectContext'
import { BSVariantContext } from '../../../core/context/beem-shot/BSVariant/BSVariantContext'
import { BSProjectCardDto } from '../../../core/dto/beem-shot/BSProject/BSProjectCardDto'
import { formatToFrench } from '../../../core/services/date-service'
import { BSSelectVariantDialog } from '../../../pages/beem-shot/components/BSVariant/BSSelectVariantDialog'
import { BSVariantForm } from '../../../pages/beem-shot/components/BSVariant/BSVariantForm'
import { DefaultTypography } from '../../typography/default-typography'
import { ProjectCardStatus } from '../project-status-card'
import { BsProjectCardSeuil } from './bs-project-seuil-card'

type IProps = {
  bsProjectCardInfo: BSProjectCardDto
  hasCoverImage: boolean
}

export function BsProjectCard({ bsProjectCardInfo, hasCoverImage }: Readonly<IProps>): React.JSX.Element {
  const { fetchAllBsVariants, selectedVariant } = useContext(BSVariantContext)
  const { fetchBsProject, setBsProject } = useContext(BSProjectContext)

  const [openSelectedVariants, setOpenSelectedVariants] = useState<boolean>(false)
  const [openCreateVariantForm, setOpenCreateVariantForm] = useState<boolean>(false)

  const handleClose = (cancelAction?: boolean): void => {
    setOpenSelectedVariants(false)
    if (cancelAction) {
      setBsProject(undefined)
    }
  }

  function handleCloseVariant(cancelAction?: boolean): void {
    setOpenCreateVariantForm(false)
    if (cancelAction) {
      setBsProject(undefined)
    }
  }

  return (
    <>
      <Box
        onClick={() => {
          fetchBsProject(bsProjectCardInfo.id).then(() => {
            fetchAllBsVariants(bsProjectCardInfo.id).then((listVariant) => {
              if (listVariant.length === 0) {
                setOpenCreateVariantForm(true)
              } else {
                setOpenSelectedVariants(true)
              }
            })
          })
        }}
        style={{ width: '100%', minHeight: 100, textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
        <Grid
          container
          display='flex'
          alignItems='center'
          flexDirection='column'
          rowGap={1}
          width='100%'
          sx={{
            backgroundColor: '#fff',
            borderRadius: 3,
            p: 3,
            boxShadow: '2px 4px 20px 0px rgba(0, 0, 0, 0.05)',
          }}>
          <Grid container item display='flex' justifyContent='space-between' alignItems='center' width='100%'>
            <Grid item xs={2}>
              {hasCoverImage ? (
                <Box>null</Box>
              ) : (
                <img
                  src={DefaultBsProjectCard}
                  alt='Default Bs ProjectCard Img'
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              )}
            </Grid>
            <Grid display='flex' justifyContent='flex-end' item xs={3}>
              <ProjectCardStatus status={bsProjectCardInfo.status} />
            </Grid>
          </Grid>

          <Grid container display='flex' justifyContent='left' alignItems='center' width='100%'>
            <DefaultTypography label={bsProjectCardInfo.name} fontSize='16px' lineHeight='24px' fontWeight={600} />
          </Grid>

          <Grid container flexGrow={1} display='flex' justifyContent='space-between' alignItems='center' width='100%'>
            <Grid
              item
              xs={6}
              display='flex'
              flexDirection='column'
              sx={{ border: 2, borderColor: '#F5F8F8', borderRadius: 2, p: 1 }}>
              <DefaultTypography label="N° d'affaire" fontSize='11px' lineHeight='26.5px' fontWeight={400} color='#8398A6' />
              <DefaultTypography
                label={bsProjectCardInfo.businessCode || ''}
                fontSize='12px'
                lineHeight='18px'
                fontWeight={400}
              />
            </Grid>

            <Grid
              item
              xs={6}
              display='flex'
              flexDirection='column'
              sx={{ border: 2, borderColor: '#F5F8F8', borderRadius: 2, p: 1 }}>
              <DefaultTypography
                label='Surface de référence'
                fontSize='11px'
                lineHeight='26.5px'
                fontWeight={400}
                color='#8398A6'
              />
              <DefaultTypography
                label={`${bsProjectCardInfo.surfaceRef || '0'} m²`}
                fontSize='12px'
                lineHeight='18px'
                fontWeight={400}
              />
            </Grid>
          </Grid>

          <Grid container display='flex' justifyContent='center' alignItems='center' minHeight={50} borderRadius={2}>
            <BsProjectCardSeuil bsProjectCardInfo={bsProjectCardInfo} />
          </Grid>

          <Grid container display='flex' justifyContent='space-between' width='100%' alignItems='center'>
            <Box>
              <Box display='flex' flexDirection='row' alignItems='center'>
                <HomeWorkOutlinedIcon />
                <Box sx={{ pl: 1 }}>
                  <DefaultTypography
                    label={bsProjectCardInfo.projectOrganizationBusinessName}
                    fontSize='12px'
                    lineHeight='18px'
                    fontWeight={400}
                  />
                </Box>
              </Box>

              <DefaultTypography
                label={`Mise à jour le ${formatToFrench(new Date(bsProjectCardInfo.lastModifiedDate))}`}
                fontSize='12px'
                lineHeight='18px'
                fontWeight={400}
                color='#8398A6'
              />
            </Box>

            <Box display='flex' alignItems='center' sx={{ background: '#000', px: 1, borderRadius: 2 }}>
              <PersonOutlinedIcon fontSize='small' sx={{ color: '#fff' }} />
              <DefaultTypography
                label={bsProjectCardInfo.teamNumber ?? '1'}
                fontSize='14px'
                lineHeight='20.02px'
                fontWeight={400}
                color='#ffffff'
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <BSVariantForm
        open={openCreateVariantForm}
        handleClose={handleCloseVariant}
        bsProjectId={bsProjectCardInfo.id}
        bsVariant={selectedVariant}
      />

      <BSSelectVariantDialog
        open={openSelectedVariants}
        handleClose={handleClose}
        projectName={bsProjectCardInfo.name}
        bsProjectId={bsProjectCardInfo.id}
      />
    </>
  )
}
