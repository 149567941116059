import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SubmitButton } from '../../../../components/buttons/SubmitButton/SubmitButton'
import CancelButton from '../../../../components/buttons/cancel-button/cancel-button'
import SelectInput from '../../../../components/inputs/select-input/select-input'
import { TextInput } from '../../../../components/inputs/text-input/text-input'
import { ErrorContext } from '../../../../components/layout/error-snackbar'
import { DefaultTypography } from '../../../../components/typography/default-typography'
import { TitleFormTypography } from '../../../../components/typography/title-form-typography'
import { pagesUrl } from '../../../../core/appConstants'
import {
  dtoToForm,
  formToDto,
  IForm,
  RseeProjectContext,
  updateFormToDto,
} from '../../../../core/context/beem-pilot/rsee/rsee-project-context'
import { ProjectStatusEnum, projectStatusEnumLabels } from '../../../../core/enum/projectStatusEnum'
import { useForm } from '../../../../core/hooks/form/use-form'
import { required } from '../../../../core/hooks/form/validation'
import { enumToSelectOptions } from '../../../../core/services/helper-service'
import { resolveUrl } from '../../../../core/services/http-service'

interface IProps {
  open: boolean
  handleClose(openCreateVariant?: boolean): void
}

export function RseeProjectForm({ open, handleClose }: Readonly<IProps>): React.JSX.Element {
  const { rseeProject, createProject, updateProject } = useContext(RseeProjectContext)
  const openErrorSnackbar = useContext(ErrorContext)
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const submit: (form: IForm) => Promise<any> = useCallback(
    (form: IForm) => {
      if (rseeProject?.id) {
        setIsSubmitting(true)
        return updateProject(updateFormToDto(form)).then(() => {
          onClose()
        })
      } else {
        setIsSubmitting(true)
        return createProject(formToDto(form))
          .then((rsee) => {
            navigate(resolveUrl(pagesUrl.BEEM_PILOT_UPLOAD_PAGE, [rsee.id, 'new']))
            onClose()
          })
          .finally(() => setIsSubmitting(false))
      }
    },
    [createProject, navigate, rseeProject?.id, updateProject]
  )

  const { form, errors, handleChange, handleSubmit, resetForm } = useForm(
    rseeProject,
    dtoToForm,
    [required('projectName'), required('businessCode')],
    submit
  )

  function onClose(openCreateVariant?: boolean): void {
    resetForm()
    handleClose(openCreateVariant)
  }

  return (
    <Dialog open={open} maxWidth='md' fullWidth onClose={() => onClose()}>
      <Box component='form' id='create-rsee-form' onSubmit={handleSubmit} sx={{ m: 1 }}>
        <DialogTitle>
          {rseeProject?.id ? (
            <TitleFormTypography label='Mettre à jour le projet Beem Pilot' />
          ) : (
            <TitleFormTypography label='Nouveau projet Beem Pilot' />
          )}
        </DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <DefaultTypography label='Informations' />
              <TextInput
                fieldName='projectName'
                label='Renseignez le nom du projet'
                handleChange={handleChange}
                form={form}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                fieldName='businessCode'
                label="Numéro de l'affaire"
                handleChange={handleChange}
                form={form}
                errors={errors}
              />
            </Grid>
            {rseeProject?.id && (
              <Grid item xs={12} pt={2}>
                <SelectInput
                  id='projectStatus'
                  label='Statut'
                  selectedOption={form.projectStatus}
                  options={enumToSelectOptions(ProjectStatusEnum, projectStatusEnumLabels).filter(
                    (selectedOption) => selectedOption.value !== ProjectStatusEnum.DELETED
                  )}
                  mode='event'
                  handleEventChange={(e) => handleChange(e)}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{ px: 1 }}>
            <CancelButton action={onClose} label='Annuler' />
          </Box>
          {rseeProject?.id ? (
            <SubmitButton label='Mettre à jour' formId='create-rsee-form' isSubmiting={false} />
          ) : (
            <SubmitButton label='Suivant' formId='create-rsee-form' isSubmiting={false} />
          )}
        </DialogActions>
      </Box>
    </Dialog>
  )
}
