import SendIcon from '@mui/icons-material/Send'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CancelButton from '../../../components/buttons/cancel-button/cancel-button'
import { CustomBottomNavigation } from '../../../components/buttons/navigate-button/CustomBottomNavigation'
import { InfoContext } from '../../../components/layout/InfoSnackbar'
import { ErrorContext } from '../../../components/layout/error-snackbar'
import { DefaultTypography } from '../../../components/typography/default-typography'
import { pagesUrl } from '../../../core/appConstants'
import { BsModelInvitationContext } from '../../../core/context/beem-shot/BSBimModel/BSModelInvitationContextProvider'
import { AppNavBarContext, NavbarTypeEnum } from '../../../core/context/nav-bar/AppNavBarContext'
import { resolveUrl } from '../../../core/services/http-service'

export function BSInvitationConfirmationPage(): React.JSX.Element {
  const navigate = useNavigate()
  const { bsProjectId } = useParams()

  const openErrorSnackbar = useContext(ErrorContext)
  const openInfoSnackbar = useContext(InfoContext)
  const { setTypeNavBar, setPreviousUrl, setStepHeader } = useContext(AppNavBarContext)
  const { emailUser, cancelBsInvitation, invitation, isModelInvitationLoading } = useContext(BsModelInvitationContext)

  useEffect(() => {
    if (!invitation) {
      openInfoSnackbar("Aucune invitation n'a été envoyé")
      navigate(resolveUrl(pagesUrl.BEEM_SHOT_UPLOAD_PAGE, [bsProjectId]))
    }
  }, [bsProjectId, invitation, navigate, openErrorSnackbar, openInfoSnackbar])

  useEffect(() => {
    setTypeNavBar(NavbarTypeEnum.HEAD_WITHOUT_STEP)
    setPreviousUrl(resolveUrl(pagesUrl.BEEM_SHOT_UPLOAD_PAGE, [bsProjectId]))
  }, [bsProjectId, setPreviousUrl, setStepHeader, setTypeNavBar])

  return (
    <Box
      display='flex'
      justifyContent='center'
      flexDirection='column'
      alignContent='center'
      alignItems='center'
      sx={{ marginBottom: '10vh', p: 3 }}>
      <Grid container justifyContent='center' alignItems='center' sx={{ display: 'flex', height: '100%', width: '100%' }}>
        <Grid item xs={3} />

        <Grid
          item
          xs={6}
          rowGap={2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            height: '100%',
            background: 'white',
            borderRadius: 8,
            p: 8,
            backgroundColor: '#E6F0FB',
          }}>
          <SendIcon fontSize='large' />
          <Typography variant='h5' fontWeight={600}>
            Invitation envoyée !
          </Typography>
          <Typography fontSize={20} fontWeight={500}>
            {emailUser} a été invité à rejoindre votre projet.
          </Typography>
          <Typography variant='body1'>
            Vous recevrez une notification lorsque la maquette sera importée. Il vous restera à renseigner des informations
            obligatoires pour calculer l’impact carbone.
          </Typography>
          <Box
            display='flex'
            flexDirection='column'
            borderRadius={2}
            p={2}
            gap={1}
            sx={{ background: 'white', boxShadow: 2 }}>
            <DefaultTypography label='Une erreur s’est glissée dans le destinataire ?' />
            <DefaultTypography label='Pas d’inquiétude, annulez l’invitation puis choisissez un nouvel acteur.' />
            <Box width='35%'>
              {!isModelInvitationLoading ? (
                <CancelButton
                  label="Annuler l'invitation"
                  action={() => {
                    cancelBsInvitation()
                    openInfoSnackbar('Invitation annulée')
                    navigate(resolveUrl(pagesUrl.BEEM_SHOT_UPLOAD_PAGE, [bsProjectId]))
                  }}
                />
              ) : (
                <CircularProgress />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3} />
      </Grid>

      <CustomBottomNavigation
        actionLabel='terminer'
        actionButton={() => navigate(resolveUrl(pagesUrl.BEEM_SHOT_HOME, []))}
      />
    </Box>
  )
}
