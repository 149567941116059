import { Box, Typography } from '@mui/material'
import { FormEvent, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CustomBottomNavigation } from '../../../components/buttons/navigate-button/CustomBottomNavigation'
import { IconFileZip } from '../../../components/custom-icons/icon-file-zip'
import { BSSubmitDialog } from '../../../components/dialog/BSSubmitDialog'
import { ConfirmationDialog } from '../../../components/dialog/confirmation-dialog'
import FileInput from '../../../components/inputs/file-input/file-input'
import { ErrorContext } from '../../../components/layout/error-snackbar'
import { pagesUrl } from '../../../core/appConstants'
import { RseeDocumentContext } from '../../../core/context/beem-pilot/rsee/rsee-document-context'
import { RseeProjectContext } from '../../../core/context/beem-pilot/rsee/rsee-project-context'
import { AppNavBarContext, NavbarTypeEnum } from '../../../core/context/nav-bar/AppNavBarContext'
import { UserContext } from '../../../core/context/user/user-context'
import { RseeActionTypeEnum } from '../../../core/enum/rsee/rseeActionTypeEnum'
import { RseeSummaryTypeEnum } from '../../../core/enum/rsee/rseeSummaryTypeEnum'
import { OrganizationRoleEnum } from '../../../core/enum/user/organization-role-enum'
import { useQuery } from '../../../core/hooks/miscellaneous/use-query'
import { resolveUrl } from '../../../core/services/http-service'

export function RseeUploadPage(): React.JSX.Element {
  const { rseeProjectId, rseeDocumentId } = useParams()
  const navigate = useNavigate()
  const query = useQuery()

  const { setTypeNavBar, setPreviousUrl, setStepHeader } = useContext(AppNavBarContext)
  const { user } = useContext(UserContext)
  const openErrorSnackbar = useContext(ErrorContext)
  const { rseeProject, addDocument } = useContext(RseeProjectContext)
  const { updateDocument } = useContext(RseeDocumentContext)

  const [errors, setErrors] = useState<Record<string, string>>({})
  const [progress, setProgress] = useState<number>(100)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [rseeFile, setRseeFile] = useState<File>()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isCancelProjectOpen, setIsCancelProjectOpen] = useState<boolean>(false)

  const actionType = chooseActionType()

  function chooseActionType(): RseeActionTypeEnum {
    if (!rseeProjectId || rseeProjectId === 'new') {
      return RseeActionTypeEnum.RSEE_PROJECT_CREATION
    } else if (!rseeDocumentId || rseeDocumentId === 'new') {
      return RseeActionTypeEnum.RSEE_DOCUMENT_ADDITION
    } else {
      return RseeActionTypeEnum.RSEE_DOCUMENT_UPDATE
    }
  }

  useEffect(() => {
    setTypeNavBar(NavbarTypeEnum.STEP_HEADER)
    setPreviousUrl(pagesUrl.BEEM_PILOT_HOME)
    setStepHeader(0)
  }, [setPreviousUrl, setStepHeader, setTypeNavBar])

  function onChange(rsee: File): void {
    setRseeFile(rsee)
    console.info('Changing Rsee File"')
  }

  function cancelAction(): void {
    setIsCancelProjectOpen(true)
  }

  function handleClose(): void {
    setIsCancelProjectOpen(false)
  }

  function handlePauseProject(): void {
    setIsCancelProjectOpen(false)
    return navigate(resolveUrl(pagesUrl.BEEM_PILOT_HOME, []))
  }

  function postFile(event: FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault()

    if (!rseeProject?.id) {
      openErrorSnackbar(new Error("Erreur: il n'y a pas de projet Beem Pilot sélectionné"))
      return Promise.resolve()
    }
    if (!rseeFile) {
      openErrorSnackbar(new Error("Erreur: il n'y a pas de Rsee chargé"))
      return Promise.resolve()
    }

    if (rseeFile) {
      if (actionType === RseeActionTypeEnum.RSEE_DOCUMENT_ADDITION && rseeProjectId) {
        setIsLoading(true)
        addDocument(rseeFile)
          .then((newDocumentId: string | undefined) => {
            if (newDocumentId) {
              navigate(
                resolveUrl(pagesUrl.BEEM_PILOT_DOCUMENT_SUMMARY_IMPORT, [rseeProjectId, newDocumentId], {
                  from: RseeSummaryTypeEnum.RSEE_DOWNLOADING,
                })
              )
            }
          })
          .finally(() => {
            setIsLoading(false)
          })
      } else if (actionType === RseeActionTypeEnum.RSEE_DOCUMENT_UPDATE && rseeProjectId && rseeDocumentId) {
        if (user?.role === OrganizationRoleEnum.TEST_USER) {
          setIsDialogOpen(true)
        } else {
          setIsLoading(true)
          updateDocument(rseeFile)
            .then(() =>
              navigate(
                resolveUrl(pagesUrl.BEEM_PILOT_DOCUMENT_SUMMARY_IMPORT, [rseeProjectId, rseeDocumentId], {
                  from: RseeSummaryTypeEnum.RSEE_DOWNLOADING,
                })
              )
            )
            .finally(() => setIsLoading(false))
        }
      } else {
        setErrors({ fileInput: 'Id projet ou id document incorrect' })
      }
    } else {
      setErrors({ fileInput: 'Vous devez importer un fichier' })
    }

    return Promise.resolve()
  }

  return (
    <Box
      display='flex'
      flexGrow={1}
      flexShrink={1}
      justifyContent='center'
      flexDirection='column'
      alignContent='center'
      alignItems='center'
      height='76vh'
      gap={4}>
      <Typography variant='h5' fontWeight={600}>
        Ajoutez un fichier RSEE
      </Typography>
      <Box
        id='rseeFileId'
        gap={2}
        component='form'
        onSubmit={postFile}
        boxShadow='2px 4px 20px 0px rgba(0, 0, 0, 0.05)'
        width='60%'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          borderRadius: '25px',
          p: 3,
        }}>
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          borderRadius={2}
          gap={1}
          p={2}
          sx={{ backgroundColor: '#E6F0FB' }}>
          <IconFileZip size='large' />
          <Box>
            <Typography variant='subtitle1'>Veuillez privilégier le format .zip pour le dépôt de votre fichier.</Typography>
            <Typography>
              Un dépôt au format .xml est accepté mais ne permet pas d’afficher les résultats de vos FDES configurés, bien
              que le traitement sera effectué.
            </Typography>
          </Box>
        </Box>
        <FileInput onChange={onChange} file={rseeFile} progress={progress} fileType='.zip,.xml' disabled={false} />
      </Box>

      <ConfirmationDialog
        id='rseeConfirmUpdateDialog'
        action={() => {
          if (rseeFile) {
            return updateDocument(rseeFile)
          }
          return Promise.resolve()
        }}
        title='Attention'
        contentText="Mettre à jour votre RSEE consommera une de vos chances d'analyser vos RSEE. Êtes-vous sûr de vouloir continuer ?"
        handleClose={() => setIsDialogOpen(false)}
        open={isDialogOpen}
      />

      <CustomBottomNavigation
        actionLabel='Importer le fichier RSEE'
        cancelLabel='Annuler'
        formId='rseeFileId'
        cancelAction={cancelAction}
        isSubmiting={isLoading}
      />

      <BSSubmitDialog
        isOpen={isCancelProjectOpen}
        onClose={handleClose}
        onSubmit={handlePauseProject}
        titleText='Êtes-vous sûr ?'
        contentText={
          <>
            Vous souhaitez remettre à plus tard la création/mise à jour du projet "{rseeProject?.projectName}" ? <br />
            Vous pouvez reprendre son édition à tout moment depuis la liste des projets Beem Shot.
          </>
        }
        cancelButtonLabel='Annuler'
        submitButtonLabel='Mettre en pause'
      />
    </Box>
  )
}
