import { Auth } from 'aws-amplify'
import { User } from '../dto/user/user'
import { CollaboratorTypeEnum } from '../enum/user/collaboratorTypeEnum'
import { OrganizationRoleEnum } from '../enum/user/organization-role-enum'

/**
 *
 * Every AWS Amplify error code
 * InternalErrorException
 * InvalidLambdaResponseException
 * InvalidParameterException
 * InvalidSmsRoleAccessPolicyException
 * InvalidSmsRoleTrustRelationshipException
 * InvalidUserPoolConfigurationException
 * NotAuthorizedException
 * PasswordResetRequiredException
 * ResourceNotFoundException
 * TooManyRequestsException
 * UnexpectedLambdaException
 * UserLambdaValidationException
 * UserNotConfirmedException
 * UserNotFoundException
 */

export function hasSuperUserRights(user: User | undefined): boolean {
  return user?.role ? user.role === OrganizationRoleEnum.SUPER_USER || user.role === OrganizationRoleEnum.MEGA_USER : false
}

export function isSuperUser(user: User | undefined): boolean {
  return user?.role ? user.role === OrganizationRoleEnum.SUPER_USER : false
}

export function isMegaUser(user: User | undefined): boolean {
  return user?.role ? user.role === OrganizationRoleEnum.MEGA_USER : false
}

export function isUltimateUser(user: User | undefined): boolean {
  return user?.role ? user.role === OrganizationRoleEnum.ULTIMATE_USER : false
}

export function isNormalUser(user: User | undefined): boolean {
  return user?.role ? user.role === OrganizationRoleEnum.NORMAL_USER : false
}

export function isTestUser(user: User | undefined): boolean {
  return user?.role ? user.role === OrganizationRoleEnum.TEST_USER : false
}

export function collaboratorTypeToLabel(type: CollaboratorTypeEnum): string {
  if (type) {
    switch (type) {
      case CollaboratorTypeEnum.CREATED:
        return 'Acceptée'
      case CollaboratorTypeEnum.WAITING_CREATION:
        return 'Invitation en attente'
      default:
        return ''
    }
  } else {
    return ''
  }
}

export function getJwtTokenFromStorage(): string | undefined {
  return getFromStorage('idToken')
}

export function getCognitoUserIdFromStorage(): string | undefined {
  return getFromStorage('cognitoUserId')
}

export function getUserFromStorage(): User | undefined {
  const item = getFromStorage('user')
  return item ? JSON.parse(item) : undefined
}

export function deleteAllCookies(): void {
  console.info('delete all cookies')
  const cookies = decodeURIComponent(document.cookie)
  const cookiesArray: string[] = cookies.split(';')
  for (const cookie of cookiesArray) {
    const name: string = cookie.substring(0, cookie.indexOf(`=`))
    console.info('clear cookie', name)
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  }
}

function getFromStorage(key: string): string | undefined {
  const dataFromStorage: string | null = localStorage.getItem(key)
  return dataFromStorage ?? undefined
}

export function getCognitoId(userAttributes: any): string | undefined {
  return userAttributes?.sub === undefined ? '' : userAttributes.sub
}

export function signIn(name: string, password: string): Promise<any> {
  // For possible error codes list, go to : https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html#API_InitiateAuth_Errors
  return Auth.signIn(name, password)
}

export async function signUp(userDto: User, organization: string, password: string): Promise<any> {
  let phoneNumber = userDto.phone
  if (phoneNumber.startsWith('0')) {
    phoneNumber = phoneNumber.replace('0', '')
  }

  phoneNumber = `+33${phoneNumber}`

  return Auth.signUp({
    username: userDto.email,
    password,
    attributes: {
      email: userDto.email,
      family_name: userDto.familyName,
      given_name: userDto.givenName,
      phone_number: phoneNumber,
      'custom:organizationId': userDto.organizationId,
    },
  })
}

export function confirm(username: string, code: string): Promise<any> {
  // For possible error codes list, go to :
  // https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ConfirmSignUp.html
  return Auth.confirmSignUp(username, code)
}

export async function resendCode(username: string): Promise<any> {
  try {
    await Auth.resendSignUp(username)
    console.info('code resent successfully')
  } catch (err) {
    console.info('error resending code: ', err)
  }
}

export function completePasswordChallenge(userInfos: any, password: string): Promise<any> {
  return Auth.completeNewPassword(userInfos, password)
}

export function forgotPassword(userInfos: string): Promise<any> {
  return Auth.forgotPassword(userInfos)
}

export function forgotPasswordSubmit(userInfos: string, code: string, newPassword: string): Promise<any> {
  return Auth.forgotPasswordSubmit(userInfos, code, newPassword)
}

export function getCookieValue(name: string): string | undefined {
  const cookies = decodeURIComponent(document.cookie)
  const cookiesArray: string[] = cookies.split(';')
  for (const cookie of cookiesArray) {
    if (cookie.indexOf(`${name}=`) !== -1) {
      return cookie.substring(cookie.indexOf('=') + 1, cookie.length)
    }
  }
  return undefined
}
