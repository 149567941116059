/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export function IconRseeShareLink({ width, height, ...props }: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props} viewBox='0 0 24 24' width={width} height={height}>
      {/*  SVG from https://tablericons.com/ */}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='icon icon-tabler icon-tabler-link'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        strokeWidth='1.5'
        stroke={props.color ? props.color : '#2c3e50'}
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'>
        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
        <path d='M9 15l6 -6' />
        <path d='M11 6l.463 -.536a5 5 0 0 1 7.071 7.072l-.534 .464' />
        <path d='M13 18l-.397 .534a5.068 5.068 0 0 1 -7.127 0a4.972 4.972 0 0 1 0 -7.071l.524 -.463' />
      </svg>
    </SvgIcon>
  )
}
