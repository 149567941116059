import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import React from 'react'
import { Contact } from '../../../core/dto/user/contact'
import { CollaboratorTypeEnum } from '../../../core/enum/user/collaboratorTypeEnum'
import { roleOnOrganizationToLabel } from '../../../core/enum/user/organization-role-enum'
import { collaboratorTypeToLabel } from '../../../core/services/authentication-service'

const pageSize = 5

interface DisplayUserColumn {
  id: 'name' | 'surname' | 'email' | 'phoneNumber' | 'userOrganizationName' | 'status' | 'action'
  label: string
  minWidth?: number
  format?: (value: number) => string
}

const columns: DisplayUserColumn[] = [
  {
    id: 'name',
    label: 'Nom',
  },
  {
    id: 'surname',
    label: 'Prénom',
  },
  {
    id: 'email',
    label: 'E-mail',
  },
  {
    id: 'phoneNumber',
    label: 'N. de tel',
  },
  {
    id: 'userOrganizationName',
    label: 'Organisation',
  },
  {
    id: 'status',
    label: 'Statut',
  },
]

type iProps = {
  page: number
  dataContactList: Contact[]
  handleChangePage: (event: unknown, newPage: number) => void
}

export default function ContactDataTable({ page, dataContactList, handleChangePage }: iProps): React.JSX.Element {
  function getValueForCollumn(value: string, row: Contact): React.JSX.Element | string {
    switch (value) {
      case 'name':
        return row.lastName
      case 'surname':
        return row.firstName
      case 'email':
        return row.email
      case 'phoneNumber':
        return row.phone
      case 'userOrganizationName':
        return row.userOrganizationName
      case 'action':
        return 'action'
      case 'status':
        return (
          <>
            <span>{roleOnOrganizationToLabel(row.role)}</span>
            {row.type === CollaboratorTypeEnum.WAITING_CREATION && (
              <span style={{ color: '#FF4C0A' }}> ({collaboratorTypeToLabel(row.type)})</span>
            )}
          </>
        )
      default:
        return ''
    }
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440, minHeight: 200 }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align='left' style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody className='notranslate'>
            {dataContactList.slice(page * pageSize, page * pageSize + pageSize).map((contact: Contact) => (
              <TableRow hover role='checkbox' tabIndex={-1} key={contact.cognitoUserId}>
                {columns.map((column) => {
                  const value = contact !== undefined && column !== undefined ? getValueForCollumn(column.id, contact) : ' '
                  return (
                    <TableCell key={column.id} align='left'>
                      {value}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ display: 'flex', align: 'center', justifyContent: 'center', alignItems: 'center' }}
        component='div'
        rowsPerPageOptions={[]}
        count={dataContactList.length}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
      />
    </Paper>
  )
}
