import { useCallback, useMemo, useState } from 'react'

interface FormHook<T> {
  statusFilter: string | null
  searchState: string | undefined
  handleSelectChange(value: string | null): void
  handleSearch(value: string): void
  itemsToShow: T[]
}

export function useSearchAndFilter<T extends object, D>(
  currentList: T[],
  filterableFields: (keyof T)[],
  statusField?: keyof T
): FormHook<T> {
  const [searchState, setSearchState] = useState<string | undefined>(undefined)
  const [statusFilter, setStatusFilter] = useState<string | null>(null)

  const handleSelectChange = useCallback((value: string | null): void => {
    setStatusFilter(value)
  }, [])

  const handleSearch = useCallback((e: string): void => {
    setSearchState(e)
  }, [])

  const itemsToShow = useMemo(
    () =>
      currentList
        .filter((item) => {
          if (!statusFilter || !statusField || statusFilter === 'TOUS') {
            return true
          }

          return item[statusField] === statusFilter
        })
        .filter((item) =>
          filterableFields.some((field) => {
            const fieldValue = item[field]
            if (typeof fieldValue === 'string') {
              return fieldValue.toLowerCase().includes(searchState?.toLowerCase() ?? '')
            }
            return false
          })
        )
        .sort((a, b) => {
          const firstField = filterableFields[0]
          if (firstField && typeof a[firstField] === 'string' && typeof b[firstField] === 'string') {
            if (a[firstField] < b[firstField]) return -1
            if (a[firstField] > b[firstField]) return 1
          }
          return 0
        }),
    [currentList, searchState, statusFilter, filterableFields]
  )

  return useMemo(
    () => ({ statusFilter, searchState, handleSelectChange, handleSearch, itemsToShow }),
    [statusFilter, searchState, handleSelectChange, handleSearch, itemsToShow]
  )
}
