import { Box } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { IconFileTypeXml } from '../../../components/custom-icons/icon-file-type-xml'
import { IconRseeShareLink } from '../../../components/custom-icons/icon-rsee-share-link'
import { IconUserCheck } from '../../../components/custom-icons/icon-user-check'
import { pagesUrl } from '../../../core/appConstants'
import { RseeDocumentListContext } from '../../../core/context/beem-pilot/rsee/rsee-document-list-context'
import { RseeProjectContext } from '../../../core/context/beem-pilot/rsee/rsee-project-context'
import { RseeTeamProjectContext } from '../../../core/context/beem-pilot/rsee/rsee-team-project-context'
import { AppNavBarContext } from '../../../core/context/nav-bar/AppNavBarContext'
import { AppSideBarContext } from '../../../core/context/side-bar/side-bar-context'
import { CustomTabPanel, TabPanelProps } from '../../beem-shot/components/CustomTabPanel'
import { RseeShareLinkPage } from '../rseeShareLink/RseeShareLinkPage'
import { RseeTeamPage } from '../rseeTeam/RseeTeamPage'
import { RseeDocumentList } from './components/RseeDocumentList'

export function RseeProjectDetailPage(): React.JSX.Element {
  const { setShowAppSide } = useContext(AppSideBarContext)
  const { currentTeam } = useContext(RseeTeamProjectContext)
  const { setTypeNavBar, setPreviousUrl, setStepHeader, setShowProjectStatus, setTitle } = useContext(AppNavBarContext)

  const { rseeProject } = useContext(RseeProjectContext)
  const { rseeDocumentList } = useContext(RseeDocumentListContext)

  const location = useLocation()

  const [tabs, setTabs] = React.useState('FICHIER_RSEE')

  useEffect(() => {
    const defaultTab = location.state?.defaultTab
    if (typeof defaultTab === 'string') {
      setTabs(defaultTab)
    }
    setPreviousUrl(pagesUrl.BEEM_PILOT_HOME)
    setShowProjectStatus(true)
    setTitle(rseeProject?.projectName ?? '')

    return () => {
      setShowProjectStatus(false)
      setTitle('BEEM PILOT')
    }
  }, [location, rseeProject?.projectName, setPreviousUrl, setShowProjectStatus, setStepHeader, setTitle, setTypeNavBar])

  useEffect(() => {
    setShowAppSide(true)
  }, [setShowAppSide])

  function handleChange(event: React.SyntheticEvent, newValue: string): void {
    setTabs(newValue)
  }

  const tabTest: TabPanelProps[] = [
    {
      id: 'FICHIER_RSEE',
      Icon: IconFileTypeXml,
      label: 'Fichier RSEE',
      chips: rseeDocumentList?.length.toString(),
      content: <RseeDocumentList />,
    },
    {
      id: 'EQUIPE',
      Icon: IconUserCheck,
      label: 'Équipe',
      chips: currentTeam.length.toString(),
      content: <RseeTeamPage />,
    },
    {
      id: 'LIEN_DE_PARTAGE',
      Icon: IconRseeShareLink,
      label: 'Lien de partage',
      chips: undefined,
      content: <RseeShareLinkPage />,
    },
  ]

  return (
    <Box>
      <CustomTabPanel panels={tabTest} tabs={tabs} handleChange={handleChange} />
    </Box>
  )
}
