import { Chip } from '@mui/material'
import React from 'react'
import { DefaultTypography } from '../../../../components/typography/default-typography'
import { OrganizationRoleEnum, roleOnOrganizationToLabel } from '../../../../core/enum/user/organization-role-enum'

type IProps = {
  role: OrganizationRoleEnum
}

export function RoleUserChip({ role }: IProps): React.JSX.Element {
  function getChipBackgroundColor(): string {
    switch (role) {
      case OrganizationRoleEnum.ULTIMATE_USER:
        return '#DFF4FF'
      case OrganizationRoleEnum.MEGA_USER:
      case OrganizationRoleEnum.NORMAL_USER:
      case OrganizationRoleEnum.SUPER_USER:
        return '#E6F0FB'
      default:
        return ''
    }
  }

  function getTypologyColor(): string {
    switch (role) {
      case OrganizationRoleEnum.ULTIMATE_USER:
        return '#06B8F1'
      case OrganizationRoleEnum.MEGA_USER:
      case OrganizationRoleEnum.NORMAL_USER:
      case OrganizationRoleEnum.SUPER_USER:
        return '#40548D'
      default:
        return ''
    }
  }
  function getRoleUserCard(): JSX.Element {
    return (
      <Chip
        sx={{ backgroundColor: getChipBackgroundColor(), color: getTypologyColor() }}
        label={
          <DefaultTypography
            label={roleOnOrganizationToLabel(role)}
            fontSize='11px'
            lineHeight='16px'
            fontWeight={500}
            color={getTypologyColor()}
          />
        }
      />
    )
  }

  return getRoleUserCard()
}
