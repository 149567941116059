import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Children } from '../../../../components/miscellianous/children'
import { BSItem } from '../../../dto/beem-shot/BSItem/BSItem'
import { BSItemSubCategory } from '../../../dto/beem-shot/BSItem/BSItemSubCategory'
import { BSItemUpdateDto } from '../../../dto/beem-shot/BSItem/BSItemUpdateDto'
import { CodeExtrait } from '../../../dto/code-extrait/code-extrait'
import { CalculTypeEnum } from '../../../enum/beem-shot/CalculTypeEnum'
import { useBSInput } from '../../../hooks/beem-shot/useBSInput'
import { useBSItem } from '../../../hooks/beem-shot/useBSItem'
import { codeToKey } from '../../../services/code-service'
import { BSProjectContext } from '../BSProject/BSProjectContext'
import { BSVariantContext } from '../BSVariant/BSVariantContext'

export interface IForm {
  id: string
  projectId: string
  calculType: CalculTypeEnum
  codeExtrait: CodeExtrait | undefined
  bsMacroComponentIds: Record<string, string | undefined>
  ignored: boolean
}

export function formToDto(id: string, form: IForm, type?: string): BSItemUpdateDto {
  return type
    ? {
        id: form.id,
        projectId: form.projectId,
        calculType: CalculTypeEnum[type as keyof typeof CalculTypeEnum],
        codeExtrait: form.codeExtrait,
        bsMacroComponentIds: form.bsMacroComponentIds,
        ignored: form.ignored,
      }
    : {
        id: form.id,
        projectId: form.projectId,
        calculType: form.calculType,
        codeExtrait: form.codeExtrait,
        bsMacroComponentIds: form.bsMacroComponentIds,
        ignored: form.ignored,
      }
}

export function dtoToForm(dto: BSItem | undefined): IForm {
  return dto
    ? {
        id: dto.id,
        projectId: dto.projectId,
        calculType: dto.calculType,
        codeExtrait: dto.codeExtrait,
        bsMacroComponentIds: dto.bsMacroComponents.reduce((acc, x) => {
          acc[x.type.name] = x.id
          return acc
        }, {} as Record<string, string>),
        ignored: dto.ignored,
      }
    : {
        id: '',
        projectId: '',
        calculType: CalculTypeEnum.STANDARD,
        codeExtrait: undefined,
        bsMacroComponentIds: {},
        ignored: false,
      }
}

export const BSItemContext = React.createContext<BsItemStore>({} as BsItemStore)

export function BSItemContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { bsProject } = useContext(BSProjectContext)
  const { selectedVariant } = useContext(BSVariantContext)
  const { updateAllUnmodifiedBSItemsputBSInput } = useBSInput()

  const [bsItems, setBsItems] = useState<BSItem[]>([])
  const [bsItemsForSubCategory, setBsItemsForSubCategory] = useState<BSItem[]>([])
  const [selectedCodeExtrait, setSelectedCodeExtrait] = useState<CodeExtrait | undefined>(undefined)
  const [selectedBSItem, setSelectedBSItem] = useState<BSItem | undefined>(undefined)

  const [isUpdating, setIsUpdating] = useState<boolean>(false)

  const { getBSItems, updateBSItem } = useBSItem()

  const subCategoriesForCategory = useMemo(() => {
    const acc: Record<string, BSItemSubCategory[]> = {}
    bsItems?.forEach((x: BSItem) => {
      if (!acc[x.category.name]) {
        acc[x.category.name] = []
      }
      acc[x.category.name].push(x.subCategory)
    })

    return acc
  }, [bsItems])

  useEffect(() => {
    if (selectedCodeExtrait) {
      const item =
        bsItems?.find((r) => {
          if (r.codeExtrait !== undefined) {
            return codeToKey(r.codeExtrait) === codeToKey(selectedCodeExtrait)
          } else {
            return undefined
          }
        }) || undefined

      setSelectedBSItem(item)
    } else {
      setSelectedBSItem(undefined)
    }
  }, [bsProject, selectedCodeExtrait, selectedBSItem, bsItems])

  const updateAllBsItems = useCallback(
    (bsVariantId: string): Promise<void> =>
      updateAllUnmodifiedBSItemsputBSInput(bsVariantId).then((x) => {
        setBsItems(x)
      }),
    [updateAllUnmodifiedBSItemsputBSInput]
  )

  const getAllBsItems = useCallback((): void => {
    if (selectedVariant?.id) {
      getBSItems(selectedVariant.id).then((newBsItem) => {
        setBsItems(newBsItem)
      })
    }
  }, [selectedVariant?.id, getBSItems])

  useEffect(() => {
    getAllBsItems()
  }, [selectedVariant?.id, getAllBsItems, getBSItems])

  const updateItemList = useCallback((bsItemList: BSItem[], updatedItem: BSItem): BSItem[] => {
    const index = bsItemList.findIndex((item) => item.id === updatedItem.id)
    if (index !== -1) {
      const updatedBsItems = [...bsItemList]
      updatedBsItems[index] = updatedItem
      return updatedBsItems
    } else {
      return bsItemList
    }
  }, [])

  const updatBSItemFunction = useCallback(
    (bsItemToUpdate: BSItemUpdateDto) => {
      setIsUpdating(true)
      return updateBSItem(bsItemToUpdate)
        .then((newBsItem) => {
          setBsItems((prevBsItems) => updateItemList(prevBsItems, newBsItem))
          setBsItemsForSubCategory((prevBsItems) => updateItemList(prevBsItems, newBsItem))
        })
        .finally(() => setIsUpdating(false))
    },
    [updateBSItem, updateItemList]
  )

  const bsItemStore = useMemo(
    () => ({
      bsItems,
      setBsItems,
      bsItemsForSubCategory,
      setBsItemsForSubCategory,
      selectedCodeExtrait,
      setSelectedCodeExtrait,
      updatBSItemFunction,
      selectedBSItem,
      setSelectedBSItem,
      isUpdating,
      subCategoriesForCategory,
      getAllBsItems,
      updateAllBsItems,
    }),
    [
      bsItems,
      bsItemsForSubCategory,
      selectedCodeExtrait,
      updatBSItemFunction,
      selectedBSItem,
      isUpdating,
      subCategoriesForCategory,
      getAllBsItems,
      updateAllBsItems,
    ]
  )
  return <BSItemContext.Provider value={bsItemStore}>{children}</BSItemContext.Provider>
}

export type BsItemStore = {
  bsItems: BSItem[]
  setBsItems: Dispatch<SetStateAction<BSItem[]>>
  bsItemsForSubCategory: BSItem[]
  setBsItemsForSubCategory: Dispatch<SetStateAction<BSItem[]>>
  selectedCodeExtrait: CodeExtrait | undefined
  setSelectedCodeExtrait: React.Dispatch<React.SetStateAction<CodeExtrait | undefined>>
  updatBSItemFunction(bsItemToUpdate: BSItemUpdateDto): Promise<void>
  selectedBSItem: BSItem | undefined
  setSelectedBSItem: React.Dispatch<React.SetStateAction<BSItem | undefined>>
  isUpdating: boolean
  getAllBsItems(): void
  subCategoriesForCategory: Record<string, BSItemSubCategory[]>
  updateAllBsItems(bsInputId: string): Promise<void>
}
